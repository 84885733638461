import React,{useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import {images} from '../../assets/Images.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/Spinner/Spinner.tsx';
import { LoginContext } from '../../contextApi/LoginContext.ts';

function Login() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [isOtpSec, setIsOtpSec] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {adminRole, setAdminRole} = useContext(LoginContext);
    let navigate = useNavigate();
    const initialValues={
        email: '',
        password: '',
        otp: ''
       }

    const validationSchema =Yup.object ({
        email: !isOtpSec && Yup.string().email('Invalid email format').required('Required'),
        password: !isOtpSec && Yup.string().required('Required'),
        otp: isOtpSec && Yup.string().required('Required')
    })

    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        setBtnLoader(true);
        setIsSuccess(false);
     try{
      if(!isOtpSec){
        try {
            const email = values.email;
            const password = values.password;
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/login`,
              JSON.stringify({ email, password }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
              }
            );
            if(response.data.status === 200){
              setAlertMessage(response.data.message);
              setOpen(true);
              setIsSuccess(true);
              setBtnLoader(false);
              setIsOtpSec(true);
            }
            if(response.data.token){
                localStorage.setItem('auth', response.data.token);
                localStorage.setItem('id', response.data.user.id);
                navigate('/');
                setBtnLoader(false);
                setAdminRole(response.data.user.role);
            }
      }catch(err){
        if(err){
            setAlertMessage(err.response.data.message);
            setOpen(true);
            setBtnLoader(false);
        }
      }
    }else{
      try {
        const email = values.email;
        const otp = values.otp;
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/verify-login`,
          JSON.stringify({ email, otp }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          }
        );
        if(response.data.token){
            localStorage.setItem('auth', response.data.token);
            localStorage.setItem('id', response.data.user.id);
            navigate('/');
            setBtnLoader(false);
            setAdminRole(response.data.user.role);
        }
  }catch(err){
    if(err){
        setAlertMessage(err.response.data.message);
        setOpen(true);
        setBtnLoader(false);
    }
  }
    }
    }catch(error){
        helpers.setErrors({submit:error.message})
        setBtnLoader(false);
    }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
       }, 2000);
      }
    },[open])
  return (
    <Box className='w-full h-screen flex items-center justify-center'>
        <Box className='w-72'>
            <Box className='w-full'><img src={images.logo} className='w-full'/></Box>
            <Box className='w-full flex flex-col items-center justify-center'>
            <h1 className='mt-14 font-semibold text-gray-600 text-lg'>PNB Event App</h1>
            <p className='mt-2 text-gray-600 text-sm'>{isOtpSec ? 'Verify OTP sent on your email' : 'Enter your login details'}</p>
           {!isOtpSec ? <form className='w-full mt-2' onSubmit={formik.handleSubmit}>
                <span className='flex items-center justify-start w-full'>
                    <TextField error={formik.errors.email} name="email" id="filled-error-helper-text" label="Email" helperText={formik.errors.email} onBlur={formik.handleChange} variant="filled" className='h-5 focus:outline-none px-2 rounded w-full' sx={{input: {background: "#f3f3f7;"}}} type='email'/>
                </span>
                <span className="flex flex-col sm:flex-row sm:items-center justify-start mt-14 w-full">
                    <TextField id="standard-error-helper-text" name="password" error={formik.errors.password} helperText={formik.errors.password} onBlur={formik.handleChange} label="Password" variant="filled" className='h-5 focus:outline-none px-2 rounded w-full' type='password' sx={{input: {background: "#f3f3f7;"}}}/>
                </span>
                {btnLoader ? <Box className='w-full mt-16  flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" type="submit" disabled={!formik.isValid} onClick={() => {setFormSubmit(true)}} className='w-full' sx={{marginTop:'70px'}}>Login</Button> }
            </form> :
            <form className='w-full mt-2' onSubmit={formik.handleSubmit}>
            <span className='flex items-center justify-start w-full'>
                <TextField error={formik.errors.otp} name="otp" value={formik.values.otp} id="filled-error-helper-text" autoComplete="off" label="OTP" helperText={formik.errors.otp} onChange={formik.handleChange} variant="filled" className='h-5 focus:outline-none px-2 rounded w-full' sx={{input: {background: "#f3f3f7;"}}} type='text'/>
            </span>
            {btnLoader ? <Box className='w-full mt-16  flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" type="submit" disabled={!formik.isValid} onClick={() => {setFormSubmit(true)}} className='w-full' sx={{marginTop:'70px'}}>Verify OTP</Button> }
        </form>
}
            </Box>
        </Box>
        <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    setIsSuccess(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, color: `${!isSuccess ? 'white' : ''}`, backgroundColor: `${!isSuccess ? 'red' : ''}`, }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
    </Box>
  );
}

export default Login;
