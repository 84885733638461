import React,{useState, useContext, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import SimpleImageSlider from "react-simple-image-slider";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom'
import { BoothsContext } from '../../../../contextApi/BoothsContext.ts';
import UseBoothsApiCalls from '../../../../hooks/BoothsApiCalls.ts';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import axios from 'axios';
import QRCode from 'qrcode.react';
import Button from '@mui/material/Button';
import { useReactToPrint } from 'react-to-print';
import ReactApexChart from 'react-apexcharts';
import ChartGenerator from './ChartGenerator.tsx';
import { Alert, Collapse, IconButton, TextField } from '@mui/material';
import moment from 'moment';
import { error } from 'console';

function ViewBoothDetails() {
    const [removeLoader, setRemoveLoader] = useState(false);
    const [bannerImages, setBannerImages] = useState([]);
    const {boothsContextData, setBoothsContextData} = useContext(BoothsContext);
    const [reportDate, setReportDate] = useState();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [size1, setSize1] = useState(2);
    const [size2, setSize2] = useState(10);
    const [isGenerateGraph, setIsGenerateGraph] = useState(false);
    const [graphImage, setGraphImage] = useState('');
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();
    const [reportLoader, setReportLoader] = useState(false);
    const [activeToggle, setActiveToggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewData, setViewData] = useState();
    const componentRef = useRef(null);
    const { id } = useParams();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  
  // -----------------------------------------------------------------
  const images = [
    { url: "http://127.0.0.1:8000/upload/1662470786513.jpg" },
    { url: "https://t3.ftcdn.net/jpg/02/70/22/86/360_F_270228625_yujevz1E4E45qE1mJe3DyyLPZDmLv4Uj.jpg" },
    { url: "http://127.0.0.1:8000/upload/1662470786452.jpg" },
    { url: "https://t3.ftcdn.net/jpg/02/70/22/86/360_F_270228625_yujevz1E4E45qE1mJe3DyyLPZDmLv4Uj.jpg" }
  ];
  
  //-----------------------------------------------------------------------
  const getBoothsById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-booth/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.booth);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
      console.log('err in view data', err);
    }
  }
  const handleDownloadQr = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').toDataURL("image/png").replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  const handleCreateReport = async () => {
    // 
    try{
      setReportLoader(true);
      // const selectedDate = moment(reportDate).format('YYYY-MM-DD');
      const accessToken = localStorage.getItem('auth');
      var formData = new FormData();
       formData.append("date", reportDate);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/get-booth-report/${id}`, formData,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response of report data is : ', response.data);
        let tempChartNames = [];
        let tempChartCount = [];
        for(let i=0; i < response.data.booth_stats.length; i++){
          tempChartNames.push(response.data.booth_stats[i].name);
          tempChartCount.push(response.data.booth_stats[i].booth_collect_count);
        }
        console.log('names for chart is : ', tempChartNames, ' and tempCHartCounts are : ', tempChartCount);

        const tempChartOptions = {
          chart: {
            type: 'bar',
          },
          xaxis: {
            categories: tempChartNames,
            tickAmount: tempChartNames.length,
            tickPlacement: 'between',
            labels: {
              show: true,
              rotate: -47,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: undefined,
              maxHeight: 350,
              style: {
                  colors: [],
                  fontSize: '9px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  cssClass: 'apexcharts-xaxis-label',
              },
            },
          },
          dataLabels: {
            enabled: false, // Hide data labels
          },
          colors: ['#4472c4'],
        };
        console.log('tempChartOptions is : ', tempChartOptions);
        const tempChartData = [
          {
            name: 'Series 1',
            data: tempChartCount,
          },
        ];
        setChartData(tempChartData);
        setChartOptions(tempChartOptions)
        // console.log('chartOptions is : ', chartOptions, ' and ChartData is : ', chartData);
        setIsGenerateGraph(true);
      }
    }catch(err){
      setReportLoader(false);
      setOpen(true);
      setErrorMsg(err.response.data.message);
      console.log('err in report data', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }

  }
  const handleGetReport = async () => {
    try{
      // const selectedDate = moment(reportDate).format('YYYY-MM-DD');
      const accessToken = localStorage.getItem('auth');
      var formData = new FormData();
       formData.append("date", reportDate);
      //  formData.append("image", graphImage);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/get-booth-report/${id}`, formData,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response of get report file is : ', response.data.pdf);
        setReportLoader(false);
        setGraphImage('');
        window.open(response.data.pdf, '_blank');
      }
    }catch(err){
      setReportLoader(false);
      setOpen(true);
      setErrorMsg(err.response.data.message);
      console.log('err in get report file', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  useEffect(()=>{
      getBoothsById();
      const todayDate = moment().format('YYYY-MM-DD');
      setReportDate(todayDate);
      
  },[])

  useEffect(()=>{
    if(graphImage){
      setIsGenerateGraph(false);
      // setReportLoader(false);
      console.log('graphImage is : ', graphImage);
      handleGetReport()
    }
  },[graphImage])
  useEffect(()=>{
    if(open){
      setTimeout(function(){
        setOpen(false);
        setReportLoader(false);
     }, 2000);
    }
  },[open])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Booths' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Booth Details</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/'>Booths </NavLink>/ <span className='font-semibold cursor-pointer'>Booth Details</span></Typography>
                </Box>                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                {!loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>View Booth</Typography>
                           <span className='flex mr-3'>
                           <h3 className='mt-1.5 mr-2'>Select Date for report: </h3>
                           <Box className='flex flex-col'>
                             <span className='flex '> <TextField size="small" value={reportDate} onChange={(e)=>{setReportDate(e.target.value)}} type='date' id="outlined-required"/></span> 
                             {reportLoader ? <Box className='ml-7 w-20 flex justify-center items-center' sx={{marginRight: '15px', marginTop: '10px', width: '170px'}}><Spinner size={5}/></Box> : <Button variant="outlined" sx={{marginRight: '15px', marginTop: '10px', width: '170px'}} onClick={handleGetReport}>Create Report</Button> }
                           </Box>
                           </span>
                         </Box>
                         <Box className='mt-10 mb-20 flex flex-col lg:flex-row items-center lg:items-start justify-start'>
                          {/* -------------------------Table---------------------------- */}

                          <Box className='w-full lg:w-5/6 flex flex-col items-start justify-start text-left'>
                              <Box className='mt-20 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Logo</span>:</Typography>
                                 <Box className='w-60 h-52 ml-5 object-cover'>
                                       <img src={`${viewData?.logo}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box>
                              {viewData?.small_logo ? <Box className='mt-20 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Small Logo</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.small_logo}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                                 <Typography variant='body1' className='pt-5 pl-5'><span className='font-bold'>Name</span>: {viewData?.name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Name(BM)</span>: {viewData?.name_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Booth Number</span>: {viewData?.booth_number}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Tagline</span>: {viewData?.tagline}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Tagline(BM)</span>: {viewData?.tagline_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Name</span>: {viewData?.contact_name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Phone</span>: {viewData?.contact_phone}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Email</span>: {viewData?.contact_email}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Description</span>: {viewData?.description}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Description(BM)</span>: {viewData?.description_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Points</span>: {viewData?.points}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Booth type?</span>: {viewData?.type}</Typography>
                                {viewData?.type !== 'Hidden' && <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Is Gold Sponsored?</span>: {viewData?.is_gold_sponsored === '1' ? 'Yes' : 'No'}</Typography> }
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Beacon Id</span>: {viewData?.beacon_id}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Beacon Name</span>: {viewData?.beacon_name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Instagram Link</span>: {viewData?.instagram_url}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Facebook Link</span>: {viewData?.facebook_url}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Twitter Link</span>: {viewData?.twitter_url}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Website Link</span>: {viewData?.website_url}</Typography>
                                 {viewData?.video_url?.length && viewData?.video_url?.map((item, index) => {
                                  return(
                                    <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Video Url {index+1}</span>: {item}</Typography>
                                  )
                                 })}
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF Title</span>: {viewData?.pdf_title}</Typography>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF File</span>: {viewData?.pdf_file}</Typography>
                                 {viewData?.pdf_cover ? <Box className='mt-3 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF Cover</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.pdf_cover}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                              <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF Title</span>: {viewData?.pdf_title_second}</Typography>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF File</span>: {viewData?.pdf_file_second}</Typography>
                                 {viewData?.pdf_cover_second ? <Box className='mt-3 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF Cover</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.pdf_cover_second}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                                 {viewData?.has_bonus_question === 1 && <>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Bonus Question Title</span>: {viewData?.question?.title}</Typography>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Bonus Question Title(BM)</span>: {viewData?.question?.title_bm}</Typography>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Bonus Question Score</span>: {viewData?.question?.question_score}</Typography>
                                  {viewData?.question?.answers.map((item, index) => {
                                    return(
                                      <Box className='w-full ' style={{borderTop: '1px solid gray', borderBottom: `${index === (viewData?.question?.answers.length - 1) ? '1px solid gray' : '0px solid gray'}`}}>
                                          <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Answer No</span>: {index+1}</Typography>
                                          <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Answer Title</span>: {item?.title}</Typography>
                                          <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Answer Title(BM)</span>: {item?.title_bm}</Typography>
                                          <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Is correct?</span>: {item?.is_correct === 1 ? 'Yes' : 'No'}</Typography>
                                      </Box>
                                    )
                                  })
                                }
                                 </> }
                                 <span className='flex flex-col sm:flex-row items-center justify-center mt-5'>
                                     <Typography variant='body1' className='pl-5 pr-5'><span className='font-bold'>QR Code</span>:</Typography>
                                     <Box className='flex flex-col items-center justify-center'>
                                        <Box ref={componentRef}>
                                           <QRCode
                                           id="qrCodeEl"
                                           size={220}
                                           color='black'
                                           level='H'
					                                 includeMargin
                                           value={viewData?.qrcode}
                                           className='bg-white border border-4 border-white'
                                         />
                                         </Box>
                                         <Button variant="contained" sx={{marginTop: '15px', width: '150px'}} onClick={handleDownloadQr}>Download QR</Button>
                                         <Button variant="outlined" sx={{marginTop: '15px', width: '150px'}} onClick={handlePrint}>Print QR</Button>
                                     </Box>
                                     
                                 </span>
                                 {isGenerateGraph && <div className='relative z-40'><ChartGenerator setGraphImage={setGraphImage} chartData={chartData} chartOptions={chartOptions}/></div> }
                          </Box>
                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box> 
                <Box className={`w-full ${!loading ? '' : 'fixed'} bottom-0 h-10 bg-white text-left flex items-center justify-start`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpen(false);
                           setReportLoader(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errorMsg}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewBoothDetails;
