import React,{useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { SchedulesContext } from '../contextApi/SchedulesContext.ts';
import { SelectedEventContext } from '../contextApi/selectedEventContext.ts';
import { useNavigate } from 'react-router-dom';

const UseSchedulesApiCalls = () => {
  const {schedulesContextData, setSchedulesContextData, scheduleDelete, setScheduleDelete} = useContext(SchedulesContext);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [unathenticated, setAuthenticated] = useState(false);
  const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const [errMessage, setErrMessage] = useState('');
  const handleOnChangeSearch = (value)=>{
    setSearchInput(value);
  }
   const handlePagination = (nextPage)=>{
    getSchedules(nextPage);
   }
   const handleRefreshCall = () => {
    setSearchInput('');
    let from = 'refresh';
    let page = 1;
    getSchedules(page, from);
   }
   const handleClearSearchCall = () => {
    setSearchInput('');
    let from = 'clearSearch';
    handleSearch(from);
   }
   const handleSearch = async (from = 'initial') => {
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-schedules?query=${from === 'initial' ? searchInput : ''}&event_id=${selectedEvent}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setSchedulesContextData(response.data);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in search schedule', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    const getSchedules = async (page=1, from='initial') => {
      setLoading(true);
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/get-schedules?page=${page}&query=${from === 'initial' ? searchInput : ''}&event_id=${selectedEvent}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setSchedulesContextData(response.data);
            setLoading(false);
          }
        }catch(err){
          setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
          console.log('err in get schedules', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }

      const handleDeleteSchedule = async (scheduleID) => {
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/delete-schedule/${scheduleID}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setScheduleDelete(true);
          }
        }catch(err){
          alert(err.response.data.message);
          console.log('err in delete schedule :', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      
      }
      
  return {
    getSchedules,
    handleOnChangeSearch,
    handleSearch,
    loading,
    handleDeleteSchedule,
    handlePagination,
    errMessage,
    unathenticated,
    handleRefreshCall,
    handleClearSearchCall

  };
};

export default UseSchedulesApiCalls;