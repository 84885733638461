import React,{ useState, useContext } from "react";
import axios from 'axios';
import { StaffContext } from '../contextApi/StaffContext.ts';
import { useNavigate } from "react-router-dom";

const UseStaffApiCalls = () => {
    const [loading, setLoading] = useState(false);
    const {staffContextData, setStaffContextData, staffDelete, setStaffDelete} = useContext(StaffContext);
    const [sortingField, setSortingField] = useState('');
    const [sortingType, setSortingType] = useState('');
    const navigate = useNavigate();
    const [unathenticated, setAuthenticated] = useState(false);
    const [errMessage, setErrMessage] = useState('');
     const handlePagination = (nextPage)=>{
      getUsers(nextPage);
     }
     const handleSortingData = async (sortingData) => {
      setSortingField(sortingData.sortModel[0].field);
      setSortingType(sortingData.sortModel[0].sort);
     }
     const handleRefreshCall = () => {
      let from = 'refresh';
      let page = 1;
      getUsers(page, from);
     }
    const getUsers = async (page=1, from = 'initial') => {
        setLoading(true);
          try{
            const accessToken = localStorage.getItem('auth');
            const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/get-users?sort=${from === 'initial' ? sortingField : ''}&sort_type=${from === 'initial' ? sortingType : ''}&page=${page}`,
              {
                headers: { 
                  'Content-Type' : 'application/json',
                  'Accept' : 'application/json',
                  'Authorization' : `Bearer ${accessToken}`
                },
                withCredentials: false,
              }
            );
            console.log('response of staff is ', response);
            if(response.status === 200){
              setStaffContextData(response.data);
              setLoading(false);
            }else{
              alert(`${response.data.message}`)
            }
          }catch(err){
            setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
            console.log('err in get staff ', err);
            if(err?.response?.status === 401){
              localStorage.setItem('auth','');
              navigate('/login');
            }
          }
        }

        const handleDeleteAdmin = async (adminID) => {
            try{
              const accessToken = localStorage.getItem('auth');
              const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/api/delete-user/${adminID}`,
                {
                  headers: { 
                    'Content-Type' : 'application/json',
                    'Accept' : 'application/json',
                    'Authorization' : `Bearer ${accessToken}`
                  },
                  withCredentials: false,
                }
              );
              if(response.status === 200){
                setStaffDelete(true);
              }
            }catch(err){
              alert(err.response.data.message);
              console.log('err in delete admin :', err);
              if(err?.response?.status === 401){
                localStorage.setItem('auth','');
                navigate('/login');
              }
            }
          
          }
          

  return {
    getUsers,
    handleDeleteAdmin,
    handleSortingData,
    handlePagination,
    unathenticated,
    errMessage,
    handleRefreshCall
  };
};

export default UseStaffApiCalls;
