import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DehazeIcon from '@mui/icons-material/Dehaze';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Topbar = ({handleToggle}) => {
    let navigate = useNavigate();
    const handleLogout = async () => {
        const accessToken = localStorage.getItem('auth');
        try {
            const accessToken = localStorage.getItem('auth');
            const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/logout`,
              {
                headers: { 
                  'Content-Type' : 'application/json',
                  'Accept' : 'application/json',
                  'Authorization' : `Bearer ${accessToken}`
                },
                withCredentials: false,
              }
            );
            localStorage.setItem('auth','');
            navigate('/login');
               
      }catch(err){
        localStorage.setItem('auth','');
        navigate('/login');
        console.log('err in logout', err);
      }
  }
    return(
        <Box className='w-full h-20 bg-gray-200 flex items-center justify-between'>
            <Button variant="contained" onClick={handleToggle} sx={{marginLeft: '20px', backgroundColor: 'green'}}><DehazeIcon /></Button>
            <Box className=''><button className='h-10 mr-5 text-gray-500 font-semibold' onClick={handleLogout}><LogoutIcon /> Logout</button></Box>
        </Box>
    )
}

export default Topbar;