import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

function UpdatePassword() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [openErr, setOpenErr] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [errMessage, setErrMsg] = useState('');
    const initialValues={
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
       }
    const validationSchema =Yup.object ({
        oldPassword: Yup.string().required('Required'),
        newPassword: Yup.string().min(6,'Not less than 6 char').max(15,'Should not greater than 15 char').required('Required'),
        confirmPassword: Yup.string().required('Required')
    })
    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        const errors = {};
        try{
          setBtnLoader(true);
          try {
            var formData = new FormData();
            formData.append("old_password", values.oldPassword);
            formData.append("new_password", values.newPassword);
            formData.append("confirm_password", values.confirmPassword);
            const accessToken = localStorage.getItem('auth');
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/api/change-password`,
              formData,
              {
                headers: { 
                  'Content-Type' : 'application/json',
                  'Accept' : 'application/json',
                  'Authorization' : `Bearer ${accessToken}`
                },
                withCredentials: false,
              }
            );
            if(response.status === 200){
              setOpen(true);
              setBtnLoader(false);
            }
          }catch(err){
              console.log('err in update password', err.response);
              if(err?.response?.data?.data?.confirm_password){
                setErrMsg(err?.response?.data?.data?.confirm_password[0]);
              }else if (err?.response?.data?.data?.new_password){
                setErrMsg(err?.response?.data?.data?.new_password[0]);
              }else{
                setErrMsg(err.response.data.message);
              }
              
              setOpenErr(true);
              setBtnLoader(false);
              if(err?.response?.status === 401){
                localStorage.setItem('auth','');
                navigate('/login');
              }
        }
        }catch(error){
            console.log(error);
            setBtnLoader(false);
             helpers.setErrors({submit:error.message})
             if(error?.response?.status === 401){
              localStorage.setItem('auth','');
              navigate('/login');
            }
    }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  useEffect(()=>{
    if(open){
      setTimeout(function(){
        setOpen(false);
     }, 2000);
    }
  },[open])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Password' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2 flex'><span className='hidden lg:flex'>Update</span> Password</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Update Password</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Update Password</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5 mt-5'>
                              <TextField id="filled-error-helper-text" name={`oldPassword`} error={formik.errors.oldPassword} helperText={formik.errors.oldPassword} onBlur={formik.handleChange} label={`Old Password`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='password'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`newPassword`} error={formik.errors.newPassword} helperText={formik.errors.newPassword} onBlur={formik.handleChange} label={`New Password`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='password'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`confirmPassword`} error={formik.errors.confirmPassword} helperText={formik.errors.confirmPassword} onBlur={formik.handleChange} label={`Confirm Password`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='password'/>
                             </Box>                           
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='lg:ml-10 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' onClick={() => {setFormSubmit(true)}} disabled={!formik.isValid}>Update Password</Button> }
                             </Box>
                           </form>
                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed z-20 top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                   <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                   >
                       <CloseIcon fontSize="inherit" />
                  </IconButton>
                  }
                 sx={{ mb: 2}}
                >
                Password is successfully Updated
              </Alert>
          </Collapse>
          <Collapse in={openErr} className='fixed z-40 top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                   <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenErr(false);
                      }}
                   >
                       <CloseIcon fontSize="inherit" />
                  </IconButton>
                  }
                 sx={{ mb: 2, backgroundColor: 'red', color: 'white'}}
                >
                {errMessage}
              </Alert>
          </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default UpdatePassword;
