import React,{useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {images} from '../../../assets/Images.js';
import { styled } from '@mui/material/styles';
import { alpha} from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink, useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { PushMessagesContext } from '../../../contextApi/PushMessagesContext.ts';
import axios from 'axios';
import UsePushMessagesApiCalls from '../../../hooks/PushMessagesApiCalls.ts';
import AlertDialogSlide from '../../../components/AlertDialog/AlertDialog.tsx';
import Pagination from '@mui/material/Pagination';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important"
  }
}));

function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

function createData(
  id: number,
  eventName: string,
  title: string,
  message: any,
  Stat: any,
  schedule: any
) {
  return {id, eventName, title, message, Stat, schedule };
}

function PushMessages() {
  const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const navigate = useNavigate();
  const [deletingID, setDeletingID] = useState();
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState('');
  const [activeToggle, setActiveToggle] = useState(false);
  const [sortingLoader, setSortingLoader] = useState(false);
  const [banner, setBanner] = React.useState('allUsers');
  const [openErr, setOpenErr] = useState(false);
  const {pushMessagesContextData, setPushMessagesContextData, pushMessagesDelete, setPushMessagesDelete} = useContext(PushMessagesContext);
  const columns: GridColDef[] = [
    { field: 'event', headerName: 'Event Name', width: 150,
    renderCell: (params) => {
      var formattedData = params.row.event.name;
      var updattedData: any = [];
      var finalOutput = '';
      if(formattedData.length > 15){
        for(let i=0; i < 15; i++){
          updattedData.push(formattedData[i])
        }
        finalOutput = updattedData.join('');
      }
      
      return <div className="rowitem">{finalOutput != '' ? `${finalOutput}...` : params.row.event.name}</div>
      },
    },
    { field: 'title', headerName: 'Title', width: 210 },
    { field: 'message', headerName: 'Message', width: 270 },
    {
      field: 'schedule_date',
      headerName: 'Schedule At',
      width: 190,
      renderCell: (params) => {
        var formattedDate = params.row.schedule_date;
        var str = `${formattedDate} ${params.row.schedule_time}`;
        const localDate = moment.utc(str).local().format('DD-MM-yyyy HH:mm');
        return <div className="rowitem">{localDate}</div>
        },
    },
    {
      field: 'id',
      headerName: 'Action',
      width: 220,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
  
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
        };
        let pushMessagesID= params.formattedValue;
        var CurrentDate = new Date();
        CurrentDate.setHours(0, 0, 0, 0);
        var MessageDate = params.row.schedule_date;
        var GivenDate = new Date(MessageDate);
        var sendingStatus = params.row.sent_status;
        if(sendingStatus == 'Not Sent'){
            return <span className='text-xs'><NavLink to={`/viewPushDetails/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5 ' sx={{fontSize: '10px', marginRight: '3px'}}>View</Button></NavLink>{((GivenDate > CurrentDate) || (GivenDate == CurrentDate))  ? <><NavLink to={`/editPushMessages/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5 w-6' sx={{fontSize: '10px'}}>Edit</Button></NavLink><Button variant="outlined" className='pl-2 h-5 w-6' sx={{fontSize: '10px', marginLeft: '3px'}} onClick={()=>{handleOpenPopUp(pushMessagesID)}}>Delete</Button></> : ''}</span>;
        }else{
            return <span className='text-xs'><NavLink to={`/viewPushDetails/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5 ' sx={{fontSize: '10px', marginRight: '3px'}}>View</Button></NavLink></span>;
        }
         
      },
    },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setBanner(event.target.value);
  };
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleOpenPopUp = (id) => {
    setDeletingID(id);
    setOpenDeletePopUp(true);
  }
  const handleSearchChange = (e) => {
    handleOnChangeSearch(e.target.value);
    setSearching(e.target.value);
  }
  const getSortedData = async (sortingData) => {
    setSortingLoader(true);
    handleSortingData(sortingData);
    const field = sortingData.sortModel[0].field;
    const type = sortingData.sortModel[0].sort;
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-push-messages?sort=${field}&sort_type=${type}&query=${searching}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setPushMessagesContextData(response.data);
        setPage(1);
        setSortingLoader(false);
      }
    }catch(err){
      console.log('err in getting booths ', err);
      setSortingLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const handleClosePopUp = () => {
    setOpenDeletePopUp(false);
  }
  const handleSortModelChange =((sortModel: GridSortModel) => {
    getSortedData({ sortModel: [...sortModel] });
  });
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value);
    setPage(value);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      setPage(1);
      handleSearch('initial');
    }
  }
  const handleRefresh = () => {
    setSearching('');
    setPage(1);
    setPushMessagesContextData([]);
    handleRefreshCall();
  }
  const handleClearSearch = () => {
    setSearching('');
    setPage(1);
    handleClearSearchCall();
    setSearching('');
  }
  const {getPushMessages, handleOnChangeSearch, handleSearch, handleSortingData, handlePagination, unathenticated, errMessage, handleRefreshCall, handleClearSearchCall}=UsePushMessagesApiCalls();
  useEffect(()=>{
    window.scrollTo(0, 0);
      getPushMessages();
  },[])
  useEffect(()=>{
    if(pushMessagesDelete){
      getPushMessages(page, 'initial');
      setOpenDeletePopUp(false);
      setTimeout(function(){
        setPushMessagesDelete(false);
     }, 2000);
    }
  },[pushMessagesDelete])
  useEffect(()=>{
    if(errMessage){
      setOpenErr(true);
    }
  },[errMessage])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Messages' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-between'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2 flex'><span className='hidden lg:flex mr-2'>Push</span> Messages</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Push Message</span></Typography>
                </Box>
                <Box className='mr-20 mt-5'>

                  <Box className='w-60 lg:w-96 h-12 shadow bg-white shadow-lg hidden sm:flex ml-10  lg:-mt-1 lg:ml-8 xl:ml-24 2xl:ml-28 flex items-center justify-center rounded-lg'>
                  
                  <Box>
                    <input
                      type='text'
                      value={searching}
                      onChange = {handleSearchChange}
                      placeholder='Search items'
                      className='w-40 lg:w-72 h-7 focus:outline-none mr-2 '
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <Box className='w-5 h-5 mr-3'>
                  <span className='w-5 h-5 cursor-pointer' onClick={()=>{handleSearch('initial'); setPage(1)}}>
                      <img
                        src={images.searchIcon}
                        style={{ width: '100%', height: '100%' }}
                        alt=''
                       />
                  </span>
                  </Box>
                  <Box className='w-5 h-5'>
                    <ClearIcon className='text-blue-400 -mt-2 ml-2 cursor-pointer' onClick={handleClearSearch}/>
                  </Box>
                </Box>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>Push Messages</Typography>
                           <span className='mr-5'><span className='mb-2 sm:mb-0 sm:mr-5'><Button variant="contained" className='px-5 h-10' onClick={handleRefresh}>Refresh</Button></span><NavLink to='/addNewPush'><Button variant="contained" className='px-5 h-10'>Send New <span className='hidden sm:flex ml-1'>Push</span></Button></NavLink></span>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}

                            <div style={{ height: 520, width: '100%' }}>
                            {(pushMessagesContextData?.push_messages?.length > 0) ? <StripedDataGrid
                                   sortingOrder={['asc', 'desc']}
                                    getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                   }
                                   rowHeight={40}
                                   columns={columns}
                                   rows=  {pushMessagesContextData?.push_messages}
                                  sortingMode="server"
                                   onSortModelChange={handleSortModelChange}
                                  components={{
                                    ColumnUnsortedIcon: CustomUnsortedIcon
                                  }}
                                  disableColumnMenu
                                   /> : (unathenticated || pushMessagesContextData?.total_pages === 0 ) ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box>}
                            </div>
                            {(parseInt(pushMessagesContextData?.page) > pushMessagesContextData?.total_pages) ? '' : <Box className='w-1/2 mr-5 lg:mr-8 2xl:mr-12 right-0 -mt-12 absolute overflow-none z-40 h-10 bg-white text-left flex items-center justify-start'>
                              <Box className='sm:ml-auto sm:mr-12 flex items-center'>
                                   <Pagination count={pushMessagesContextData?.total_pages} variant="outlined" shape="rounded" size="small" page={page} onChange={handlePageChange}/>
                              </Box>
                          </Box> }
                       </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={pushMessagesDelete} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setPushMessagesDelete(false);
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2}}
                  >
                        Push Message is successfully Deleted
                  </Alert>
                </Collapse>
                <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
                {openDeletePopUp ? <AlertDialogSlide id={deletingID} from='Push Message' handleClosePopUp={handleClosePopUp}/> :  ''}
            </Grid>
        </Grid>
    </Box>
  );
}

export default PushMessages;
