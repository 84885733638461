import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import './AppContent.css';
import { useNavigate } from 'react-router-dom';

function ContentTerms() {
    const [btnLoader, setBtnLoader] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [openErr, setOpenErr] = useState(false);
  const [unathenticated, setAuthenticated] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [activeToggle, setActiveToggle] = useState(false);
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const [termsContent, setTermsContent] = useState();
  const [termsBMContent, setTermsBMContent] = useState();
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
  const onChangeTACEvent = (content) => {
    setTermsContent(content)
  }
  const onChangeTACMBEvent = (content) => {
    setTermsBMContent(content)
  }
  const onScrollEvent = (event) => {
  }
  const onClickEvent = (event) => {
  }
  const onMouseDownEvent = (event) => {
  }
  const onKeyUpEvent = (event) => {
  }
  const onFocusEvent = (event) => {
  }
  const handleSubmit = async () => {
    setBtnLoader(true);
    try {
      var formData = new FormData();
      if(termsContent){
        formData.append("terms", termsContent);
      }
      if(termsBMContent){
        formData.append("terms_bm", termsBMContent);
      }
      const accessToken = localStorage.getItem('auth');
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/update-static-content`,
        formData,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setOpen(true);
        setBtnLoader(false);
      }
     }catch(err){
     alert(err.response.data.message);
     setBtnLoader(false);
     if(err?.response?.status === 401){
      localStorage.setItem('auth','');
      navigate('/login');
    }
   }
  }
  const getAppContent = async () => {
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-static-content`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.static_content);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      setAuthenticated(true);
      setErrMessage(`${err.response.data.message}`);
      console.log('err in getting app content ', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    useEffect(()=>{
      window.scrollTo(0, 0);
        getAppContent();
    },[])
    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
       }, 2000);
      }
    },[open])
    useEffect(()=>{
      if(errMessage){
        setOpenErr(true);
      }
    },[errMessage])
    useEffect(()=>{
      if(openErr){
        setTimeout(function(){
          setOpenErr(false);
       }, 2000);
      }
    },[openErr])
  return (
    <Box className='AppContent'>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='TermsContent' from='AppContent' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Terms and Conditions</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'> App Content / <span className='font-semibold'>Terms and Conditions</span></Typography>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                { !loading && !unathenticated  ?   <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Terms and Conditions</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center'>
        
                             <Box className='w-4/5 mt-10 flex flex-col items-center justify-center'>
                             <label className='font-bold mb-5'>Terms & Conditions: </label>
                              <SunEditor
                                name="myeditor"
                                height="400px"
                                placeholder="Type somthing here..."
                                defaultValue={viewData[0]?.terms}
                                onChange={onChangeTACEvent}
                                onScroll={onScrollEvent}
                                onClick={onClickEvent}
                                onMouseDown={onMouseDownEvent}
                                onKeyUp={onKeyUpEvent}
                                onFocus={onFocusEvent}
                                setOptions={{
                                 buttonList: [
                                 ["undo", "redo"],
                                 ["font", "fontSize", "formatBlock"],
                                 ["paragraphStyle", "blockquote"],
                                 ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                 ["fontColor", "hiliteColor", "textStyle"],
                                 ["removeFormat"],
                                 ["outdent", "indent"],
                                 ["align", "horizontalRule", "list", "lineHeight"],
                                 ["table", "link", "image", "video", "audio"],
                                 ["imageGallery"],
                                 ["fullScreen", "showBlocks", "codeView"],
                                 ["preview", "print"],
                                 ["save", "template"]
                                ],
                               }}
                              />
                             </Box>
                             <Box className='w-4/5 mt-10 flex flex-col items-center justify-center'>
                             <label className='font-bold mb-5'>Terms & Conditions(Malaysian): </label>
                              <SunEditor
                                name="myeditor"
                                height="400px"
                                placeholder="Type somthing here..."
                                defaultValue={viewData[0]?.terms_bm}
                                onChange={onChangeTACMBEvent}
                                onScroll={onScrollEvent}
                                onClick={onClickEvent}
                                onMouseDown={onMouseDownEvent}
                                onKeyUp={onKeyUpEvent}
                                onFocus={onFocusEvent}
                                setOptions={{
                                 buttonList: [
                                 ["undo", "redo"],
                                 ["font", "fontSize", "formatBlock"],
                                 ["paragraphStyle", "blockquote"],
                                 ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                 ["fontColor", "hiliteColor", "textStyle"],
                                 ["removeFormat"],
                                 ["outdent", "indent"],
                                 ["align", "horizontalRule", "list", "lineHeight"],
                                 ["table", "link", "image", "video", "audio"],
                                 ["imageGallery"],
                                 ["fullScreen", "showBlocks", "codeView"],
                                 ["preview", "print"],
                                 ["save", "template"]
                                ],
                               }}
                              />
                             </Box>
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 items-center justify-center text-center'>
                             {btnLoader ? <Box className='w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{backgroundColor: 'green'}} onClick={handleSubmit}>Update Content</Button> }
                             </Box>

                             </form>
                         </Box>
                    </Box> : unathenticated ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box>  : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading && !unathenticated ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed z-20 top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                  action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                  >
                       <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2}}
            >
              T & C's of App Content is successfully Updated
            </Alert>
          </Collapse>
          <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ContentTerms;
