import React from 'react';
import {
  Routes,Route,
} from 'react-router-dom';
import PrivateRoute from './PrivateRouters';
import PublicRoute from './PublicRouters';
import Login from '../pages/Login/Login.jsx';
import Booths from '../pages/Dashboard/Booths/Booths.tsx';
import AddNewBooth from '../pages/Dashboard/Booths/AddNewBooth/AddNewBooth.tsx';
import ViewBoothDetails from '../pages/Dashboard/Booths/ViewBoothDetails/ViewBoothDetails.tsx';
import Schedule from '../pages/Dashboard/Schedule/Schedule.tsx';
import Promotions from '../pages/Dashboard/Promotions/Promotions.tsx';
import AddNewSchedule from '../pages/Dashboard/Schedule/AddNewSchedule/AddNewSchedule.tsx';
import AddNewPromotion from '../pages/Dashboard/Promotions/AddNewPromotion/AddNewPromotion.tsx';
import Users from '../pages/Dashboard/Users/Users.tsx';
import AppContent from '../pages/Dashboard/AppContent/AppContent.tsx';
import PushMessages from '../pages/Dashboard/PushMessages/PushMessages.tsx';
import AddNewPush from '../pages/Dashboard/PushMessages/AddNewPush/AddNewPush.tsx';
import StaffManagement from '../pages/Dashboard/StaffManagement/StaffManagement.tsx';
import AddNewAdmin from '../pages/Dashboard/StaffManagement/AddNewAdmin/AddNewAdmin.tsx';
import UpdatePassword from '../pages/Dashboard/UpdatePassword/UpdatePassword.tsx';
import ViewScheduleDetails from '../pages/Dashboard/Schedule/ViewScheduleDetails/ViewScheduleDetails.tsx';
import ViewPromotionDetails from '../pages/Dashboard/Promotions/ViewPromotionDetails/ViewPromotionDetails.tsx';
import ViewPushDetails from '../pages/Dashboard/PushMessages/ViewPushMessages/ViewPushMessages.tsx';
import EditBooth from '../pages/Dashboard/Booths/EditBooth/EditBooth.tsx';
import EditSchedule from '../pages/Dashboard/Schedule/EditSchedule/EditSchedule.tsx';
import EditPromotion from '../pages/Dashboard/Promotions/EditPromotion/EditPromotion.tsx';
import EditAdmin from '../pages/Dashboard/StaffManagement/EditAdmin/EditAdmin.tsx';
import ContentTerms from '../pages/Dashboard/AppContent/ContentTerms.tsx';
import ContentPdpa from '../pages/Dashboard/AppContent/ContentPdpa.tsx';
import UsersList from '../pages/Dashboard/UsersList/UsersList.tsx';
import ViewUsersListDetails from '../pages/Dashboard/UsersList/ViewUsersListDetails/ViewUsersListDetails.tsx';
import EditUsersList from '../pages/Dashboard/UsersList/EditUsersList/EditUsersList.tsx';
import Quiz from '../pages/Dashboard/Quiz/Quiz.tsx';
import ViewQuizDetails from '../pages/Dashboard/Quiz/ViewQuizDetails/ViewQuizDetails.tsx';
import EditPushMessages from '../pages/Dashboard/PushMessages/EditPushMessages/EditPushMessages.tsx';
import Surveys from '../pages/Dashboard/Surveys/Surveys.tsx';
import ViewSurveySubmissions from '../pages/Dashboard/Surveys/VIewSurveySubmissions/ViewSurveySubmissions.tsx';
import ViewSurveyAnswers from '../pages/Dashboard/Surveys/VIewSurveySubmissions/ViewSurveyAnswers.tsx';
import SurveyExports from '../pages/Dashboard/SurveyExports/SurveyExports.tsx';
import AutoShow from '../pages/Dashboard/AutoShow/AutoShow.tsx';
import AddAutoShow from '../pages/Dashboard/AutoShow/AddAutoShow/AddAutoShow.tsx';
import EditAutoShow from '../pages/Dashboard/AutoShow/EditAutoShow/EditAutoShow.tsx';
import ViewAutoShowDetails from '../pages/Dashboard/AutoShow/ViewAutoShowDetails/ViewAutoShowDetails.tsx';
import FoodFiestas from '../pages/Dashboard/FoodFiestas/FoodFiestas.tsx';
import AddFoodFiestas from '../pages/Dashboard/FoodFiestas/AddFoodFiestas/AddFoodFiestas.tsx';
import EditFoodFiestas from '../pages/Dashboard/FoodFiestas/EditFoodFiestas/EditFoodFiestas.tsx';
import ViewFoodFiestasDetails from '../pages/Dashboard/FoodFiestas/ViewFoodFiestasDetails/ViewFoodFiestasDetails.tsx';
import Sustainability from '../pages/Dashboard/Sustainability/Sustainability.tsx';
import SocialLinks from '../pages/Dashboard/SocialLinks/SocialLinks.tsx';
const Routers=()=>{
    return(
      <Routes>
            <Route exact path='/login' element={<PublicRoute/>}>
                <Route exact path='/login' element={<Login />}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
              <Route exact path='/' element={<Booths/>}/>
              <Route exact path='/addNewBooth' element={<AddNewBooth/>}/>
              <Route exact path='/editBooth/:id/:page' element={<EditBooth/>}/>
              <Route exact path='/viewBoothDetails/:id' element={<ViewBoothDetails/>}/>
              <Route exact path='/autoshow' element={<AutoShow/>}/>
              <Route exact path='/addNewAutoShow' element={<AddAutoShow/>}/>
              <Route exact path='/editAutoShow/:id/:page' element={<EditAutoShow/>}/>
              <Route exact path='/viewAutoShowDetails/:id' element={<ViewAutoShowDetails/>}/>
              <Route exact path='/foodfiestas' element={<FoodFiestas />}/>
              <Route exact path='/addNewFoodFiestas' element={<AddFoodFiestas />}/>
              <Route exact path='/editFoodFiestas/:id/:page' element={<EditFoodFiestas />}/>
              <Route exact path='/viewFoodFiestasDetails/:id' element={<ViewFoodFiestasDetails />}/>
              <Route exact path='/schedule' element={<Schedule/>}/>
              <Route exact path='/addNewSchedule' element={<AddNewSchedule/>}/>
              <Route exact path='/editSchedule/:id' element={<EditSchedule/>}/>
              <Route exact path='/viewScheduleDetails/:id' element={<ViewScheduleDetails/>}/>
              <Route exact path='/promotions' element={<Promotions/>}/>
              <Route exact path='/addNewPromotion' element={<AddNewPromotion/>}/>
              <Route exact path='/editPromotion/:id' element={<EditPromotion/>}/>
              <Route exact path='/viewPromotionDetails/:id' element={<ViewPromotionDetails/>}/>
              <Route exact path='/usersStats' element={<Users/>}/>
              <Route exact path='/usersList' element={<UsersList/>}/>
              <Route exact path='/viewUsersListDetails/:id' element={<ViewUsersListDetails/>}/>
              <Route exact path='/editUsersList/:id' element={<EditUsersList/>}/>
              <Route exact path='/appContent' element={<AppContent/>}/>
              <Route exact path='/termsAndConditions' element={<ContentTerms/>}/>
              <Route exact path='/pdpa' element={<ContentPdpa/>}/>
              <Route exact path='/pushMessages' element={<PushMessages/>}/>
              <Route exact path='/viewPushDetails/:id' element={<ViewPushDetails/>}/>
              <Route exact path='/editPushMessages/:id' element={<EditPushMessages/>}/>
              <Route exact path='/addNewPush' element={<AddNewPush/>}/>
              <Route exact path='/staffManagement' element={<StaffManagement/>}/>
              <Route exact path='/addNewAdmin' element={<AddNewAdmin/>}/>
              <Route exact path='/editAdmin/:id' element={<EditAdmin/>}/>
              <Route exact path='/updatePassword' element={<UpdatePassword/>}/>
              <Route exact path='/quiz' element={<Quiz/>}/>
              <Route exact path='/quizDetails/:id/:quizName' element={<ViewQuizDetails/>}/>
              <Route exact path='/surveys' element={<Surveys/>}/>
              <Route exact path='/socialLinks' element={<SocialLinks/>}/>
              <Route exact path='/sustainability' element={<Sustainability />}/>
              <Route exact path='/exportsurveys' element={<SurveyExports/>}/>
              <Route exact path='/surveyDetails/:id/:surveyName' element={<ViewSurveySubmissions/>}/>
              <Route exact path='/surveyAnswers/:id/:submissionId/:surveyName' element={<ViewSurveyAnswers/>}/>
            </Route>
      </Routes>
    )
  }
  export default Routers;