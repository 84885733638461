import React,{useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { SurveyContext } from '../contextApi/SurveyContext.ts';
import { SelectedEventContext } from '../contextApi/selectedEventContext.ts';
import { useNavigate } from 'react-router-dom';

const UseSurveysApiCalls = () => {
    const {surveyContextData, setSurveyContextData} = useContext(SurveyContext);
    const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sortingField, setSortingField] = useState<string>('');
  const [sortingType, setSortingType] = useState('');
  const [unathenticated, setAuthenticated] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const handleOnChangeSearch = (value)=>{
    setSearchInput(value);
  }
   const handlePagination = (nextPage)=>{
    getSurveys(nextPage);
   }
   const handleSortingData = (sortingData) => {
    setSortingField(sortingData.sortModel[0].field);
    setSortingType(sortingData.sortModel[0].sort);
   }
   const handleRefreshCall = () => {
    setSearchInput('');
    let from = 'refresh';
    let page = 1;
    getSurveys(page, from);
   }
   const handleClearSearchCall = () => {
    setSearchInput('');
    let from = 'clearSearch';
    handleSearch(from);
   }
   const handleSearch = async (from = 'initial') => {
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-surveys?query=${from === 'initial' ? searchInput : ''}&sort=${sortingField}&sort_type=${sortingType}&event_id=${selectedEvent}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('on search surveys response is ', response.data);
        setSurveyContextData(response.data);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in search surveys', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    const getSurveys = async (page=1, from = 'initial') => {
      setLoading(true);
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/get-surveys?sort=${from === 'initial' ? sortingField : ''}&sort_type=${from === 'initial' ? sortingType : ''}&query=${from === 'initial' ? searchInput : ''}&page=${page}&event_id=${selectedEvent}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            console.log('response of get surveys is ', response.data)
            setSurveyContextData(response.data);
            setLoading(false);
          }
        }catch(err){
          setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
          console.log('err in get surveys', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }
      
  return {
    getSurveys,
    handleOnChangeSearch,
    handleSearch,
    loading,
    unathenticated,
    handleSortingData,
    handlePagination,
    errMessage,
    sortingField,
    handleRefreshCall,
    handleClearSearchCall
  };
};

export default UseSurveysApiCalls;