import React,{useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import { UsersListContext } from '../../../../contextApi/UsersListContext.ts';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Spinner from '../../../../components/Spinner/Spinner.tsx';

function ViewUsersListDetails() {
    const [size1, setSize1] = useState(2);
    const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const {usersListContextData, setUsersListContextData} = useContext(UsersListContext);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
  const { id } = useParams();
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const getUsersListById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-user/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.user);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in view user list', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const setDateFormat = (date: any) => {
    var formattedDate = date;
    if(formattedDate){
    var newFormat = formattedDate.split("-").reverse().join("-");
    return newFormat
    }else{
      return '';
    }
    
}
  useEffect(()=>{
    getUsersListById();
  },[])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='UsersList' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                 <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>User Details</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/usersList'>Users </NavLink>/ <span className='font-semibold cursor-pointer'>User Details</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                { !loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>View User</Typography>
                         </Box>
                         <Box className='mt-10 mb-20 flex flex-col xl:flex-row items-center justify-start'>
                          {/* -------------------------Table---------------------------- */}
                          
                          <Box className='w-full lg:w-2/3 flex flex-col items-start justify-start text-left'>
                              <Box className='mt-20 xl:mt-5 flex items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Profile</span>:</Typography>
                                 <Box className='w-52 h-52 ml-5 object-contain bg-gray-100 flex items-center justify-center'>
                                       <img src={viewData?.profile_image} className='object-contain' width='90' height='90' />
                                 </Box>
                              </Box>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Name</span>: {viewData?.name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Email</span>: {viewData?.email}</Typography>
                                 {/* <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>IC Number</span>: {viewData?.ic_number}</Typography> */}
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Phone Number</span>: {viewData?.mobile_number}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Role</span>: {viewData?.role}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Mobile Number</span>: {viewData?.mobile_number}</Typography>
                                 {/* <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Date of Birth</span>: {setDateFormat(viewData?.dob)}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Gender</span>: {viewData?.gender}</Typography> */}
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Platform</span>: {viewData?.platform}</Typography>
                               </Box>
                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                    </Box>
                
                <Box className={`w-full ${!loading ? '' : 'fixed'} bottom-0 h-10 bg-white text-left flex items-center justify-start`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewUsersListDetails;
