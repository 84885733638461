import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import { useFormik } from 'formik';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { useNavigate } from "react-router-dom";
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import moment from 'moment';


interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
  }
  
  const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  }));
  
  function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }

function EditPushMessages() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [radioValue, setRadioValue] = useState('now');
    const [btnLoader, setBtnLoader] = useState(false);
    const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();
    const handleRadioChange = (e) => {
        setRadioValue(e.target.value);
    }
    const initialValues={
        title: viewData?.title ? viewData.title : '',
        message: viewData?.message ? viewData.message : '',
        date: viewData?.schedule_date ? viewData.schedule_date : '',
        time: viewData?.schedule_time ? viewData.schedule_time : '',
       }

    const validationSchema =Yup.object ({
        title: Yup.string().required('Required'),
        message: Yup.string().required('Message is Required'),
        date: radioValue === 'later' ? Yup.string().required('Required') : '',
        time: radioValue === 'later' ? Yup.string().required('Required') : ''
    })

    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        setBtnLoader(true);
     try{
      try{
        
      var formData = new FormData();
      formData.append("title", values.title);
      formData.append("message", values.message);
      if(radioValue === 'later'){
        var d = new Date (`${values.date} ${values.time}`);
        const utcDate = moment.utc(d).format('yyyy-MM-DD');
        const utcTime = moment.utc(d).format('HH:mm');
        console.log('utcDate is ', utcDate, ' and utcTime is ', utcTime);
        formData.append("schedule_date", utcDate);
        formData.append("schedule_time", utcTime);
        formData.append("is_scheduled", 1);
      }else{
        formData.append("is_scheduled", 0);
      }
      const accessToken = localStorage.getItem('auth');
      const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/update-push-message/${id}`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setOpen(true);
            setBtnLoader(false);
            
          }
      }catch(err){
        console.log('err in add push message', err.response);
        alert(err.response.data.message);
        setBtnLoader(false);
        if(err?.response?.status === 401){
          localStorage.setItem('auth','');
          navigate('/login');
        }
      }
    }catch(error){
        helpers.setErrors({submit:error.message})
        setBtnLoader(false);
        if(error?.response?.status === 401){
          localStorage.setItem('auth','');
          navigate('/login');
        }
    }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const getPushMessagesById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-push-message/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response is ', response);
        let tempViewData = response.data.push_message;
        const localDate = moment.utc(response.data.push_message.schedule_date_time).local().format('yyyy-MM-DD');
        const localTime = moment.utc(response.data.push_message.schedule_date_time).local().format('HH:mm');
        tempViewData.schedule_date = localDate;
        tempViewData.schedule_time = localTime;
        console.log('local sechedule time is using moment: ', localDate, ' and time is ', localTime);
        setViewData(tempViewData);
        if(response.data.push_message.is_scheduled === 1){
            setRadioValue('later');
        }else{
            setRadioValue('now');
        }
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in view push messages', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  useEffect(()=>{
    getPushMessagesById();
  },[])
  useEffect(()=>{
    if(open){
      setTimeout(function(){
        setOpen(false);
        navigate('/pushMessages');
     }, 2000);
    }
  },[open])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Messages' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Edit Push Message</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/pushMessages'>Push Messages</NavLink> / <span className='font-semibold'>Edit Push Message</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                { !loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Edit Push Message</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5'>
                              <TextField id="filled-error-helper-text" name={`title`} defaultValue={viewData?.title} error={formik.errors.title} helperText={formik.errors.title} onBlur={formik.handleChange} label={`Title`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-14'>
                             <TextareaAutosize aria-label="minimum height" minRows={3} defaultValue={viewData?.message} name='message' helperText={formik.errors.message} onBlur={formik.handleChange} placeholder="Message" className={`border border-2 focus:outline-none px-2 rounded w-4/5`}/>
                             <p className='text-red-600 text-center'>{formik.errors.message}</p>
                             </Box>
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Start Time</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue={radioValue} onChange={handleRadioChange}>
                                  <MyFormControlLabel value='now' label="Now" control={<Radio />} />
                                  <MyFormControlLabel value='later' label="Later" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                             {radioValue === 'later' ?
                             <Box className='w-full flex flex-col items-center justify-center'>
                                  <Box className='w-4/5 mt-10'>
                                       <TextField id="filled-error-helper-text" name={`date`} defaultValue={viewData?.schedule_date} error={formik.errors.date} helperText={formik.errors.date} onBlur={formik.handleChange} variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='date'/>
                                  </Box>
                                  <Box className='w-4/5 mt-10'>
                                       <TextField id="filled-error-helper-text" name={`time`} defaultValue={viewData?.schedule_time} error={formik.errors.time} helperText={formik.errors.time} onBlur={formik.handleChange} label={`Time`} variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='time'/>
                                  </Box>
                             </Box> : ''}
                             
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left sm:ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' disabled={!formik.isValid} onClick={() => {setFormSubmit(true)}}>Update Message</Button> }
                             </Box>
                             </form>

                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpen(false);
                           navigate('/pushMessages');
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2}}
                 >
                    Message is successfully Updated
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default EditPushMessages;
