import React,{useState, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, Radio, RadioGroup, useRadioGroup } from '@mui/material';
import styled from '@emotion/styled';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme?.palette?.primary?.main,
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

function EditAutoShow() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [bannerUrls, setBannerUrls] = useState([]);
    const [optionsArr, setOptionsArr] = useState<Array<any>>([{
      "title": "",
      "title_bm": "",
      "is_correct": '1',
      "id": -1,
    }, {
      "title": "",
      "title_bm": "",
      "is_correct": '0',
      "id": -1
    }]);
    const [open, setOpen] = useState(false);
    const [bannerCount, setBannerCount] = useState(0);
    const [bannerLoader, setBannerLoader] = useState(false);
    const [canUpdateAns, setCanUpdateAns] = useState(false);
    const [deletedAnswers, setDeletedAnswers] = useState([]);
    const [radioValue, setRadioValue] = useState();
    let [optionsCheck, setOptionsCheck] = useState(false);
    const { id } = useParams();
    let navigate = useNavigate();
    const [pdfRadioValue, setPdfRadioValue] = useState("0");
    const [btnLoader, setBtnLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [videoLinksArr, setVideoLinksArr] = useState([]);
    const [viewData, setViewData] = useState();
    const [bannerImages, setBannerImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPdf1, setShowPdf1] = useState();
    const [showPdf2, setShowPdf2] = useState();
    const [logoPreview, setLogoPreview] = useState();
    const [logoFile, setLogoFile] = useState();
    const [coverPreview, setCoverPreview] = useState();
    const [coverFile, setCoverFile] = useState();
    const [pdfFile, setPdfFile] = useState();
    const [pdfPreview, setPdfPreview] = useState();
    const [coverPreview2, setCoverPreview2] = useState();
    const [coverFile2, setCoverFile2] = useState();
    const [pdfFile2, setPdfFile2] = useState();
    const [pdfPreview2, setPdfPreview2] = useState();
    const [pdfRadioValue2, setPdfRadioValue2] = useState("0");
    const [smallLogoPreview, setSmallLogoPreview] = useState();
    const [smallLogoFile, setSmallLogoFile] = useState();
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            setBannerImages(prevBanners => [...prevBanners, {
                id: bannerCount+1,
                value: e.target.result    
              }]);
              setBannerCount(bannerCount+1); 
          };
          reader.readAsDataURL(event.target.files[0]);
          uploadBanner(event.target.files[0]);
        }
      }
      const uploadBanner = async (event) => {
        setBannerLoader(true);
        const uploadingFile = event.target.files[0];
        try {
          var formData = new FormData();
          formData.append("file", uploadingFile);
          const accessToken = localStorage.getItem('auth');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/upload`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            const tempBannerUrlArr = [...bannerUrls,response.data.fileUrl];
            setBannerUrls(tempBannerUrlArr);
            setBannerLoader(false);
          }
    }catch(err){
      console.log('err in edit promotion', err.response);
      setBannerLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
      
    }
  }
    const initialValues={
        name: viewData?.name ? viewData.name : '',
        name_malaysian: viewData?.name_bm ? viewData.name_bm : '',
        tagline: viewData?.tagline ? viewData.tagline : '',
        logoLink: viewData?.logo ? viewData.logo : '',
        smallLogoLink: viewData?.small_logo ? viewData.small_logo : '',
        tagline_malaysian: viewData?.tagline_bm ? viewData.tagline_bm : '',
        contactPersonName: viewData?.contact_name ? viewData.contact_name : '',
        contactPersonPhone: viewData?.contact_phone ? viewData.contact_phone : '',
        contactPersonEmail: viewData?.contact_email ? viewData.contact_email : '',
        description: viewData?.description ? viewData.description : '',
        description_malaysian: viewData?.description_bm ? viewData.description_bm : '',
        pdfTitle: viewData?.pdf_title ? viewData?.pdf_title : '',
        pdf_file: viewData?.pdf_file ? viewData?.pdf_file : '',
        facebook_link: viewData?.facebook_url ? viewData?.facebook_url : '',
        instagram_link: viewData?.instagram_url ? viewData?.instagram_url : '',
        twitter_link: viewData?.twitter_url ? viewData?.twitter_url : '',
        booking_link: viewData?.booking_link ? viewData?.booking_link : '',
        pdfTitle2: viewData?.pdf_title_second ? viewData?.pdf_title_second : '',
        pdf_file_second: viewData?.pdf_file_second ? viewData?.pdf_file_second : '',
        first_image: viewData?.first_image ? viewData?.first_image : '',
        first_link: viewData?.first_link ? viewData?.first_link : '',
        second_image: viewData?.second_image ? viewData?.second_image : '',
        second_link: viewData?.second_link ? viewData?.second_link : '',
        third_image: viewData?.third_image ? viewData?.third_image : '',
        third_link: viewData?.third_link ? viewData?.third_link : '',
       }
    const validationSchema =Yup.object ({
        name: Yup.string().required('Required'),
        name_malaysian: Yup.string().required('Required'),
        tagline: Yup.string().required('Required'),
        tagline_malaysian: Yup.string().required('Required'),
        contactPersonName: Yup.string().required('Required'),
        contactPersonPhone: Yup.string().required('Required'),
        contactPersonEmail: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        description_malaysian: Yup.string().required('Required'),
    })
    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        if(logoFile){
        setBtnLoader(true);
        console.log('values are : ', values);
        console.log('optionsArr is : ', optionsArr);
        let finalAnswers: any = []
        for(let i=0; i < optionsArr.length; i++){
          if(optionsArr[i].id !== -1){
            let obj = {
              "title": optionsArr[i].title,
              "title_bm": optionsArr[i].title_bm,
              "is_correct": Number(optionsArr[i].is_correct),
              "id": optionsArr[i].id
            }
            finalAnswers.push(obj);
          }else{
            let obj = {
              "title": optionsArr[i].title,
              "title_bm": optionsArr[i].title_bm,
              "is_correct": Number(optionsArr[i].is_correct)
            }
            finalAnswers.push(obj);
          }
          
          
        }
        console.log('finalAnswers is: ', finalAnswers);
     try{
        try {
          var formData = new FormData();
          formData.append("name", values.name);
          formData.append("name_bm", values.name_malaysian);
          formData.append("tagline", values.tagline);
          formData.append("tagline_bm", values.tagline_malaysian);
          formData.append("contact_name", values.contactPersonName);
          formData.append("contact_phone", values.contactPersonPhone);
          formData.append("contact_email", values.contactPersonEmail);
          formData.append("description", values.description);
          formData.append("description_bm", values.description_malaysian);
          formData.append("contact_email", values.contactPersonEmail);
          // formData.append("first_pdf_enabled", showPdf1);
          // formData.append("second_pdf_enabled", showPdf2);
          formData.append("twitter_url", values.twitter_link);
          formData.append("booking_link", values.booking_link);
          formData.append("instagram_url", values.instagram_link);
          formData.append("first_image", values.first_image);
          formData.append("first_link", values.first_link);
          formData.append("second_image", values.second_image);
          formData.append("second_link", values.second_link);
          formData.append("third_image", values.third_image);
          formData.append("third_link", values.third_link);
          formData.append("facebook_url", values.facebook_link);
          // formData.append("pdf_title_second", values.pdfTitle2);
          // if(coverFile2){
          //   formData.append("pdf_cover_second", coverFile2);
          //  }
          //  formData.append("pdf_file_second", values.pdf_file_second);
          let videoLinks = videoLinksArr;
          videoLinks.forEach((item, index) => {
            formData.append(`video_url[${index}]`, item === null ? '' : item);
          })
          if(logoFile !== viewData?.logo){
            formData.append("logo", logoFile);
          }
          // formData.append("pdf_title", values.pdfTitle);
          // if(coverFile !== viewData?.pdf_cover){
          //   formData.append("pdf_cover", coverFile);
          // }
          
          // formData.append("pdf_file", values.pdf_file);
          if(smallLogoFile !== viewData?.small_logo){
            formData.append("small_logo", smallLogoFile);
          }
          const accessToken = localStorage.getItem('auth');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/update-autoshow/${id}`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setOpen(true);
            setBtnLoader(false);
          }
    }catch(err){
      console.log('err in edit auto show', err.response);
      alert(err.response.data.message);
      setBtnLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }

    }catch(error){
        helpers.setErrors({submit:error.message})
    }
  }else{
    alert('Logo File is Required');
  }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const getRegExp = ['e', 'E', '+', '-', '.', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm','n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '~', '`', '!', '@', '#', '$', '%', '^', '&', '&', '*', '(', ')', '_', '=', '{','}', '[',']','|', '<', '>', ',', '?', '/',';',':','"',"'"];

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handlePdfRadioChange = (e) => {
    console.log('value of radio is : ', e.target.value);
    setPdfRadioValue(e.target.value);
  }
  const handleShowPdf1 = (e) => {
    console.log('value of radio is : ', e.target.value);
    setShowPdf1(e.target.value);
  }
  const handleShowPdf2 = (e) => {
    console.log('value of radio is : ', e.target.value);
    setShowPdf2(e.target.value);
  }
  const getAutoShowById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-autoshow/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.auto_show);
        setLogoPreview(response.data.auto_show.logo);
        setLogoFile(response.data.auto_show.logo);
        setSmallLogoPreview(response.data.auto_show.small_logo);
        setSmallLogoFile(response.data.auto_show.small_logo);
        setCoverPreview(response.data.auto_show.pdf_cover);
        setCoverFile(response.data.auto_show.pdf_cover);
        setPdfFile(response.data.auto_show.pdf_file);
        setPdfPreview(response.data.auto_show.pdf_file);
        setShowPdf1(`${response.data.auto_show.first_pdf_enabled}`);
        setShowPdf2(`${response.data.auto_show.second_pdf_enabled}`);
        setCoverPreview2(response.data.auto_show.pdf_cover_second);
        setCoverFile2(response.data.auto_show.pdf_cover_second);
        setPdfFile2(response.data.auto_show.pdf_file_second);
        setPdfPreview2(response.data.auto_show.pdf_file_second);
        if(response.data.auto_show?.video_url?.length > 0){
          setVideoLinksArr(response.data.auto_show?.video_url);
        }
        setLoading(false);
      }
      
    }catch(err){
      setLoading(false);
      console.log('err in get autoshow by id', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const handleContactNum = (e) => {
    if(/^[0-9]+$/.test(e.target.value)){
      formik.handleChange(e)
    }else{
      return
    }
   }
   const handleRadioChange = (e) => {
    console.log('value of radio is : ', e.target.value);
    setRadioValue(e.target.value);
}

   const handleCorrectAnswer = (index) => {
    let tempOptionsArr = optionsArr;
    for(let i=0; i < tempOptionsArr.length; i++){
      if(tempOptionsArr[i].is_correct === '1' && index !== i){
        tempOptionsArr[i].is_correct = '0'
      }else if(tempOptionsArr[i].is_correct === '0' && index === i){
        tempOptionsArr[i].is_correct = '1'
      }
    }
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptionsArr);
    
   }

   const handleAddVideo = () => {
    let tempVideoLinksArr = videoLinksArr;
    tempVideoLinksArr.push('');
    setVideoLinksArr(tempVideoLinksArr);
    setOptionsCheck(!optionsCheck);
   }
   const handleRemoveVideo = (index) => {
    let tempVideoLinksArr = [...videoLinksArr];
    tempVideoLinksArr.splice(index, 1);
    setVideoLinksArr(tempVideoLinksArr);
    setOptionsCheck(!optionsCheck);
   }
   const handleVideoLinkValue = (index, e) => {
    let value = e.target.value;
    let tempVideoLinksArr = [...videoLinksArr];
    tempVideoLinksArr[index] = value;
    setVideoLinksArr(tempVideoLinksArr);
   }

   const handleAddOption = () => {
    let tempOptions = optionsArr;
    let obj = {
      "title": "",
      "title_bm": "",
      "is_correct": '0',
      "id": -1,
    }
    
    tempOptions.push(obj);
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptions);
   }
   const handleRemoveOption = (index) => {
    if(optionsArr.length > 2){
      let tempOptionsArr = optionsArr;
      tempOptionsArr[index].title = "";
      tempOptionsArr[index].title_bm = "";
      tempOptionsArr[index].is_correct = "0";
      if(tempOptionsArr[index].id !== -1){
        let tempDeletedAnswers = deletedAnswers;
        tempDeletedAnswers.push(tempOptionsArr[index].id);
      }
      tempOptionsArr.splice(index,1);
      setOptionsCheck(!optionsCheck);
      setOptionsArr(tempOptionsArr);
      console.log('after removing option arr is : ', tempOptionsArr);
    }
  } 
  const handleAddValues = (index, fieldName, e) => {
    let tempOptionsArr = optionsArr;
    if(fieldName === 'title'){
      tempOptionsArr[index].title = e.target.value;
    }else if(fieldName === 'title_bm'){
      tempOptionsArr[index].title_bm = e.target.value;
    }
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptionsArr);
    
  }
  const checkAnswersValid = () => {
    if(formSubmit && radioValue === '1'){
      for(let i=0; i < optionsArr.length; i++){
        if(optionsArr[i].title === "" || optionsArr[i].title_bm === ""){
          return true;
        }
      }
    }else{
      return false;
    }
    
  }
  const handleSelectLogo = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setLogoFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  
  const handleSelectCover = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setCoverFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectPdf = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setPdfFile(e.target.files[0]);
      formik.setFieldValue('pdf_file', e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setPdfPreview(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectCover2 = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setCoverFile2(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview2(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectPdf2 = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setPdfFile(e.target.files[0]);
      formik.setFieldValue('pdf_file_second', e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setPdfPreview2(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handlePdfRadioChange2 = (e) => {
    console.log('value of radio is : ', e.target.value);
    setPdfRadioValue2(e.target.value);
  }

  const handleSelectSmallLogo = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setSmallLogoFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSmallLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }
  useEffect(()=>{
    console.log('bannerUrls is ', bannerUrls);
  },[removeLoader])
    useEffect(()=>{
        console.log('bannerImages are ', bannerImages);
        getAutoShowById();
    },[])
    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
          navigate('/autoshow');
       }, 2000);
      }
    },[open]) 
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='AutoShow' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Edit Auto Show</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/autoshow'>Auto Show </NavLink>/ <span className='font-semibold'>Edit Auto Show</span></Typography>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                {!loading ?  <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Edit Auto Show</Typography>
                         </Box>
                        <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5'>
                              <TextField id="filled-error-helper-text" name={`name`} defaultValue={viewData?.name} error={formik.errors.name} helperText={formik.errors.name} onBlur={formik.handleChange} label={`Name`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`name_malaysian`} defaultValue={viewData?.name_bm} error={formik.errors.name_malaysian} helperText={formik.errors.name_malaysian} onBlur={formik.handleChange} label={`Name(Malaysian)`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                           
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`tagline`} defaultValue={viewData?.tagline} error={formik.errors.tagline} helperText={formik.errors.tagline} onBlur={formik.handleChange} label={`Tagline`} placeholder="e.g Think Different, Be What's Next" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`tagline_malaysian`} defaultValue={viewData?.tagline_bm} error={formik.errors.tagline_malaysian} helperText={formik.errors.tagline_malaysian} onBlur={formik.handleChange} label={`Tagline(Malaysian)`} placeholder="e.g Think Different, Be What's Next" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonName`} defaultValue={viewData?.contact_name} error={formik.errors.contactPersonName} helperText={formik.errors.contactPersonName} onBlur={formik.handleChange} label={`Contact Person's Name`} placeholder="e.g Vinay" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonPhone`} defaultValue={viewData?.contact_phone} onKeyDown={(e) => getRegExp.includes(e.key) && e.preventDefault()} error={formik.errors.contactPersonPhone} helperText={formik.errors.contactPersonPhone} onBlur={(e)=>{handleContactNum(e)}} label={`Contact Person's Phone`} placeholder="e.g 03615786678" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text' InputProps={{ inputProps: { min: 0 } }} />
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonEmail`} defaultValue={viewData?.contact_email} error={formik.errors.contactPersonEmail} helperText={formik.errors.contactPersonEmail} onBlur={formik.handleChange} label={`Contact Person's Email`} placeholder="e.g support@pnb.com.my" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='email'/>
                             </Box>
                             <Box className='w-4/5 mt-12'>
                             <TextareaAutosize id="filled-error-helper-text" aria-label="minimum height" defaultValue={viewData?.description} name='description' minRows={3} error={formik.errors.description} helperText={formik.errors.description} onBlur={formik.handleChange} placeholder="Description" variant="filled" className={`border border-2 focus:outline-none px-2 rounded w-4/5`}/>
                             <p className='text-red-600 text-center'>{formik.errors.description}</p>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                             <TextareaAutosize aria-label="minimum height" minRows={3} defaultValue={viewData?.description_bm} name='description_malaysian' helperText={formik.errors.description_malaysian} helperText={formik.errors.description_malaysian} onBlur={formik.handleChange} placeholder="Description(Malaysian)" className={`border border-2 focus:outline-none px-2 rounded w-4/5`}/>
                             <p className='text-red-600 text-center'>{formik.errors.description_malaysian}</p>
                             </Box>
                            
                             
 
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`facebook_link`} defaultValue={viewData?.facebook_url} error={formik.errors.facebook_link} helperText={formik.errors.facebook_link} onBlur={formik.handleChange} label={`Facebook Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`twitter_link`} defaultValue={viewData?.twitter_url} error={formik.errors.twitter_link} helperText={formik.errors.twitter_link} onBlur={formik.handleChange} label={`Twitter Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`instagram_link`} defaultValue={viewData?.instagram_url} error={formik.errors.instagram_link} helperText={formik.errors.instagram_link} onBlur={formik.handleChange} label={`Instagram Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`booking_link`} defaultValue={viewData?.booking_link} error={formik.errors.booking_link} helperText={formik.errors.booking_link} onBlur={formik.handleChange} label={`Booking Link`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`first_image`} defaultValue={viewData?.first_image} error={formik.errors.first_image} helperText={formik.errors.first_image} onBlur={formik.handleChange} label={`First Image`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`first_link`} defaultValue={viewData?.first_link} error={formik.errors.first_link} helperText={formik.errors.first_link} onBlur={formik.handleChange} label={`First Link`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`second_image`} defaultValue={viewData?.second_image} error={formik.errors.second_image} helperText={formik.errors.second_image} onBlur={formik.handleChange} label={`Second Image`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`second_link`} defaultValue={viewData?.second_link} error={formik.errors.second_link} helperText={formik.errors.second_link} onBlur={formik.handleChange} label={`Second Link`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`third_image`} defaultValue={viewData?.third_image} error={formik.errors.third_image} helperText={formik.errors.third_image} onBlur={formik.handleChange} label={`Third Image`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`third_link`} defaultValue={viewData?.third_link} error={formik.errors.third_link} helperText={formik.errors.third_link} onBlur={formik.handleChange} label={`Third Link`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             {videoLinksArr.length && videoLinksArr.map((item, index) => {
                                 return(
                                  <Box className={`w-4/5 mt-10 flex items-center justify-center ${index !== 0 && 'ml-5'}`}>
                                    <TextField id="filled-error-helper-text" onChange={(e)=>{handleVideoLinkValue(index, e)}} value={item} label={`Video Url ${index+1}`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                                    <span className={`${index === 0 && 'hidden'}`}><CloseIcon className={`cursor-pointer `} sx={{fontSize: '16px'}} onClick={()=>{handleRemoveVideo(index)}}/></span>
                                  </Box>
                                 )
                             })}
                             <Button variant="outlined" sx={{marginTop: '45px', width: '200px'}} onClick={handleAddVideo}>Add more video</Button>
                             {/* <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Want to show First PDF on App?</label>
                    <RadioGroup id='time' name="use-radio-group" value={showPdf1} onChange={handleShowPdf1}>
                      <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                      <MyFormControlLabel value="0" label="No" control={<Radio />} />
                    </RadioGroup>
                  </Box> */}
                  {/* <span className={`w-4/5 ${showPdf1 !== '1' && 'hidden'}`}>  
                             <Box className='w-4/5 mt-10 ml-7'>
                              <TextField id="filled-error-helper-text" name={`pdfTitle`} defaultValue={viewData?.pdf_title} error={formik.errors.pdfTitle} helperText={formik.errors.pdfTitle} onBlur={formik.handleChange} label={`First PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>First PDF Cover: </label>
                    <label htmlFor="uploadImage3" className="">
                      <input type="file" id="uploadImage3" accept="image/*" hidden onChange={(e) => { handleSelectCover(e) }} />
                      {coverPreview && <img src={coverPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!coverPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(coverPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box> 
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Select Option to Add First PDF?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handlePdfRadioChange}>
                                  <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                                  <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                             {pdfRadioValue === '0' ?
                             <Box className='w-full flex flex-col items-center justify-center'>
                                  <Box className='w-full mt-10'>
                                  <TextField id="filled-error-helper-text" name={`pdf_file`} defaultValue={viewData?.pdf_file} error={formik.errors.pdf_file} helperText={formik.errors.pdf_file} onBlur={formik.handleChange} label={`First PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                                  </Box>
                                  </Box>
                                  : 
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>First PDF File: </label>
                    <label htmlFor="uploadImage4" className="">
                      <input type="file" id="uploadImage4" hidden onChange={(e) => { handleSelectPdf(e) }} />                        
                      <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{pdfPreview && pdfPreview}</p></div>
                    </label>
                             </Box> 
                                  }
                  </span>

                       <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Want to show Second PDF on App?</label>
                    <RadioGroup id='time' name="use-radio-group" value={showPdf2} onChange={handleShowPdf2}>
                      <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                      <MyFormControlLabel value="0" label="No" control={<Radio />} />
                    </RadioGroup>
                  </Box>
                  <span className={`w-4/5 ${showPdf2 !== '1' && 'hidden'}`}>
                      <Box className='w-4/5 mt-7 ml-7'>
                              <TextField id="filled-error-helper-text" name={`pdfTitle2`} defaultValue={viewData?.pdf_title_second} error={formik.errors.pdfTitle2} helperText={formik.errors.pdfTitle2} onBlur={formik.handleChange} label={`Second PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Second PDF Cover: </label>
                    <label htmlFor="uploadImage7" className="">
                      <input type="file" id="uploadImage7" accept="image/*" hidden onChange={(e) => { handleSelectCover2(e) }} />
                      {coverPreview2 && <img src={coverPreview2} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!coverPreview2) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(coverPreview2) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box> 
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Select Option to Add Second PDF?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handlePdfRadioChange2}>
                                  <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                                  <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                             {pdfRadioValue2 === '0' ?
                             <Box className='w-full flex flex-col items-center justify-center'>
                                  <Box className='w-full mt-10'>
                                  <TextField id="filled-error-helper-text" name={`pdf_file_second`} defaultValue={viewData?.pdf_file_second} error={formik.errors.pdf_file_second} helperText={formik.errors.pdf_file_second} onBlur={formik.handleChange} label={`Second PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                                  </Box>
                                  </Box>
                                  : 
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Second PDF File: </label>
                    <label htmlFor="uploadImage8" className="">
                      <input type="file" id="uploadImage8" hidden onChange={(e) => { handleSelectPdf2(e) }} />                        
                      <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{pdfPreview2 && pdfPreview2}</p></div>
                    </label>
                             </Box> 
                                  }

                    </span> */}



                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            
                              <label className='ml-10 md:ml-24 font-semibold mr-5'>Logo: </label>
                    <label htmlFor="uploadImage" className="">
                      <input type="file" id="uploadImage" accept="image/*" hidden onChange={(e) => { handleSelectLogo(e) }} />
                      {logoPreview && <img src={logoPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!logoPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(logoPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box>
                           
                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                  
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Small Logo: </label>
                    <label htmlFor="uploadImage2" className="">
                      <input type="file" id="uploadImage2" accept="image/*" hidden onChange={(e) => { handleSelectSmallLogo(e) }} />
                      {smallLogoPreview && <img src={smallLogoPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                      {(!smallLogoPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(smallLogoPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                  </Box>
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} disabled={!formik.isValid || checkAnswersValid()} type='submit' onClick={() => {setFormSubmit(true)}}>Update Auto Show</Button> }
                             </Box>
                             </form> 
                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className={`pt-2 pl-5 `}><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpen(false);
                           navigate('/autoshow');
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2}}
                 >
                   Auto Show is successfully Updated
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default EditAutoShow;
