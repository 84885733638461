import React, { useRef, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import html2canvas from 'html2canvas';

const ChartGenerator = ({chartData, chartOptions, setGraphImage}) => {
  const chartRef = useRef(null);
  const [chartRendered, setChartRendered] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(()=>{
    if(chartRendered){
    const chartRenderTimeout = setTimeout(() => {
        captureChartAsImage();
      }, 2000); // Adjust the delay as needed
  
      return () => {
        clearTimeout(chartRenderTimeout);
      };
    }
  },[chartRendered])

  useEffect(() => {
    const chartRenderTimeout = setTimeout(() => {
      setChartRendered(true);
    }, 1000); // Adjust the delay as needed

    return () => {
      clearTimeout(chartRenderTimeout);
    };
  }, []);

  const captureChartAsImage = () => {
    setLoader(true);
    if (chartRendered && chartRef.current) {
      const chartContainer = chartRef.current;
      html2canvas(chartContainer).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png');
        setGraphImage(dataURL);
        setLoader(false);
      });
    }
  };
  useEffect(()=>{
  },[chartOptions])

  return (
    <div className="">
      {chartRendered && (
        <div>
        <div ref={chartRef}>
          <ReactApexChart options={chartOptions} series={chartData} height={500} type="bar"/>
        </div>
        </div>
      )}
    </div>
  );
};

export default ChartGenerator;
