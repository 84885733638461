import React,{useEffect, useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {images} from '../../../assets/Images.js';
import { styled } from '@mui/material/styles';
import { alpha} from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SurveyContext } from '../../../contextApi/SurveyContext.ts';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import UseSurveysApiCalls from '../../../hooks/SurveysApiCalls.ts';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import { SelectedEventContext } from '../../../contextApi/selectedEventContext.ts';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important"
  }
}));

function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

function createData(
  id: number,
  title: string,
  title_bm: string,
  dated: any,
) {
  return {id, title, title_bm, dated};
}

function Surveys() {
  const [searching, setSearching] = useState('');
  const [size1, setSize1] = useState(2);
  const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const {surveyContextData, setSurveyContextData} = useContext(SurveyContext);
  const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const [openErr, setOpenErr] = useState(false);
  const [queryOptions, setQueryOptions] = React.useState({});

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', width: 220 },
    { field: 'title_bm', headerName: 'Title(BM)', width: 200 },
    { field: 'survey_submission_count', headerName: 'Total Submissions', width: 170 },
    { field: 'points', headerName: 'Points', width: 100 },
    { field: 'from_date', headerName: 'Start Date', width: 130,
      renderCell: (params) => {
      var formattedDate = params.row.from_date;
      console.log('formatted date is ', formattedDate);
      if(formattedDate){
        const localDate = moment.utc(formattedDate).local().format('DD-MM-yyyy');
      return <div className="rowitem">{localDate}</div> }
      },
    },
    { field: 'to_date', headerName: 'End Date', width: 130,
      renderCell: (params) => {
      var formattedDate = params.row.to_date;
      console.log('formatted date is ', formattedDate);
      if(formattedDate){
        const localDate = moment.utc(formattedDate).local().format('DD-MM-yyyy');
      return <div className="rowitem">{localDate}</div> }
      },
    },
    {
      field: 'id',
      headerName: 'Action',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
  
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
        };
        let quizID= params.formattedValue;
        var name = params.row.title;
        return <span className='text-xs'><NavLink to={`/surveyDetails/${params.formattedValue}/${name}`}><Button variant="outlined" className='mx-2 h-5' sx={{fontSize: '10px', marginRight: '3px'}}>View</Button></NavLink></span>;
      },
    },
  ];

  // ---------------------------------------------------------------------

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleSearchChange = (e) => {
    handleOnChangeSearch(e.target.value);
    setSearching(e.target.value);
  }
  const getSortedData = async (sortingData) => {
    handleSortingData(sortingData);
    const field = sortingData.sortModel[0].field;
    const type = sortingData.sortModel[0].sort;
    setIsSorted(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-surveys?sort=${field}&sort_type=${type}&query=${searching}&event_id=${selectedEvent}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setSurveyContextData(response.data);
        setPage(1);
      }
    }catch(err){
      console.log('err in sorting surveys', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const handleSortModelChange = ((sortModel: GridSortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
    getSortedData({ sortModel: [...sortModel] });
  });
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value);
    setPage(value);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      setPage(1);
      handleSearch('initial');
    }
  }
  const handleRefresh = () => {
    setSearching('');
    setPage(1);
    setSurveyContextData([]);
    handleRefreshCall();
  }
  const handleClearSearch = () => {
    setSearching('');
    setPage(1);
    handleClearSearchCall();
    setSearching('');
  }
  const {getSurveys, handleOnChangeSearch, handleSearch, handleSortingData, handlePagination, unathenticated, errMessage, sortingField, handleRefreshCall, handleClearSearchCall}=UseSurveysApiCalls();
  useEffect(()=>{
    window.scrollTo(0, 0);
    if(selectedEvent){
      getSurveys();
    }
  },[selectedEvent])
  useEffect(()=>{
    if(errMessage){
      setOpenErr(true);
    }
  },[errMessage])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])

  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Survey' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-between'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Surveys</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Surveys</span></Typography>
                </Box>
                <Box className='mr-20 mt-5'>

                  <Box className='w-60 lg:w-96 h-12 shadow bg-white shadow-lg hidden sm:flex ml-10  lg:-mt-1 lg:ml-8 xl:ml-24 2xl:ml-28 flex items-center justify-center rounded-lg'>
                  
                  <Box>
                    <input
                      value={searching}
                      type='text'
                      onChange = {handleSearchChange}
                      placeholder='Search items'
                      className='w-40 lg:w-72 h-7 focus:outline-none mr-2 '
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <Box className='w-5 h-5 mr-3'>
                  <span className='w-5 h-5 cursor-pointer' onClick={()=>{handleSearch('initial'); setPage(1)}}> <img
                        src={images.searchIcon}
                        style={{ width: '100%', height: '100%' }}
                        alt=''
                      /></span>
                  </Box>
                  <Box className='w-5 h-5'>
                    <ClearIcon className='text-blue-400 -mt-2 ml-2 cursor-pointer' onClick={handleClearSearch}/>
                  </Box>
                </Box>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>Surveys</Typography>
                           <span className='mb-2 sm:mb-0 sm:mr-5'><Button variant="contained" className='px-5 h-10' onClick={handleRefresh}>Refresh</Button></span>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}

                            <div style={{ height: 550, width: '100%' }}>
                            {(surveyContextData?.surveys?.length > 0) ? <StripedDataGrid
                                  sortingOrder={['asc', 'desc']}
                                    getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                   }
                                   rowHeight={40}
                                   columns={columns}
                                  rows={surveyContextData?.surveys}
                                  sortingMode="server"
                                  onSortModelChange={handleSortModelChange}
                                  components={
                                   {ColumnUnsortedIcon: CustomUnsortedIcon}
                                  }
                                  disableColumnMenu
                                   />  : (unathenticated || surveyContextData?.total_pages === 0 ) ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box>}
                                   </div>
                                   {(parseInt(surveyContextData?.page) > surveyContextData?.total_pages) ? '' : <Box className='w-1/2 mr-5 lg:mr-8 2xl:mr-12 right-0 -mt-12 absolute overflow-none z-40 h-10 bg-white text-left flex items-center justify-start'>
                                       <Box className='sm:ml-auto sm:mr-12 flex items-center'>
                                            <Pagination count={surveyContextData?.total_pages} variant="outlined" shape="rounded" size="small" page={page} onChange={handlePageChange}/>
                                       </Box>
                                   </Box> }
                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default Surveys;
