import React,{useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { PushMessagesContext } from '../contextApi/PushMessagesContext.ts';
import {DeleteAlert} from '../components/Alert/Alert.tsx';
import UseDeletePopUp from './UseDeletePopUp.ts';
import { useNavigate } from 'react-router-dom';

const UsePushMessagesApiCalls = () => {
  const {pushMessagesContextData, setPushMessagesContextData, pushMessagesDelete, setPushMessagesDelete} = useContext(PushMessagesContext);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  const [unathenticated, setAuthenticated] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const handleOnChangeSearch = (value)=>{
    setSearchInput(value);
  }
   const handleSortingData = (sortingData) => {
    setSortingField(sortingData.sortModel[0].field);
    setSortingType(sortingData.sortModel[0].sort);
    
   }
   const handlePagination = (nextPage)=>{
    getPushMessages(nextPage);
   }
   const handleRefreshCall = () => {
    setSearchInput('');
    let from = 'refresh';
    let page = 1;
    getPushMessages(page, from);
   }
   const handleClearSearchCall = () => {
    setSearchInput('');
    let from = 'clearSearch';
    handleSearch(from);
   }
   const handleSearch = async (from = 'initial') => {
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-push-messages?query=${from === 'initial' ? searchInput : ''}&sort=${sortingField}&sort_type=${sortingType}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setPushMessagesContextData(response.data);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in pushMessages search', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    const getPushMessages = async (page=1, from = 'initial') => {
      setLoading(true);
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/get-push-messages?sort=${from === 'initial' ? sortingField : ''}&sort_type=${from === 'initial' ? sortingType : ''}&query=${from === 'initial' ? searchInput : ''}&page=${page}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setPushMessagesContextData(response.data);
            console.log('response of push messages is ', response);
            setLoading(false);
          }
        }catch(err){
          setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
          console.log('err in get pushMessages ', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }

      const handleDeletePushMessages = async (pushMessageID) => {
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/delete-push-message/${pushMessageID}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setPushMessagesDelete(true);
            console.log('response of delete push message is ', response);
          }
        }catch(err){
          alert(err.response.data.message);
          console.log('err in delete push message :', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      
      }
      
  return {
    getPushMessages,
    handleOnChangeSearch,
    handleSearch,
    loading,
    unathenticated,
    handleSortingData,
    searchInput,
    handleDeletePushMessages,
    handlePagination,
    errMessage,
    handleRefreshCall,
    handleClearSearchCall
  };
};

export default UsePushMessagesApiCalls;