import React,{useState, useRef, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import {images} from '../../../../assets/Images.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { convertCompilerOptionsFromJson } from 'typescript';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import { SelectedEventContext } from '../../../../contextApi/selectedEventContext.ts';

function AddNewPromotion() {
    const [formSubmit, setFormSubmit] = useState(false);
    let navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
    const [open, setOpen] = useState(false);
    const [logoPreview, setLogoPreview] = useState();
    const [logoFile, setLogoFile] = useState();
    const [imagePreview, setImagePreview] = useState();
    const [imageFile, setImageFile] = useState();
    const initialValues={
         promotionImage: '',
         link: '',
         sort: '',
       }

    const validationSchema =Yup.object ({
         promotionImage: Yup.string().required('Required'),
         sort: Yup.string().required('Required'),

    })

    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        // if(imageFile){
     try{
        setBtnLoader(true);
        try {
          var formData = new FormData();
          formData.append("link", values.link);
          formData.append("sort", values.sort);
          formData.append("promotion_image", values.promotionImage);
          const accessToken = localStorage.getItem('auth');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/add-promotion?&event_id=${selectedEvent}`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 201){
            setOpen(true);
            setBtnLoader(false);
          }
    }catch(err){
      console.log('err in add promotion', err.response);
      alert(err.response.data.message);
      setBtnLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
    }catch(error){
        helpers.setErrors({submit:error.message});
        setBtnLoader(false);
        if(error?.response?.status === 401){
          localStorage.setItem('auth','');
          navigate('/login');
        }
    }
  // }else{
  //   alert('Promotion Image File is Required')
  // }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const getRegExpForSorting = ['e', 'E', '+', '-', '.'];

  const handleSelectLogo = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setLogoFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectImage = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setImageFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
          navigate('/promotions');
       }, 2000);
      }
    },[open])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Promotions' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Add Promotion</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/promotions'>Promotions </NavLink>/ <span className='font-semibold'>Add Promotion</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Add Promotion</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5 mt-0'>
                              <TextField id="filled-error-helper-text" name={`promotionImage`} error={formik.errors.promotionImage} helperText={formik.errors.promotionImage} onBlur={formik.handleChange} label={`Promotion Image url`} placeholder="eg: https://example.com" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                              {/* <label className='ml-10 md:ml-24 font-semibold mr-5'>Promotion Image: </label>
                    <label htmlFor="uploadImage" className="">
                      <input type="file" id="uploadImage" accept="image/*" hidden onChange={(e) => { handleSelectImage(e) }} />
                      {imagePreview && <img src={imagePreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!imagePreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(imagePreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label> */}
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`link`} onBlur={formik.handleChange} label={`Link`} placeholder="eg: https://example.com" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                              
                              {/* <label className='ml-10 md:ml-24 font-semibold mr-5'>Logo: </label>
                              <label htmlFor="uploadImage2" className="">
                      <input type="file" id="uploadImage2" accept="image/*" hidden onChange={(e) => { handleSelectLogo(e) }} />
                      {logoPreview && <img src={logoPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!logoPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(logoPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label> */}
                             </Box>
                             <Box className='w-4/5 mt-10'>
                               <TextField id="filled-error-helper-text" name={`sort`} error={formik.errors.sort} onKeyDown={(e) => getRegExpForSorting.includes(e.key) && e.preventDefault()} helperText={formik.errors.sort} onBlur={formik.handleChange} label={`Sorting value`} placeholder="e.g 2" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='number' InputProps={{ inputProps: { min: 0 } }}/>
                             </Box>
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' onClick={() => {setFormSubmit(true)}} disabled={!formik.isValid}>Add Promotion</Button> }
                             </Box>
                             </form>

                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    navigate('/promotions');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2}}
            >
              Promotion is successfully Created
            </Alert>
          </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default AddNewPromotion;
