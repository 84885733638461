import React,{useState, useContext, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { Alert, Collapse, IconButton,} from '@mui/material';
import ChartGenerator from '../../Booths/ViewBoothDetails/ChartGenerator.tsx';

function ViewFoodFiestasDetails() {
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();
    const [size1, setSize1] = useState(2);
    const [size2, setSize2] = useState(10);
    const [isGenerateGraph, setIsGenerateGraph] = useState(false);
    const [graphImage, setGraphImage] = useState('');
    const [chartData, setChartData] = useState();
    const [chartOptions, setChartOptions] = useState();
    const [reportLoader, setReportLoader] = useState(false);
    const [activeToggle, setActiveToggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewData, setViewData] = useState();
    const componentRef = useRef(null);
    const { id } = useParams();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  
  //-----------------------------------------------------------------------
  const getFoodFiestasById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-foodfiesta/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.food_fiesta);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in view data', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
 
  useEffect(()=>{
      getFoodFiestasById();
      
      
  },[])

  useEffect(()=>{
    if(open){
      setTimeout(function(){
        setOpen(false);
        setReportLoader(false);
     }, 2000);
    }
  },[open])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='FoodFiestas' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Maybank Islamic MAE-Dan Selera Details</Typography>
                    <Typography variant='body1' sx={{}} className='pt-2'><NavLink to='/foodfiestas'>Maybank Islamic MAE-Dan Selera </NavLink>/ <span className='font-semibold cursor-pointer'>Maybank Islamic MAE-Dan Selera Details</span></Typography>
                </Box>                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                {!loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>View Maybank Islamic MAE-Dan Selera</Typography>
                         </Box>
                         <Box className='mt-10 mb-20 flex flex-col lg:flex-row items-center lg:items-start justify-start'>
                          {/* -------------------------Table---------------------------- */}

                          <Box className='w-full lg:w-2/3 flex flex-col items-start justify-start text-left'>
                              <Box className='mt-20 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Logo</span>:</Typography>
                                 <Box className='w-60 h-52 ml-5 object-cover'>
                                       <img src={`${viewData?.logo}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box>
                              {viewData?.small_logo ? <Box className='mt-20 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Small Logo</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.small_logo}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                                 <Typography variant='body1' className='pt-5 pl-5'><span className='font-bold'>Name</span>: {viewData?.name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Name(BM)</span>: {viewData?.name_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Tagline</span>: {viewData?.tagline}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Tagline(BM)</span>: {viewData?.tagline_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Name</span>: {viewData?.contact_name}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Phone</span>: {viewData?.contact_phone}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Contact Email</span>: {viewData?.contact_email}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Description</span>: {viewData?.description}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Description(BM)</span>: {viewData?.description_bm}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Instagram Link</span>: {viewData?.instagram_url}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Facebook Link</span>: {viewData?.facebook_url}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Twitter Link</span>: {viewData?.twitter_url}</Typography>
                                 {viewData?.video_url?.length && viewData?.video_url?.map((item, index) => {
                                  return(
                                    <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Video Url {index+1}</span>: {item}</Typography>
                                  )
                                 })}
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF Title</span>: {viewData?.pdf_title}</Typography>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF File</span>: {viewData?.pdf_file}</Typography>
                                 {viewData?.pdf_cover ? <Box className='mt-3 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>PDF Cover</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.pdf_cover}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                                 
                              <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF Title</span>: {viewData?.pdf_title_second}</Typography>
                                  <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF File</span>: {viewData?.pdf_file_second}</Typography>
                                 {viewData?.pdf_cover_second ? <Box className='mt-3 xl:mt-5 flex flex-col sm:flex-row items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Second PDF Cover</span>:</Typography>
                                 <Box className='w-48 h-40 ml-5 object-cover'>
                                       <img src={`${viewData?.pdf_cover_second}`} className='w-full h-full object-contain' />
                                 </Box>
                              </Box> : ''}
                                 
                                 {isGenerateGraph && <div className='relative z-40'><ChartGenerator setGraphImage={setGraphImage} chartData={chartData} chartOptions={chartOptions}/></div> }
                          </Box>
                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box> 
                <Box className={`w-full ${!loading ? '' : 'fixed'} bottom-0 h-10 bg-white text-left flex items-center justify-start`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpen(false);
                           setReportLoader(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errorMsg}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewFoodFiestasDetails;
