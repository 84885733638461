import React,{useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import axios from 'axios';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import './AddNewBooth.css';
import { SelectedEventContext } from '../../../../contextApi/selectedEventContext.ts';
import { FormControlLabel, Radio, RadioGroup, useRadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import EventMap from './MapComponent.tsx';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

function AddNewBooth() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [optionsArr, setOptionsArr] = useState([{
      "title": "",
      "title_bm": "",
      "is_correct": '1'
    }, {
      "title": "",
      "title_bm": "",
      "is_correct": '0'
    }]);
    const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
    let navigate = useNavigate();
    let [optionsCheck, setOptionsCheck] = useState(false);
    const [radioValue, setRadioValue] = useState("0");
    const [goldRadioValue, setGoldRadioValue] = useState("0");
    const [pdfRadioValue, setPdfRadioValue] = useState("0");
    const [btnLoader, setBtnLoader] = useState(false);
    const [showBooth, setShowBooth] = useState('Normal');
    const [showPdf1, setShowPdf1] = useState("1");
    const [showPdf2, setShowPdf2] = useState("1");
    const [logoPreview, setLogoPreview] = useState();
    const [videoLinksArr, setVideoLinksArr] = useState(['']);
    const [logoFile, setLogoFile] = useState();
    const [coverPreview, setCoverPreview] = useState();
    const [coverFile, setCoverFile] = useState();
    const [pdfFile, setPdfFile] = useState();
    const [pdfPreview, setPdfPreview] = useState();
    const [selectedBooth, setSelectedBooth] = useState('');
    const [coverPreview2, setCoverPreview2] = useState();
    const [coverFile2, setCoverFile2] = useState();
    const [pdfFile2, setPdfFile2] = useState();
    const [pdfPreview2, setPdfPreview2] = useState();
    const [pdfRadioValue2, setPdfRadioValue2] = useState("0");
    const [smallLogoPreview, setSmallLogoPreview] = useState();
    const [smallLogoFile, setSmallLogoFile] = useState();
    const [removeLoader, setRemoveLoader] = useState(false);
    const [errPopUp, setErrorPopUp] = useState(false);
    const initialValues={
        name: '',
        name_malaysian: '',
        boothNumber: '',
        tagline: '',
        points: '',
        logoLink: '',
        smallLogoLink: '',
        tagline_malaysian: '',
        contactPersonName: '',
        contactPersonPhone: '',
        contactPersonEmail: '',
        description: '',
        description_malaysian: '',
        question_title: '',
        question_title_bm: '',
        question_score: '',
        pdfTitle: '',
        beacon_id: '',
        beacon_name: '',
        facebook_link: '',
        instagram_link: '',
        twitter_link: '',
        website_link: '',
        video_url: '',
        pdf_file: '',
        pdf_file_second: '',
        pdfTitle2: '',
       }
    const validationSchema =Yup.object ({
        name: Yup.string().required('Required'),
        name_malaysian: Yup.string().required('Required'),
        boothNumber: Yup.string().required('Required'),
        tagline: Yup.string().required('Required'),
        points: Yup.string().required('Required'),
        // logoLink: Yup.string().required('Required'),
        tagline_malaysian: Yup.string().required('Required'),
        contactPersonName: Yup.string().required('Required'),
        contactPersonPhone: Yup.string().required('Required'),
        contactPersonEmail: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        description_malaysian: Yup.string().required('Required'),
        question_title: radioValue === '1' ? Yup.string().required('Required') : '',
        question_title_bm: radioValue === '1' ? Yup.string().required('Required') : '',
        question_score: radioValue === '1' ? Yup.string().required('Required') : '',
        // pdfTitle: Yup.string().required('Required'),
        // video_url: Yup.string().required('Required'),
        // pdf_file: pdfRadioValue === '0' ? Yup.string().required('Required') : ''
    })
    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
    if(logoFile){
        setBtnLoader(true);
        console.log('values are : ', values);
        console.log('optionsArr is : ', optionsArr);
        let finalAnswers: any = []
        for(let i=0; i < optionsArr.length; i++){
          let obj = {
            "title": optionsArr[i].title,
            "title_bm": optionsArr[i].title_bm,
            "is_correct": Number(optionsArr[i].is_correct)
          }
          finalAnswers.push(obj);
        }
        console.log('finalAnswers is: ', finalAnswers);
     try{
        try {
          var formData = new FormData();
          formData.append("name", values.name);
          formData.append("name_bm", values.name_malaysian);
          formData.append("booth_number", values.boothNumber);
          formData.append("tagline", values.tagline);
          formData.append("points", values.points);
          formData.append("tagline_bm", values.tagline_malaysian);
          formData.append("contact_name", values.contactPersonName);
          formData.append("contact_phone", values.contactPersonPhone);
          formData.append("map_id", selectedBooth);
          formData.append("contact_email", values.contactPersonEmail);
          formData.append("description", values.description);
          formData.append("first_pdf_enabled", showPdf1);
          formData.append("second_pdf_enabled", showPdf2);
          formData.append("description_bm", values.description_malaysian);
          formData.append("contact_email", values.contactPersonEmail);
          formData.append("pdf_title", values.pdfTitle);
          formData.append("pdf_title_second", values.pdfTitle2);
          formData.append("beacon_id", values.beacon_id);
          formData.append("beacon_name", values.beacon_name);
          formData.append("twitter_url", values.twitter_link);
          formData.append("website_url", values.website_link);
          formData.append("instagram_url", values.instagram_link);
          formData.append("facebook_url", values.facebook_link);
          formData.append("logo", logoFile);
          let videoLinks = videoLinksArr;
          
          videoLinks.forEach((item, index) => {
            formData.append(`video_url[${index}]`, item === null ? '' : item);
          })
          if(smallLogoFile){
            formData.append("small_logo", smallLogoFile);
          }
         if(coverFile){
          formData.append("pdf_cover", coverFile);
         }
         if(coverFile2){
          formData.append("pdf_cover_second", coverFile2);
         }
          formData.append("type", showBooth);
            formData.append("pdf_file", values.pdf_file);
            formData.append("pdf_file_second", values.pdf_file_second);
          if(radioValue === '1'){
              formData.append("has_bonus_question", 1);
              formData.append("question_title", values.question_title);
              formData.append("question_title_bm", values.question_title_bm);
              formData.append("question_score", Number(values.question_score));
              finalAnswers.forEach((object, index) => {
                Object.keys(object).forEach((key) => {
                  formData.append(`answers[${index}][${key}]`, object[key]);
                });
              });

          }else{
            formData.append("has_bonus_question", 0);
          }
          if(showBooth !== "Hidden"){
            formData.append("is_gold_sponsored", Number(goldRadioValue));
          }
          
          const accessToken = localStorage.getItem('auth');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/add-booth?event_id=${selectedEvent}`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 201){
            setOpen(true);
            setBtnLoader(false);
          }
    }catch(err){
      console.log('err in add booth', err.response);
      alert(err.response.data.message);
      setBtnLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }

    }catch(error){
        helpers.setErrors({submit:error.message})
        setBtnLoader(false);
    }
  }else{
    alert('Logo File is Required')
  }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10); 
  const [activeToggle, setActiveToggle] = useState(false);
  const getRegExp = ['e', 'E', '+', '-', '.', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm','n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '~', '`', '!', '@', '#', '$', '%', '^', '&', '&', '*', '(', ')', '_', '=', '{','}', '[',']','|', '<', '>', ',', '?', '/',';',':','"',"'"];
  const getRegExpForPoints = ['e', 'E', '+', '-', '.'];

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleRadioChange = (e) => {
    console.log('value of radio is : ', e.target.value);
    setRadioValue(e.target.value);
}
const handleGoldRadioChange = (e) => {
  console.log('value of radio is : ', e.target.value);
  setGoldRadioValue(e.target.value);
}
const handlePdfRadioChange = (e) => {
  console.log('value of radio is : ', e.target.value);
  setPdfRadioValue(e.target.value);
}
const handleShowPdf1 = (e) => {
  console.log('value of radio is : ', e.target.value);
  setShowPdf1(e.target.value);
}
const handleShowPdf2 = (e) => {
  console.log('value of radio is : ', e.target.value);
  setShowPdf2(e.target.value);
}

   const handleShowBooth = (e) => {
    setShowBooth(e.target.value);
   }
   const handleContactNum = (e) => {
    if(/^[0-9]+$/.test(e.target.value)){
      formik.handleChange(e)
    }else{
      return
    }
   }

   const handleCorrectAnswer = (index) => {
    let tempOptionsArr = optionsArr;
    for(let i=0; i < tempOptionsArr.length; i++){
      if(tempOptionsArr[i].is_correct === '1' && index !== i){
        tempOptionsArr[i].is_correct = '0'
      }else if(tempOptionsArr[i].is_correct === '0' && index === i){
        tempOptionsArr[i].is_correct = '1'
      }
    }
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptionsArr);
    
   }

   const handleAddOption = () => {
    let tempOptions = optionsArr;
    let obj = {
      "title": "",
      "title_bm": "",
      "is_correct": '0'
    }
    
    tempOptions.push(obj);
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptions);
   }
   const handleAddVideo = () => {
    let tempVideoLinksArr = videoLinksArr;
    tempVideoLinksArr.push('');
    setVideoLinksArr(tempVideoLinksArr);
    setOptionsCheck(!optionsCheck);
   }
   const handleRemoveVideo = (index) => {
    let tempVideoLinksArr = [...videoLinksArr];
    tempVideoLinksArr.splice(index, 1);
    setVideoLinksArr(tempVideoLinksArr);
    setOptionsCheck(!optionsCheck);
   }
   const handleVideoLinkValue = (index, e) => {
    let value = e.target.value;
    let tempVideoLinksArr = [...videoLinksArr];
    tempVideoLinksArr[index] = value;
    setVideoLinksArr(tempVideoLinksArr);
   }
   const handleRemoveOption = (index) => {
    if(optionsArr.length > 2){
      let tempOptionsArr = optionsArr;
      tempOptionsArr[index].title = "";
      tempOptionsArr[index].title_bm = "";
      tempOptionsArr[index].is_correct = "0";

      tempOptionsArr.splice(index,1);
      setOptionsCheck(!optionsCheck);
      setOptionsArr(tempOptionsArr);
      console.log('after removing option arr is : ', tempOptionsArr);
    }
  } 
  const handleAddValues = (index, fieldName, e) => {
    let tempOptionsArr = optionsArr;
    if(fieldName === 'title'){
      tempOptionsArr[index].title = e.target.value;
    }else if(fieldName === 'title_bm'){
      tempOptionsArr[index].title_bm = e.target.value;
    }
    setOptionsCheck(!optionsCheck);
    setOptionsArr(tempOptionsArr);
    
  }

  const checkAnswersValid = () => {
    if(formSubmit && radioValue === '1'){
      for(let i=0; i < optionsArr.length; i++){
        if(optionsArr[i].title === "" || optionsArr[i].title_bm === ""){
          return true;
        }
      }
    }else{
      return false;
    }
    
  }

  const handleSelectLogo = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setLogoFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectCover = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setCoverFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectPdf = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setPdfFile(e.target.files[0]);
      formik.setFieldValue('pdf_file', e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setPdfPreview(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectCover2 = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setCoverFile2(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview2(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectPdf2 = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setPdfFile(e.target.files[0]);
      formik.setFieldValue('pdf_file_second', e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setPdfPreview2(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }
  const handlePdfRadioChange2 = (e) => {
    setPdfRadioValue2(e.target.value);
  }

  const handleSelectSmallLogo = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setSmallLogoFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSmallLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

    useEffect(()=>{
    },[removeLoader, optionsArr])

    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
          navigate('/');
       }, 2000);
      }
    },[open])

    useEffect(()=>{
      if(errPopUp){
        setTimeout(function(){
         setErrorPopUp(false);
       }, 2000);
      }
    },[errPopUp])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Booths' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Add Booth</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/'>Booths </NavLink>/ <span className='font-semibold'>Add Booth</span></Typography>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Add Booth</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5'>
                              <TextField id="filled-error-helper-text" name={`name`} error={formik.errors.name} helperText={formik.errors.name} onBlur={formik.handleChange} label={`Name`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`name_malaysian`} error={formik.errors.name_malaysian} helperText={formik.errors.name_malaysian} onBlur={formik.handleChange} label={`Name(Malaysian)`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`boothNumber`} error={formik.errors.boothNumber} helperText={formik.errors.boothNumber} onBlur={formik.handleChange} label={`Booth Number`} placeholder="e.g B7100" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`tagline`} error={formik.errors.tagline} helperText={formik.errors.tagline} onBlur={formik.handleChange} label={`Tagline`} placeholder="e.g Think Different, Be What's Next" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`tagline_malaysian`} error={formik.errors.tagline_malaysian} helperText={formik.errors.tagline_malaysian} onBlur={formik.handleChange} label={`Tagline(Malaysian)`} placeholder="e.g Think Different, Be What's Next" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonName`} error={formik.errors.contactPersonName} helperText={formik.errors.contactPersonName} onBlur={formik.handleChange} label={`Contact Person's Name`} placeholder="e.g Vinay" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonPhone`} onKeyDown={(e) => getRegExp.includes(e.key) && e.preventDefault()} error={formik.errors.contactPersonPhone} helperText={formik.errors.contactPersonPhone} onBlur={(e)=>{handleContactNum(e)}} label={`Contact Person's Phone`} placeholder="e.g 03615706678" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text' InputProps={{ inputProps: { min: 0 } }}/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`contactPersonEmail`} error={formik.errors.contactPersonEmail} helperText={formik.errors.contactPersonEmail} onBlur={formik.handleChange} label={`Contact Person's Email`} placeholder="e.g support@pnb.com.my" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='email'/>
                             </Box>
                             <Box className='w-4/5 mt-12'>
                             <TextareaAutosize id="filled-error-helper-text" aria-label="minimum height" name='description' minRows={3} error={formik.errors.description} helperText={formik.errors.description} onBlur={formik.handleChange} placeholder="Description" variant="filled" className={`border border-2 focus:outline-none px-2 rounded w-4/5`}/>
                             <p className='text-red-600 text-center'>{formik.errors.description}</p>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                             <TextareaAutosize aria-label="minimum height" minRows={3} name='description_malaysian' helperText={formik.errors.description_malaysian} helperText={formik.errors.description_malaysian} onBlur={formik.handleChange} placeholder="Description(Malaysian)" className={`border border-2 focus:outline-none px-2 rounded w-4/5`}/>
                             <p className='text-red-600 text-center'>{formik.errors.description_malaysian}</p>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`points`} error={formik.errors.points} onKeyDown={(e) => getRegExpForPoints.includes(e.key) && e.preventDefault()} helperText={formik.errors.points} onBlur={formik.handleChange} label={`Points`} placeholder="e.g 2" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='number' InputProps={{ inputProps: { min: 0 } }}/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`beacon_id`} error={formik.errors.beacon_id} helperText={formik.errors.beacon_id} onBlur={formik.handleChange} label={`Beacon ID`} placeholder="e.g xyz" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`beacon_name`} error={formik.errors.beacon_name} helperText={formik.errors.beacon_name} onBlur={formik.handleChange} label={`Beacon Name`} placeholder="e.g xyz" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`facebook_link`} error={formik.errors.facebook_link} helperText={formik.errors.facebook_link} onBlur={formik.handleChange} label={`Facebook Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`twitter_link`} error={formik.errors.twitter_link} helperText={formik.errors.twitter_link} onBlur={formik.handleChange} label={`Twitter Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                          
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`instagram_link`} error={formik.errors.instagram_link} helperText={formik.errors.instagram_link} onBlur={formik.handleChange} label={`Instagram Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`website_link`} error={formik.errors.website_link} helperText={formik.errors.website_link} onBlur={formik.handleChange} label={`Website Url`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                             </Box>
                             {videoLinksArr.length && videoLinksArr.map((item, index) => {
                                 return(
                                  <Box className={`w-4/5 mt-10 flex items-center justify-center ${index !== 0 && 'ml-5'}`}>
                                    <TextField id="filled-error-helper-text" onChange={(e)=>{handleVideoLinkValue(index, e)}} value={item} label={`Video Url ${index+1}`} placeholder="e.g https://xxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                                    <span className={`${index === 0 && 'hidden'}`}><CloseIcon className={`cursor-pointer `} sx={{fontSize: '16px'}} onClick={()=>{handleRemoveVideo(index)}}/></span>
                                  </Box>
                                 )
                             })}
                             <Button variant="outlined" sx={{marginTop: '45px', width: '200px'}} onClick={handleAddVideo}>Add more video</Button>
                 
                    <Box className='w-4/5 mt-14 flex items-center justify-start'>
                      <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Want to show First PDF on App?</label>
                      <RadioGroup id='time' name="use-radio-group" defaultValue="1" onChange={handleShowPdf1}>
                        <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                        <MyFormControlLabel value="0" label="No" control={<Radio />} />
                      </RadioGroup>
                    </Box>
                    <span className={`w-4/5 ${showPdf1 !== '1' && 'hidden'}`}>


                    <Box className='w-4/5 mt-7 ml-7'>
                      <TextField id="filled-error-helper-text" name={`pdfTitle`} value={formik.values.pdfTitle} error={formik.errors.pdfTitle} helperText={formik.errors.pdfTitle} onChange={formik.handleChange} label={`First PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                    </Box>
                    
                    <Box className='w-4/5 mt-14 flex items-center justify-start'>
                      <label className='ml-10 md:ml-24 font-semibold mr-5'>First PDF Cover: </label>
                      <label htmlFor="uploadImage3" className="">
                        <input type="file" id="uploadImage3" accept="image/*" hidden onChange={(e) => { handleSelectCover(e) }} />
                        {coverPreview && <img src={coverPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                        {(!coverPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                        {(coverPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                      </label>
                    </Box>
                    <Box className='w-4/5 mt-14 flex items-center justify-start'>
                      <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Select Option to Add PDF?</label>
                      <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handlePdfRadioChange}>
                        <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                        <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                      </RadioGroup>
                    </Box>
                    {pdfRadioValue === '0' ?
                      <Box className='w-full flex flex-col items-center justify-center'>
                        <Box className='w-full mt-8'>
                          <TextField id="filled-error-helper-text" name={`pdf_file`} value={formik.values.pdf_file} error={formik.errors.pdf_file} helperText={formik.errors.pdf_file} onChange={formik.handleChange} label={`First PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='url' />
                        </Box>
                      </Box>
                      :
                      <Box className='w-4/5 mt-14 flex items-center justify-start'>
                        <label className='ml-10 md:ml-24 font-semibold mr-5'>First PDF File: </label>
                        <label htmlFor="uploadImage4" className="">
                          <input type="file" id="uploadImage4" hidden onChange={(e) => { handleSelectPdf(e) }} />
                          <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{pdfPreview && pdfPreview}</p></div>
                        </label>
                      </Box>
                    }
                  </span>
                    {/* -------------------------------------------Second PDF-------------------------------- */}
                    <Box className='w-4/5 mt-14 flex items-center justify-start'>
                      <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Want to show Second PDF on App?</label>
                      <RadioGroup id='time' name="use-radio-group" defaultValue="1" onChange={handleShowPdf2}>
                        <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                        <MyFormControlLabel value="0" label="No" control={<Radio />} />
                      </RadioGroup>
                    </Box>
                    <span className={`w-4/5 ${showPdf2 !== '1' && 'hidden'}`}>
                    <Box className='w-4/5 mt-7 ml-7'>
                              <TextField id="filled-error-helper-text" name={`pdfTitle2`} error={formik.errors.pdfTitle2} helperText={formik.errors.pdfTitle2} onBlur={formik.handleChange} label={`Second PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Second PDF Cover: </label>
                    <label htmlFor="uploadImage7" className="">
                      <input type="file" id="uploadImage7" accept="image/*" hidden onChange={(e) => { handleSelectCover2(e) }} />
                      {coverPreview2 && <img src={coverPreview2} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!coverPreview2) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(coverPreview2) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box> 
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Select Option to Add Second PDF?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handlePdfRadioChange2}>
                                  <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                                  <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                             {pdfRadioValue2 === '0' ?
                             <Box className='w-full flex flex-col items-center justify-center'>
                                  <Box className='w-full mt-10'>
                                  <TextField id="filled-error-helper-text" name={`pdf_file_second`} error={formik.errors.pdf_file_second} helperText={formik.errors.pdf_file_second} onBlur={formik.handleChange} label={`Second PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url'/>
                                  </Box>
                                  </Box>
                                  : 
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Second PDF File: </label>
                    <label htmlFor="uploadImage8" className="">
                      <input type="file" id="uploadImage8" hidden onChange={(e) => { handleSelectPdf2(e) }} />                        
                      <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{pdfPreview2 && pdfPreview2}</p></div>
                    </label>
                             </Box> 
                                  }
                    </span>

                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label className='ml-10 md:ml-24 font-semibold mr-5'>Logo: </label>
                    <label htmlFor="uploadImage" className="">
                      <input type="file" id="uploadImage" accept="image/*" hidden onChange={(e) => { handleSelectLogo(e) }} />
                      {logoPreview && <img src={logoPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}
                        
                      {(!logoPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(logoPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box> 
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                              <label className='ml-10 md:ml-24 font-semibold mr-5'>Small Logo: </label>
                    <label htmlFor="uploadImage2" className="">
                      <input type="file" id="uploadImage2" accept="image/*" hidden onChange={(e) => { handleSelectSmallLogo(e) }} />
                      {smallLogoPreview && <img src={smallLogoPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                      {(!smallLogoPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                      {(smallLogoPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                    </label>
                             </Box>
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Booth type?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="Normal" onChange={handleShowBooth}>
                                  <MyFormControlLabel value="Normal" label="Normal" control={<Radio />} />
                                  <MyFormControlLabel value="Bonus" label="Bonus" control={<Radio />} />
                                  <MyFormControlLabel value="Hidden" label="Hidden" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                            {showBooth !== "Hidden" && <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Is Gold Sponsored?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handleGoldRadioChange}>
                                  <MyFormControlLabel value="0" label="No" control={<Radio />} />
                                  <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                             </RadioGroup>
                             </Box> }
                             <Box className='w-4/5 mt-14 flex items-center justify-start'>
                            <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Have Bonus Question?</label>
                             <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handleRadioChange}>
                                  <MyFormControlLabel value="0" label="No" control={<Radio />} />
                                  <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                             </RadioGroup>
                             </Box>
                             {radioValue === '1' ?
                             <Box className='w-full flex flex-col items-center justify-center'>
                                  <Box className='w-4/5 mt-10'>
                                  <TextField id="filled-error-helper-text" name={`question_title`} error={formik.errors.question_title} helperText={formik.errors.question_title} onBlur={formik.handleChange} label={`Question Title`} placeholder="Recommended Length: less than 50 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                                  </Box>
                                  <Box className='w-4/5 mt-10'>
                                  <TextField id="filled-error-helper-text" name={`question_title_bm`} error={formik.errors.question_title_bm} helperText={formik.errors.question_title_bm} onBlur={formik.handleChange} label={`Question Title(Malaysian)`} placeholder="Recommended Length: less than 50 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                                  </Box>
                                  <Box className='w-4/5 mt-10'>
                                  <TextField id="filled-error-helper-text" name={`question_score`} error={formik.errors.question_score} helperText={formik.errors.question_score} onKeyDown={(e) => getRegExpForPoints.includes(e.key) && e.preventDefault()} onBlur={formik.handleChange} label={`Question Score`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='number' InputProps={{ inputProps: { min: 0 } }}/>
                                  </Box>
                                  <label htmlFor='time' className='ml-10 md:ml-24 mt-10 font-semibold mr-5'>Answer Options</label>
                                  {optionsArr.length > 0 ? optionsArr.map((item, index)=>{
                                    return(
                                      <Box className='w-4/5 mt-10 flex flex-col justify-center items-center border border-1 border-gray-400'>
                                        <Box className='w-full flex items-center justify-between p-2'>
                                          <h1>{index+1}</h1>
                                          <span className={`${(item.is_correct === '1' || optionsArr.length < 3) && 'hidden'}`}><CloseIcon className={`cursor-pointer ${item.is_correct === '1' && 'hidden'}`} sx={{fontSize: '16px'}} onClick={()=>{handleRemoveOption(index)}}/></span>
                                        </Box>
                                        <Box className='w-5/6 '>
                                          <TextField id="filled-error-helper-text" error={formSubmit && item.title === ""} helperText={'Field is required'} value={item.title} onChange={(e)=>{handleAddValues(index, 'title', e)}} label={`Answer Title`} placeholder="Recommended Length: less than 50 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                        </Box>
                                        <Box className='w-5/6 mt-10'>
                                          <TextField id="filled-error-helper-text" error={formSubmit && item.title_bm === ""} helperText={'Field is required'} value={item.title_bm}  onChange={(e)=>{handleAddValues(index, 'title_bm', e)}} label={`Answer Title(Malaysian)`} placeholder="Recommended Length: less than 50 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                        </Box>
                                        <Box className='w-5/6 mt-10 flex items-center justify-start'>
                                          <label htmlFor='time' className='ml-10 md:ml-24 font-semibold mr-5'>Is correct?</label>
                                          <Radio
                                            checked={item.is_correct === '1'}
                                            onChange={() => { handleCorrectAnswer(index) }}
                                            value="a"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </Box>
                                      </Box>
                                    )
                                  }) : ''}
                                   <Button variant="outlined" sx={{marginTop: '15px', width: '200px'}} onClick={handleAddOption}>Add more option</Button>
                             </Box> : ''}

                             <label htmlFor='time' className=' mt-10 mb-3 font-semibold mr-5'>Select Booth Location from map</label>
                             <EventMap selectedBooth={selectedBooth} setSelectedBooth={setSelectedBooth}/>




                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left items-center justify-center'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' disabled={!formik.isValid || checkAnswersValid()} onClick={() => {setFormSubmit(true)}}>Add Booth</Button> }
                             </Box> 
                             </form>
                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className={`pt-2 pl-5 `}><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpen(false);
                           navigate('/');
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2}}
                 >
                    Booth is successfully Created
                 </Alert>
               </Collapse>
               <Collapse in={errPopUp} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setErrorPopUp(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    Please choose at least one banner
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default AddNewBooth;
