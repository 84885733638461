import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom'

function EditAdmin() {
    const [formSubmit, setFormSubmit] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [errOpen, setErrOpen] = useState(false);
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
  const { id } = useParams();
    let navigate = useNavigate();
    const initialValues={
        fullName: viewData?.name ? viewData.name : '',
        email: viewData?.email ? viewData.email : '',
       }

    const validationSchema =Yup.object ({
        fullName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),

    })

    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
        setBtnLoader(true);
     try{
      try {
        var formData = new FormData();
        formData.append("name", values.fullName);
        if(values.email != viewData?.email){
          formData.append("email", values.email);
        }
        const accessToken = localStorage.getItem('auth');
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/update-user/${id}`,
          formData,
          {
            headers: { 
              'Content-Type' : 'application/json',
              'Accept' : 'application/json',
              'Authorization' : `Bearer ${accessToken}`
            },
            withCredentials: false,
          }
        );
        if(response.status === 200){
          console.log('response is ',response);
          setOpen(true);
          setBtnLoader(false);
        }
  }catch(err){
    console.log('err in edit new admin', err.response);
    setErrOpen(true);
    setBtnLoader(false);
    if(err?.response?.status === 401){
      localStorage.setItem('auth','');
      navigate('/login');
    }
  }
    }catch(error){
        helpers.setErrors({submit:error.message})
        setBtnLoader(false);
        if(error?.response?.status === 401){
          localStorage.setItem('auth','');
          navigate('/login');
        }
    }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }

  const getAdminById = async () => {
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-user/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.user);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in edit admin', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  useEffect(()=>{
    getAdminById();
 },[]) 

 useEffect(()=>{
  if(open){
    setTimeout(function(){
      setOpen(false);
      navigate('/staffManagement');
   }, 2000);
  }
},[open])

useEffect(()=>{
  if(errOpen){
    setTimeout(function(){
      setErrOpen(false);
   }, 2000);
  }
},[errOpen])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Staff' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Edit Admin</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/staffManagement'>Staff Management </NavLink>/ <span className='font-semibold'>Edit Admin</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                { !loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Edit Admin</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='w-4/5 mt-5'>
                              <TextField id="filled-error-helper-text" name={`fullName`} defaultValue={viewData?.name} error={formik.errors.fullName} helperText={formik.errors.fullName} onBlur={formik.handleChange} label={`Full Name`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`email`} defaultValue={viewData?.email} error={formik.errors.email} helperText={formik.errors.email} onBlur={formik.handleChange} label={`Email`} placeholder="" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='email'/>
                             </Box>                           
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' onClick={() => {setFormSubmit(true)}} disabled={!formik.isValid}>Update Admin</Button> }
                             </Box>
                             </form>

                         </Box>
                    </Box>  : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setOpen(false);
                         navigate('/staffManagement');
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2}}
                  >
                        Admin is successfully Updated
                  </Alert>
                </Collapse>
                <Collapse in={errOpen} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setErrOpen(false);
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                  >
                        Email already exists
                  </Alert>
                </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default EditAdmin;
