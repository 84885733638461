import React,{useEffect, useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { alpha} from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink } from 'react-router-dom';
import { StaffContext } from '../../../contextApi/StaffContext.ts';
import UseStaffApiCalls from '../../../hooks/StaffApiCalls.ts';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import AlertDialogSlide from '../../../components/AlertDialog/AlertDialog.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important"
  }
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}
function createData(
  id: number,
  fullName: string,
  username: string
) {
  return {id, fullName, username };
}


function StaffManagement() {
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [deletingID, setDeletingID] = useState();
  const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [openErr, setOpenErr] = useState(false);
  const [page, setPage] = useState(1);
  const {staffContextData, setStaffContextData, staffDelete, setStaffDelete} = useContext(StaffContext);
  const [activeToggle, setActiveToggle] = useState(false);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Full Name', width: 420, sortable: false, },
    { field: 'email', headerName: 'Email', width: 420, sortable: false, },
    {
      field: 'id',
      headerName: 'Action',
      width: 170,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); 
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );
          return alert(JSON.stringify(thisRow, null, 4));
        };
        let adminID= params.formattedValue;
        return <span className='text-xs'><NavLink to={`/editAdmin/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5 w-6' sx={{fontSize: '10px'}}>Edit</Button></NavLink> <Button variant="outlined" className='mx-2 h-5 w-6' sx={{fontSize: '10px'}} onClick={()=>{handleOpenPopUp(adminID)}}>Delete</Button></span>;
      },
    },
  ];

  // -----------------------------------------------------------------
  
  const handleOpenPopUp = (id) => {
    setDeletingID(id);
    setOpenDeletePopUp(true);
  }
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleClosePopUp = () => {
    setOpenDeletePopUp(false);
  }
  const handleSortModelChange = ((sortModel: GridSortModel) => {
  });
  useEffect(()=>{
    window.scrollTo(0, 0);
    getUsers();
  },[])
  useEffect(()=>{
    if(staffDelete){
      setOpenDeletePopUp(false);
      setPage(1);
      getUsers();
      setTimeout(function(){
        setStaffDelete(false);
     }, 2000);
    }
  },[staffDelete])
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value);
    setPage(value);
  };
  const handleRefresh = () => {
    setPage(1);
    setStaffContextData([]);
    handleRefreshCall();
  }
  const {getUsers, handleSortingData, handlePagination, unathenticated, errMessage, handleRefreshCall}=UseStaffApiCalls();
  useEffect(()=>{
    if(errMessage){
      setOpenErr(true);
    }
  },[errMessage])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Staff' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-between'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2 flex'>Staff<span className='hidden lg:flex ml-2'>Management</span></Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Staff Management</span></Typography>
                </Box>
                <Box className='mr-20 mt-5'>

                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>Staff Users</Typography>
                           <span className='mr-5 flex flex-col sm:flex-row'> <span className='mb-2 sm:mb-0 sm:mr-5'><Button variant="contained" className='px-5 h-10' onClick={handleRefresh}>Refresh</Button></span>{!unathenticated ? <NavLink to='/addNewAdmin'><Button variant="contained" className='px-5 h-10'>Add New <span className='hidden sm:flex ml-1'>Admin</span></Button></NavLink> : ''}</span>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}

                            <div style={{ height: 550, width: '100%' }}>
                            {(staffContextData?.users?.length > 0 ) ?  <StripedDataGrid
                                    getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                   }
                                   rowHeight={40}
                                   columns={columns}
                                  rows={staffContextData?.users}
                                  sortingMode="server"
                                  onSortModelChange={handleSortModelChange}
                                  components={{
                                    ColumnUnsortedIcon: CustomUnsortedIcon
                                  }}
                                  disableColumnMenu
                                   /> : (unathenticated || staffContextData?.total_pages === 0) ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                                   </div>
                                   {(parseInt(staffContextData?.page) > staffContextData?.total_pages) ? '' : <Box className='w-1/2 mr-5 lg:mr-8 2xl:mr-12 right-0 -mt-12 absolute overflow-none z-40 h-10 bg-white text-left flex items-center justify-start'>
                                        <Box className='sm:ml-auto sm:mr-12 flex items-center'>
                                            <Pagination count={staffContextData?.total_pages} variant="outlined" shape="rounded" size="small" page={page} onChange={handlePageChange}/>
                                        </Box>
                                   </Box> }

                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={staffDelete} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setStaffDelete(false);
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2}}
                  >
                        Staff is successfully Deleted
                  </Alert>
                </Collapse>
                <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
                {openDeletePopUp ? <AlertDialogSlide id={deletingID} from='Admin' handleClosePopUp={handleClosePopUp}/> :  ''}
            </Grid>
        </Grid>
    </Box>
  );
}

export default StaffManagement;
