import React,{useState} from 'react';
import './App.css';
import Routers from './routes/Routes';
import {BoothsContext} from './contextApi/BoothsContext.ts';
import {SchedulesContext} from './contextApi/SchedulesContext.ts';
import {PromotionsContext} from './contextApi/PromotionsContext.ts';
import {QuizContext} from './contextApi/QuizContext.ts';
import {QuizDetailsContext} from './contextApi/QuizDetailsContext.ts';
import {StaffContext} from './contextApi/StaffContext.ts';
import {LoginContext} from './contextApi/LoginContext.ts';
import {SideBarContext} from './contextApi/SideBarContext.ts';
import {UsersListContext} from './contextApi/UsersListContext.ts';
import {PushMessagesContext} from './contextApi/PushMessagesContext.ts';
import {SurveyContext} from './contextApi/SurveyContext.ts';
import {SurveyDetailsContext} from './contextApi/SurveyDetailsContext.ts';
import { SurveyExportContext } from './contextApi/SurveyExportContext.ts';
import { SelectedEventContext } from './contextApi/selectedEventContext.ts';
import { AllEventsContext } from './contextApi/allEventsContext.ts';
import { AutoShowContext } from './contextApi/AutoShowContext.ts';
import { FoodFiestasContext } from './contextApi/FoodFiestasContext.ts';

function App() {
  const [boothsContextData, setBoothsContextData] = useState([]);
  const [autoShowContextData, setAutoShowContextData] = useState([]);
  const [foodFiestasContextData, setFoodFiestasContextData] = useState([]);
  const [autoShowDelete, setAutoShowDelete] = useState(false);
  const [foodFiestasDelete, setFoodFiestasDelete] = useState(false);
  const [boothDelete, setBoothDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [schedulesContextData, setSchedulesContextData] = useState([]);
  const [scheduleDelete, setScheduleDelete] = useState(false);
  const [scheduleSearch, setScheduleSearch] = useState('');
  const [promotionsContextData, setPromotionsContextData] = useState([]);
  const [quizContextData, setQuizContextData] = useState([]);
  const [quizBlock, setQuizBlock] = useState(false);
  const [surveyContextData, setSurveyContextData] = useState([]);
  const [surveyExportContextData, setSurveyExportContextData] = useState([]);
  const [quizDetailsContextData, setQuizDetailsContextData] = useState([]);
  const [surveyDetailsContextData, setSurveyDetailsContextData] = useState([]);
  const [promotionDelete, setPromotionDelete] = useState(false);
  const [staffContextData, setStaffContextData] = useState([]);
  const [staffDelete, setStaffDelete] = useState(false);
  const [adminRole, setAdminRole] = useState('');
  const [openSubList, setOpenSubList] = useState(false);
  const [usersListContextData, setUsersListContextData] = useState([]);
  const [userDelete, setUserDelete] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [pushMessagesContextData, setPushMessagesContextData] = useState([]);
  const [pushMessagesDelete, setPushMessagesDelete] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [allEventsContextData, setAllEventsContextData] = useState([]);
  return (
    <div className="App">
      <LoginContext.Provider value={{adminRole, setAdminRole}}>
         <SideBarContext.Provider value={{openSubList, setOpenSubList}}>
            <UsersListContext.Provider value={{usersListContextData, setUsersListContextData, userSearch, setUserSearch, userDelete, setUserDelete}}>
               <SurveyContext.Provider value={{surveyContextData, setSurveyContextData}}>
                  <SurveyExportContext.Provider value={{surveyExportContextData, setSurveyExportContextData}}>
                     <SurveyDetailsContext.Provider value={{surveyDetailsContextData, setSurveyDetailsContextData}}>
                         <QuizContext.Provider value={{quizContextData, setQuizContextData, quizBlock, setQuizBlock}}>
                           <QuizDetailsContext.Provider value={{quizDetailsContextData, setQuizDetailsContextData}}>
                              <PromotionsContext.Provider value={{promotionsContextData, setPromotionsContextData, promotionDelete, setPromotionDelete}}>
                                 <PushMessagesContext.Provider value={{pushMessagesContextData, setPushMessagesContextData, pushMessagesDelete, setPushMessagesDelete}}>
                                    <StaffContext.Provider value={{staffContextData, setStaffContextData, staffDelete, setStaffDelete}}>
                                       <SchedulesContext.Provider value={{schedulesContextData, setSchedulesContextData, scheduleDelete, setScheduleDelete, scheduleSearch, setScheduleSearch}}>
                                          <AutoShowContext.Provider value={{autoShowContextData, setAutoShowContextData, autoShowDelete, setAutoShowDelete, searchQuery, setSearchQuery}}>
                                             <FoodFiestasContext.Provider value={{foodFiestasContextData, setFoodFiestasContextData, foodFiestasDelete, setFoodFiestasDelete, searchQuery, setSearchQuery}}>
                                                <BoothsContext.Provider value={{boothsContextData, setBoothsContextData, boothDelete, setBoothDelete, searchQuery, setSearchQuery}}>
                                                   <SelectedEventContext.Provider value={{selectedEvent, setSelectedEvent}}>
                                                      <AllEventsContext.Provider value={{allEventsContextData, setAllEventsContextData}}>  
                                                         <Routers />
                                                      </AllEventsContext.Provider>
                                                   </SelectedEventContext.Provider>
                                                </BoothsContext.Provider>
                                             </FoodFiestasContext.Provider>
                                          </AutoShowContext.Provider>
                                       </SchedulesContext.Provider>
                                    </StaffContext.Provider>
                                 </PushMessagesContext.Provider>
                              </PromotionsContext.Provider> 
                           </QuizDetailsContext.Provider> 
                        </QuizContext.Provider>
                     </SurveyDetailsContext.Provider>
                  </SurveyExportContext.Provider>
               </SurveyContext.Provider>
            </UsersListContext.Provider>       
         </SideBarContext.Provider>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
