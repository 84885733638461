import React,{useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NavLink, Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import { images } from '../../assets/Images';
import Typography from '@mui/material/Typography';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { LoginContext } from '../../contextApi/LoginContext.ts';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import AttractionsIcon from '@mui/icons-material/Attractions';
import axios from 'axios';
import { SideBarContext } from '../../contextApi/SideBarContext.ts';
import Groups2Icon from '@mui/icons-material/Groups2';
import PollIcon from '@mui/icons-material/Poll';
import GetAppIcon from '@mui/icons-material/GetApp';
import FormControl from '@mui/material/FormControl';
import {SelectChangeEvent } from '@mui/material/Select';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { SelectedEventContext } from '../../contextApi/selectedEventContext.ts';
import { AllEventsContext } from '../../contextApi/allEventsContext.ts';
import WindowSharpIcon from '@mui/icons-material/WindowSharp';
import FastfoodSharpIcon from '@mui/icons-material/FastfoodSharp';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import Select from 'react-select';

const Sidebar = ({active, activeToggle}) => {
  let navigate = useNavigate();
  const {adminRole, setAdminRole} = useContext(LoginContext);
  const {openSubList, setOpenSubList} = useContext(SideBarContext);
  const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const {allEventsContextData, setAllEventsContextData} = useContext(AllEventsContext);
  const [options, setOptions] = useState([]);
  const [eventLoader, setEventLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [pnbEvent, setPnbEvent] = React.useState('');
  const getAdminRole = async () => {
    try{
      const accessToken = localStorage.getItem('auth');
      const id = localStorage.getItem('id');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-user/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setAdminRole(response.data.user.role);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      if(err.response.status === 401){
        alert(`${err.response.data.message}`);
        localStorage.setItem('auth', '');
      }
     
      console.log('err in getting role is : ', err);
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEvent(event.target.value);
  };
  // const handleChange = (selected) => {
  //   setSelectedEvent(selected);
  // };

  const getAllEvents = async () =>{
    setEventLoader(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-events`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        let tempArr = response.data.events.reverse();
        let tempOptions= [];
        for(let i=0; i < tempArr.length; i++){
          let obj = {
            "value": tempArr[i].id, "label": `${tempArr[i].name}`
          }
          tempOptions.push(obj);

        }
        setOptions(tempOptions);
        setAllEventsContextData(tempArr);
        setSelectedEvent(tempArr[1].id)
        setEventLoader(false);
      }
    }catch(err){
      setEventLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
      console.log('err in get all events ', err);
    }
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
    // if(!adminRole){
    //   getAdminRole();
    // }
  },[])
  useEffect(()=>{
    if(allEventsContextData.length === 0){
      console.log('in condition of getAllEvents useEffect')
      getAllEvents();
    }
  },[allEventsContextData])

  useEffect(()=>{
    if(active === 'TermsContent' || active === 'PdpaContent' || active === 'Content'){
      setOpenSubList(true);
    }else{
      setOpenSubList(false);
    }
  },[])

    return(
        <Box className='flex flex-col items-center justify-center w-full' sx={{backgroundColor: '#484f4f'}}>
          <Box className='flex items-start flex-col w-full'>
            <Box>
                <Box className='w-full flex items-center justify-center mt-7'>
                    <img src={images.logoWhite} className='w-3/5'/>
                </Box>
                <Typography variant='body1' sx={{margin: 'auto', textAlign: 'center'}} className='text-white pt-2 hidden sm:block'>Admin</Typography>
            </Box>
            <p className='mt-8 pl-1 text-white'>Select Event</p>
            {allEventsContextData.length > 0 ? <span className={`${activeToggle ? 'pl-0' : 'sm:pl-0.5'}`}><FormControl sx={{ width: activeToggle ? 110 : 220, color: 'white' }} size="small">
            {/* <InputLabel id="demo-select-small-label" sx={{color: 'white'}}>Selected Event</InputLabel> */}
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedEvent}
                // multiline
                // rows={2}
                label=""
                onChange={handleChange}
                sx={{
                  '& .MuiSelect-select': {
                    color: 'white',
                    fontSize: '14px',// Change text color
                    '&:focus': {
                      backgroundColor: 'transparent', // Remove focus background color
                    },
                  },
                  '& .MuiSelect-icon': {
                    color: 'white', // Change arrow color
                  },
                  '& .MuiSelect-outlined': {
                    border: '1px solid white', // Change border color
                  },
                }}
              >
                {allEventsContextData.map((item: any, index: number) => {
                  return(
                    <MenuItem value={item?.id}>{item?.short_name}</MenuItem>
                  )
                })}
                
              </Select>
            </FormControl></span> : <span className={`text-white ${activeToggle ? 'pl-0' : 'sm:pl-0'} pl-0`}>Loading...</span>}

            <ul className={`mt-2 text-white font-semibold flex flex-col items-center sm:items-start justify-center sm:justify-start w-full ${activeToggle ? 'pl-5' : ''}`}>
            
                <Link to='/' className='w-full'><li className={`py-2 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Booths' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><GridViewOutlinedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Booths</span></ li></Link>
                <Link to='/autoshow' className='w-full'><li className={`py-2 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'AutoShow' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><WindowSharpIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Auto Show</span></ li></Link>
                <Link to='/foodfiestas' className='w-full'><li className={`py-2 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'FoodFiestas' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><FastfoodSharpIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Maybank Islamic MAE-Dan Selera</span></ li></Link>
                <Link to='/schedule' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Schedule' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><ScheduleRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Schedule</span></ li></Link>
                <Link to='/promotions' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Promotions' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><CheckCircleOutlineRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Promotions</span></ li></Link>
                <Link to='/socialLinks' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'SocialLinks' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><CheckCircleOutlineRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Social Links</span></ li></Link>
                <Link to='/usersStats' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'UsersStats' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><PeopleRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Users Stats</span></ li></Link>
                <Link to='/usersList' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'UsersList' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><Groups2Icon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Users</span></ li></Link>
                <Link to='/quiz' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Quiz' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><ArticleIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Quiz</span></ li></Link>
                <Link to='/surveys' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Survey' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><PollIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Surveys</span></ li></Link>
                {/* <Link to='/exportsurveys' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'ExportSurvey' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><GetAppIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Export Surveys</span></ li></Link> */}
                <Link to='/sustainability' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Sustainability' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><AppRegistrationIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/><span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}> Sustainability</span></ li></Link>
                <li onClick={()=>{setOpenSubList(!openSubList)}} className={`w-full py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'AboutContent' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><DifferenceRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex '}`}>App Content</span></ li>
                       <span className={`w-full ${openSubList ? '' : 'hidden'}`}>
                          {/* <Link to='/appContent' className='w-full'><li className={`py-2 text-gray-300 pl-12 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Content' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><InfoIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex '}`}>About</span></ li></Link> */}
                          <Link to='/termsAndConditions' className='w-full'><li className={`py-2 text-gray-300 pl-12 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'TermsContent' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><ArticleIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex '}`}>T & C's</span></ li></Link>
                          {/* <Link to='/pdpa' className='w-full'><li className={`py-2 text-gray-300 pl-12 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'PdpaContent' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><AttractionsIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={`${activeToggle ? 'hidden' : 'hidden sm:flex '}`}>PDPA</span></ li></Link> */}
                        </span>
                {/* <Link to='/pushMessages' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Messages' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><MailOutlineRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Push Messages</span></ li></Link> */}
                <Link to='/staffManagement' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Staff' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><Groups2RoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Staff Management</span></ li></Link>
                <Link to='/updatePassword' className='w-full'><li className={`py-2 text-gray-300 pl-5 hover:bg-gray-600 hover:text-white w-full flex items-start justify-start cursor-pointer ${active === 'Password' ? 'bg-gray-600 text-white' : 'text-gray-300'}`}><KeyRoundedIcon sx={{fontSize: '20px', margin: '2px', marginRight: '10px'}}/> <span className={` ${activeToggle ? 'hidden' : 'hidden sm:flex'}`}>Update Password</span></ li></Link>
            </ul>
          </Box>
        </Box>
    );
}

export default Sidebar;