import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, styled, TextField, useRadioGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
  }
  
const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme?.palette?.primary?.main,
    },
  }));

function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }

function Sustainability() {
    const [btnLoader, setBtnLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [size1, setSize1] = useState(2);
    const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [openErr, setOpenErr] = useState(false);
  const [activeToggle, setActiveToggle] = useState(false);
  const [unathenticated, setAuthenticated] = useState(false);
  const [firstPdfTitle, setFirstPdfTitle] = useState();
  const [secondPdfTitle, setSecondPdfTitle] = useState();
  const [coverPreview, setCoverPreview] = useState();
  const [coverFile, setCoverFile] = useState();
  const [errMessage, setErrMessage] = useState('');
  const [coverTitle, setCoverTitle] = useState('');
  const [fPcoverPreview, setFPCoverPreview] = useState();
  const [fPcoverFile, setFPCoverFile] = useState();
  const [fPdfFile, setFPdfFile] = useState();
  const [fPdfPreview, setFPdfPreview] = useState();
  
  const [sPcoverPreview, setSPCoverPreview] = useState();
  const [sPcoverFile, setSPCoverFile] = useState();
  const [sPdfFile, setSPdfFile] = useState();
  const [sPdfPreview, setSPdfPreview] = useState();
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const [tncContent, setTncContent] = useState();
  const [aboutBMContent, setAboutBMContent] = useState();
  const [fPdfRadioValue, setFPdfRadioValue] = useState("0");
  const [instaLink, setInstaLink] = useState();
  const [showPdf1, setShowPdf1] = useState();
  const [showPdf2, setShowPdf2] = useState();
  const [sPdfRadioValue, setSPdfRadioValue] = useState("0");
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
  const onChangeAboutEvent = (content) => {
    setTncContent(content)
  }
  const onChangeAboutMBEvent = (content) => {
    setAboutBMContent(content)
  }
  const onScrollEvent = (event) => {
  }
  const onClickEvent = (event) => {
  }
  const onMouseDownEvent = (event) => {
  }
  const onKeyUpEvent = (event) => {
  }
  const onFocusEvent = (event) => {
  }
  const handleSubmit = async () => {
    setBtnLoader(true);
    try {
      var formData = new FormData();
      if(tncContent){
        formData.append("tnc", tncContent);
      }
      if(instaLink){
        formData.append("insta_link", instaLink);
      }
      if(coverFile !== viewData?.cover_image){
        formData.append("cover_image", coverFile);
      }
      if(fPcoverFile !== viewData?.first_pdf_cover){
        formData.append("first_pdf_cover", fPcoverFile);
      }if(fPdfFile){
        formData.append("first_pdf", fPdfFile);
      }if(firstPdfTitle){
        formData.append("first_pdf_title", firstPdfTitle);
      }
      formData.append("first_pdf_enabled", showPdf1);
      formData.append("cover_title", coverTitle);
      formData.append("second_pdf_enabled", showPdf2);
      if(sPcoverFile !== viewData?.second_pdf_cover){
        formData.append("second_pdf_cover", sPcoverFile);
      }if(sPdfFile){
        formData.append("second_pdf", sPdfFile);
      }if(secondPdfTitle){
        formData.append("second_pdf_title", secondPdfTitle);
      }
      const accessToken = localStorage.getItem('auth');
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/update-sustainability`,
        formData,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setOpen(true);
        setBtnLoader(false);
      }
     }catch(err){
     alert(err.response.data.message);
     setBtnLoader(false);
     if(err?.response?.status === 401){
      localStorage.setItem('auth','');
      navigate('/login');
    }
   }
  }
  const getAppContent = async () => {
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-sustainability`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response.data :', response.data);
        setViewData(response.data.sustainability);
        setTncContent(response.data.sustainability.tnc);
        setCoverPreview(response.data.sustainability.cover_image);
        setCoverFile(response.data.sustainability.cover_image);
        setCoverTitle(response.data.sustainability.cover_title);
        setFPdfFile(response.data.sustainability.first_pdf);
        setFPdfPreview(response.data.sustainability.first_pdf);
        setSPdfFile(response.data.sustainability.second_pdf);
        setShowPdf1(`${response.data.sustainability.first_pdf_enabled}`);
        setShowPdf2(`${response.data.sustainability.second_pdf_enabled}`);
        setSPdfPreview(response.data.sustainability.second_pdf);
        setFPCoverPreview(response.data.sustainability.first_pdf_cover);
        setFPCoverFile(response.data.sustainability.first_pdf_cover);
        setSPCoverPreview(response.data.sustainability.second_pdf_cover);
        setSPCoverFile(response.data.sustainability.second_pdf_cover);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      setAuthenticated(true);
      setErrMessage(`${err.response.data.message}`);
      console.log('err in getting app content ', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const handleSelectCover = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setCoverFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectFPCover = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setFPCoverFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFPCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }
  const handleShowPdf1 = (e) => {
    console.log('value of radio is : ', e.target.value);
    setShowPdf1(e.target.value);
  }
  const handleShowPdf2 = (e) => {
    console.log('value of radio is : ', e.target.value);
    setShowPdf2(e.target.value);
  }

  const handleSelectFPdf = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setFPdfFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setFPdfPreview(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleFPdfRadioChange = (e) => {
    setFPdfRadioValue(e.target.value);
  }




  
  const handleSelectSPCover = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun');
      setSPCoverFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSPCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSelectSPdf = (e) => {
    try{
      const obj: any = { [e.target.name]: e.target.value };
    if (e.target.type === "file") {
      if (obj["attachments"] !== "") {
      console.log('in setting file preview fun and file is : ', e.target.files[0]);
      setSPdfFile(e.target.files[0]);
      const file = e.target.files[0];
    if (file) {
      console.log('file name is : ', file?.name)
      setSPdfPreview(file?.name);
    }
    }
    
  }
    }catch(err: any){
      console.log('error in selecting file ')
    }
  }

  const handleSPdfRadioChange = (e) => {
    setSPdfRadioValue(e.target.value);
  }

    useEffect(()=>{
      window.scrollTo(0, 0);
        getAppContent();
    },[])
    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
       }, 2000);
      }
    },[open])
    useEffect(()=>{
      if(errMessage){
        setOpenErr(true);
      }
    },[errMessage])
    useEffect(()=>{
      if(openErr){
        setTimeout(function(){
          setOpenErr(false);
       }, 2000);
      }
    },[openErr])
  return (
    <Box className='AppContent'>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Sustainability' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Sustainability</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'> Home / <span className='font-semibold'>Sustainability</span></Typography>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                { !loading && !unathenticated ?   <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Sustainability</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center'>
                                  <Box className='w-4/5 mt-10'>
                                      <TextField id="filled-error-helper-text" name={`coverTitle`} defaultValue={viewData?.cover_title} onBlur={(e) => { setCoverTitle(e.target.value) }} label={`Cover Image Title`} placeholder="e.g Test cover" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                      <label className='font-semibold mr-5'>Cover Image: </label>
                                      <label htmlFor="uploadImage" className="">
                                          <input type="file" id="uploadImage" accept="image/*" hidden onChange={(e) => { handleSelectCover(e) }} />
                                          {coverPreview && <img src={coverPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                                          {(!coverPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                                          {(coverPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                                      </label>
                                  </Box>
                                  
                                  <Box className='w-4/5 mt-10'>
                                      <TextField id="filled-error-helper-text" name={`pdfTitle`} defaultValue={viewData?.insta_link} onBlur={(e) => { setInstaLink(e.target.value) }} label={`Instagram Link`} placeholder="e.g https://test.com" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label htmlFor='time' className=' font-semibold mr-5'>Want to show First PDF on App?</label>
                    <RadioGroup id='time' name="use-radio-group" value={showPdf1} onChange={handleShowPdf1}>
                      <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                      <MyFormControlLabel value="0" label="No" control={<Radio />} />
                    </RadioGroup>
                  </Box>
                  <span className={`w-4/5 ${showPdf1 !== '1' && 'hidden'}`}>  

                                  <Box className='w-4/5 mt-10'>
                                      <TextField id="filled-error-helper-text" name={`pdfTitle`} defaultValue={viewData?.first_pdf_title} onBlur={(e) => { setFirstPdfTitle(e.target.value) }} label={`First PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                      <label className='font-semibold mr-5'>First PDF Cover: </label>
                                      <label htmlFor="uploadImage3" className="">
                                          <input type="file" id="uploadImage3" accept="image/*" hidden onChange={(e) => { handleSelectFPCover(e) }} />
                                          {fPcoverPreview && <img src={fPcoverPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                                          {(!fPcoverPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                                          {(fPcoverPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                                      </label>
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                      <label htmlFor='time' className='font-semibold mr-5'>Select Option to Add First PDF?</label>
                                      <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handleFPdfRadioChange}>
                                          <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                                          <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                                      </RadioGroup>
                                  </Box>
                                  {fPdfRadioValue === '0' ?
                                      <Box className='w-full flex flex-col items-center justify-center'>
                                          <Box className='w-full mt-10'>
                                              <TextField id="filled-error-helper-text" name={`pdf_file`} defaultValue={viewData?.first_pdf} onBlur={(e) => { setFPdfFile(e.target.value) }} label={`First PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='url' />
                                          </Box>
                                      </Box>
                                      :
                                      <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                          <label className='font-semibold mr-5'>First PDF File: </label>
                                          <label htmlFor="uploadImage4" className="">
                                              <input type="file" id="uploadImage4" hidden onChange={(e) => { handleSelectFPdf(e) }} />
                                              <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{fPdfPreview && fPdfPreview}</p></div>
                                          </label>
                                      </Box>
                                  }
                         </span>

                                  {/* ----------------------------2nd PDF------------------------------------ */}
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                    <label htmlFor='time' className='font-semibold mr-5'>Want to show Second PDF on App?</label>
                    <RadioGroup id='time' name="use-radio-group" value={showPdf2} onChange={handleShowPdf2}>
                      <MyFormControlLabel value="1" label="Yes" control={<Radio />} />
                      <MyFormControlLabel value="0" label="No" control={<Radio />} />
                    </RadioGroup>
                  </Box>
                  <span className={`w-4/5 ${showPdf2 !== '1' && 'hidden'}`}>
                                  <Box className='w-4/5 mt-10'>
                                      <TextField id="filled-error-helper-text" name={`pdfTitle`} defaultValue={viewData?.second_pdf_title} onBlur={(e) => { setSecondPdfTitle(e.target.value) }} label={`Second PDF Title`} placeholder="e.g Test pdf" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='text' />
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                      <label className='font-semibold mr-5'>Second PDF Cover: </label>
                                      <label htmlFor="uploadImage6" className="">
                                          <input type="file" id="uploadImage6" accept="image/*" hidden onChange={(e) => { handleSelectSPCover(e) }} />
                                          {sPcoverPreview && <img src={sPcoverPreview} alt='profile' className={`w-20 h-20 rounded border-2 border-gray-500 cursor-pointer`} />}

                                          {(!sPcoverPreview) && <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div>}
                                          {(sPcoverPreview) && <div className='absolute bg-blue-200 rounded-full flex items-center justify-center w-5 h-5 cursor-pointer' style={{ marginLeft: '70px', marginTop: '-87px' }} > <EditSharpIcon className='text-xl 2xl:text-3xl' sx={{ color: '#284D91', fontSize: '16px' }} /></div>}
                                      </label>
                                  </Box>
                                  <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                      <label htmlFor='time' className='font-semibold mr-5'>Select Option to Add Second PDF?</label>
                                      <RadioGroup id='time' name="use-radio-group" defaultValue="0" onChange={handleSPdfRadioChange}>
                                          <MyFormControlLabel value="0" label="Add Url" control={<Radio />} />
                                          <MyFormControlLabel value="1" label="Add File" control={<Radio />} />
                                      </RadioGroup>
                                  </Box>
                                  {sPdfRadioValue === '0' ?
                                      <Box className='w-full flex flex-col items-center justify-center'>
                                          <Box className='w-full mt-10'>
                                              <TextField id="filled-error-helper-text" name={`pdf_file`} defaultValue={viewData?.second_pdf} onBlur={(e) => { setSPdfFile(e.target.value) }} label={`Second PDF File Url`} placeholder="e.g htpps://xxxxxxx" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{ input: { background: "white" } }} type='url' />
                                          </Box>
                                      </Box>
                                      :
                                      <Box className='w-4/5 mt-14 flex items-center justify-start'>
                                          <label className='font-semibold mr-5'>Second PDF File: </label>
                                          <label htmlFor="uploadImage5" className="">
                                              <input type="file" id="uploadImage5" hidden onChange={(e) => { handleSelectSPdf(e) }} />
                                              <div className='flex items-center justify-center'> <div className="w-32 flex items-center justify-center text-white rounded cursor-pointer" style={{ background: "#284D91", textTransform: "none", height: '40px', width: '120px' }}>Select File</div><p className='ml-5'>{sPdfPreview && sPdfPreview}</p></div>
                                          </label>
                                      </Box>
                                  }

                        </span>
                             <Box className='w-4/5 mt-10 flex flex-col items-center justify-center'>

                              {/* ----------------------------Editor----------------------------- */}
                              <label className='font-bold mb-5'>Terms & Conditions:</label>
                              <SunEditor
                                name="myeditor"
                                height="400px"
                                placeholder="Type somthing here..."
                                defaultValue={viewData?.tnc}
                                onChange={onChangeAboutEvent}
                                onScroll={onScrollEvent}
                                onClick={onClickEvent}
                                onMouseDown={onMouseDownEvent}
                                onKeyUp={onKeyUpEvent}
                                onFocus={onFocusEvent}
                                setOptions={{
                                 buttonList: [
                                 ["undo", "redo"],
                                 ["font", "fontSize", "formatBlock"],
                                 ["paragraphStyle", "blockquote"],
                                 ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                 ["fontColor", "hiliteColor", "textStyle"],
                                 ["removeFormat"],
                                 ["outdent", "indent"],
                                 ["align", "horizontalRule", "list", "lineHeight"],
                                 ["table", "link", "image", "video", "audio"],
                                 ["imageGallery"],
                                 ["fullScreen", "showBlocks", "codeView"],
                                 ["preview", "print"],
                                 ["save", "template"]
                                ],
                               }}
                               
                              />
                             </Box>
                             
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 items-center justify-center text-center'>
                             {btnLoader ? <Box className='w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{backgroundColor: 'green'}} onClick={handleSubmit}>Update Sustainability</Button> }
                             </Box>

                             </form>
                         </Box>
                    </Box>  : unathenticated ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading && !unathenticated ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed z-20 top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                  action={
                  <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                  >
                       <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2}}
            >
              Sustainability is successfully Updated
            </Alert>
          </Collapse>
          <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default Sustainability;
