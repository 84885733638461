import React,{useEffect, useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {images} from '../../../assets/Images.js';
import { styled } from '@mui/material/styles';
import { alpha} from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { SchedulesContext } from '../../../contextApi/SchedulesContext.ts';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import UseSchedulesApiCalls from '../../../hooks/SchedulesApiCalls.ts';
import AlertDialogSlide from '../../../components/AlertDialog/AlertDialog.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectedEventContext } from '../../../contextApi/selectedEventContext.ts';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      //backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important"
  }
}));

function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

function createData(
  id: number,
  Name: string,
  NameBM: string,
  Location: any,
  Date: any,
  Start: any,
  End: any,
) {
  return {id, Name, NameBM, Location, Date, Start, End };
}

function Schedule() {
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [deletingID, setDeletingID] = useState();
  const [searching, setSearching] = useState('');
  const back = '<';
  const next = '>';
  const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [page, setPage] = useState(1);
  const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const {schedulesContextData, setSchedulesContextData, scheduleDelete, setScheduleDelete} = useContext(SchedulesContext);
  const [queryOptions, setQueryOptions] = React.useState({});

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'title_bm', headerName: 'Title(BM)', width: 150 },
    { field: 'location', headerName: 'Location', width: 150 },
    {
      field: 'dated',
      headerName: 'Date',
      width: 100,
      renderCell: (params) => {
        var formattedDate = params.row.dated;
        var newFormat = formattedDate.split("-").reverse().join("-");
        return <div className="rowitem">{newFormat}</div>
        },
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 140,
      renderCell: (params) => {
        var formattedDate = params.row.dated;
        var str = `${formattedDate} ${params.row.start_time}`;
        str = str.replace(/-/g,'/');
        var dt = new Date(`${str} UTC`);
        var d = new Date(dt);
        var datestring = String(d.getHours()). padStart(2, '0') + ":" + String(d.getMinutes()). padStart(2, '0');
        return <div className="rowitem">{params.row.start_time}</div>
        },
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 140,
      renderCell: (params) => {
        var formattedDate = params.row.dated;
        var str = `${formattedDate} ${params.row.end_time}`;
        str = str.replace(/-/g,'/');
        var dt = new Date(`${str} UTC`);
        var d = new Date(dt);
        var datestring = String(d.getHours()). padStart(2, '0') + ":" + String(d.getMinutes()). padStart(2, '0');
        return <div className="rowitem">{params.row.end_time}</div>
        },
    },
    {
      field: 'id',
      headerName: 'Action',
      width: 220,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
  
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};
  
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );
  
          return alert(JSON.stringify(thisRow, null, 4));
        };
        let scheduleID= params.formattedValue;
        return <span className='text-xs'><NavLink to={`/viewScheduleDetails/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5' sx={{fontSize: '10px', marginRight: '3px'}}>View</Button></NavLink><NavLink to={`/editSchedule/${params.formattedValue}`}><Button variant="outlined" className='mx-2 h-5 w-6' sx={{fontSize: '10px'}}>Edit</Button></NavLink> <Button variant="outlined" className='mx-2 h-5 w-6' sx={{fontSize: '10px'}} onClick={()=>{handleOpenPopUp(scheduleID)}}>Delete</Button></span>;
      },
    },
  ];

  // ---------------------------------------------------------------------
  
  const handleOpenPopUp = (id) => {
    setDeletingID(id);
    setOpenDeletePopUp(true);
  }
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleSearchChange = (e) => {
    handleOnChangeSearch(e.target.value);
    setSearching(e.target.value);
  }
  const handleClosePopUp = () => {
    setOpenDeletePopUp(false);
  }
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value);
    setPage(value);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      setPage(1);
      handleSearch('initial');
    }
  }
  const handleRefresh = () => {
    setSearching('');
    setPage(1);
    setSchedulesContextData([]);
    handleRefreshCall();
  }
  const handleClearSearch = () => {
    setSearching('');
    setPage(1);
    handleClearSearchCall();
    setSearching('');
  }
  const {getSchedules, handleOnChangeSearch, handleSearch, handlePagination, unathenticated, errMessage, handleRefreshCall, handleClearSearchCall}=UseSchedulesApiCalls();
  useEffect(()=>{
    window.scrollTo(0, 0);
    if(selectedEvent){
    getSchedules();
    }
  },[selectedEvent])

  useEffect(()=>{
    if(scheduleDelete){
      setOpenDeletePopUp(false);
      getSchedules(page, 'initial');
      setTimeout(function(){
        setScheduleDelete(false);
     }, 2000);
    }
  },[scheduleDelete])

  useEffect(()=>{
    if(errMessage){
      setOpenErr(true);
    }
  },[errMessage])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Schedule' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-between'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Schedule</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Schedule</span></Typography>
                </Box>
                <Box className='mr-20 mt-5'>

                  <Box className='w-60 lg:w-96 h-12 shadow bg-white shadow-lg hidden sm:flex ml-10  lg:-mt-1 lg:ml-8 xl:ml-24 2xl:ml-28 flex items-center justify-center rounded-lg'>
                  
                  <Box>
                    <input
                      type='text'
                      value={searching}
                      onChange = {handleSearchChange}
                      placeholder='Search items'
                      className='w-40 lg:w-72 h-7 focus:outline-none mr-2 '
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <Box className='w-5 h-5 mr-3'>
                  <span className='w-5 h-5 cursor-pointer' onClick={()=>{handleSearch('initial'); setPage(1)}}> <img
                        src={images.searchIcon}
                        style={{ width: '100%', height: '100%' }}
                        alt=''
                      /></span>
                  </Box>
                  <Box className='w-5 h-5'>
                    <ClearIcon className='text-blue-400 -mt-2 ml-2 cursor-pointer' onClick={handleClearSearch}/>
                  </Box>
                </Box>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>Schedule</Typography>
                           <span className='mr-5 flex flex-col sm:flex-row'><span className='mb-2 sm:mb-0 sm:mr-5'><Button variant="contained" className='px-5 h-10' onClick={handleRefresh}>Refresh</Button></span><NavLink to='/addNewSchedule'><Button variant="contained" className='px-5 h-10'>Add New <span className='hidden sm:flex ml-1'>Schedule</span></Button></NavLink></span>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}

                            <div style={{ height: 550, width: '100%' }}>
                               {(schedulesContextData?.schedules?.length > 0 ) ? <StripedDataGrid
                                   sortingOrder={['asc', 'desc']}
                                    getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                   }
                                   rowHeight={40}
                                   columns={columns.map((column) => ({
                                    ...column,
                                    sortable: false,
                                  }))}
                                  rows={schedulesContextData?.schedules}
                                  disableColumnMenu
                                   />  : ( unathenticated || schedulesContextData?.total_pages === 0 ) ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                                   </div>
                                  {(parseInt(schedulesContextData?.page) > schedulesContextData?.total_pages) ? '' : <Box className='w-1/2 mr-5 lg:mr-8 2xl:mr-12 right-0 -mt-12 absolute overflow-none z-40 h-10 bg-white text-left flex items-center justify-start'>
                                       <Box className='sm:ml-auto sm:mr-12 flex items-center'>
                                            <Pagination count={schedulesContextData?.total_pages} variant="outlined" shape="rounded" size="small" page={page} onChange={handlePageChange}/>
                                       </Box>
                                   </Box> }

                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={scheduleDelete} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setScheduleDelete(false);
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2}}
                  >
                        Schedule is successfully Deleted
                  </Alert>
                </Collapse>
                <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
                {openDeletePopUp ? <AlertDialogSlide id={deletingID} from='Schedule' handleClosePopUp={handleClosePopUp}/> :  ''}
            </Grid>
        </Grid>
    </Box>
  );
}

export default Schedule;
