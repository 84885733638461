import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import UseBoothsApiCalls from '../../hooks/BoothsApiCalls.ts';
import UseDeletePopUp from '../../hooks/UseDeletePopUp.ts';
import UseSchedulesApiCalls from '../../hooks/SchedulesApiCalls.ts';
import UsePromotionsApiCalls from '../../hooks/PromotionsApiCalls.ts';
import UseStaffApiCalls from '../../hooks/StaffApiCalls.ts';
import UsePushMessagesApiCalls from '../../hooks/PushMessagesApiCalls.ts';
import UseQuizApiCalls from '../../hooks/QuizApiCalls.ts';
import UseUsersListApiCalls from '../../hooks/UsersListApiCalls.ts';
import UseAutoShowApiCalls from '../../hooks/AutoShowApiCalls.ts';
import UseFoodFiestasApiCalls from '../../hooks/FoodFiestasApiCalls.ts';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({id, from, handleClosePopUp, isActiveQuiz}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClosePopUp();
  };
  const handleYes = () => {
    setOpen(false);
    if(from === 'Booth'){
        handleDeleteBooth(id);
    }else if(from === 'AutoShow'){
      handleDeleteAutoShow(id);
  }else if(from === 'Maybank Islamic MAE-Dan Selera'){
    handleDeleteFoodFiestas(id);
  }else if(from === 'Schedule'){
        handleDeleteSchedule(id);
    }else if(from === 'Promotion'){
        handleDeletePromotion(id);
    }else if(from === 'Admin'){
      handleDeleteAdmin(id);
  }else if(from === 'Push Message'){
    handleDeletePushMessages(id);
  }else if(from === 'Quiz'){
    handleBlockQuiz(id);
  }else if(from === 'User'){
    handleRadeemUser(id);
  }
    
  }
  React.useEffect(()=>{
    setOpen(true);
  },[])
  const {handleDeleteBooth}=UseBoothsApiCalls();
  const {handleDeleteAutoShow}=UseAutoShowApiCalls();
  const {handleDeleteFoodFiestas}=UseFoodFiestasApiCalls();
  const {handleDeleteSchedule}=UseSchedulesApiCalls();
  const {handleDeletePromotion} = UsePromotionsApiCalls();
  const {handleDeleteAdmin} = UseStaffApiCalls();
  const {handleDeletePushMessages} = UsePushMessagesApiCalls();
  const {handleBlockQuiz} = UseQuizApiCalls();
  const {handleRadeemUser} = UseUsersListApiCalls();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`${from === 'Quiz' ? isActiveQuiz : from === 'User' ? 'Radeem' : 'Delete'} Alert`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure to ${from === 'Quiz' ? isActiveQuiz : from === 'User' ? 'Radeem' : 'Delete'} this ${from} ? `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
