import React,{useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { BoothsContext } from '../contextApi/BoothsContext.ts';
import {DeleteAlert} from '../components/Alert/Alert.tsx';
import UseDeletePopUp from './UseDeletePopUp.ts';
import { SelectedEventContext } from '../contextApi/selectedEventContext.ts';
import { useNavigate } from 'react-router-dom';

const UseBoothsApiCalls = () => {
  const {boothsContextData, setBoothsContextData, boothDelete, setBoothDelete, searchQuery, setSearchQuery} = useContext(BoothsContext);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const {selectedEvent, setSelectedEvent} = useContext(SelectedEventContext);
  const [loading, setLoading] = useState(false);
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  const [unathenticated, setAuthenticated] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const handleOnChangeSearch = (value)=>{
    setSearchInput(value);
  }
   const handleSortingData = (sortingData) => {
    setSortingField(sortingData.sortModel[0].field);
    setSortingType(sortingData.sortModel[0].sort);
    
   }
   const handlePagination = (nextPage)=>{
    getBooths(nextPage);
   }
   const handleRefreshBooths = () => {
    setSortingField('');
    setSortingType('');
    setSearchInput('');
    let from = 'refresh';
    let page = 1;
    getBooths(page, from);
   }
   const handleClearSearchBooths = () => {
    setSearchInput('');
    let from = 'clearSearch';
    handleSearch(from);
   }
   const handleSearch = async (from = 'initial') => {
    console.log('from is ', from);
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-booths?query=${from === 'initial' ? searchInput : ''}&sort=${sortingField}&sort_type=${sortingType}&event_id=${selectedEvent}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setBoothsContextData(response.data);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in booth search', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    const getBooths = async (page=1, from='initial') => {
      setLoading(true);
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/get-booths?sort=${from === 'initial' ? sortingField : ''}&sort_type=${from === 'initial' ? sortingType : ''}&query=${from === 'initial' ? searchInput : ''}&page=${page}&event_id=${selectedEvent}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setBoothsContextData(response.data);
            setLoading(false);
          }
        }catch(err){
          setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
          console.log('err in get booths ', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }

      const handleDeleteBooth = async (boothID) => {
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/delete-booth/${boothID}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setBoothDelete(true);
          }
        }catch(err){
          alert(err.response.data.message);
          console.log('err in delete booth :', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      
      }
      
  return {
    getBooths,
    handleOnChangeSearch,
    handleSearch,
    loading,
    handleSortingData,
    searchInput,
    handleDeleteBooth,
    handlePagination,
    errMessage,
    unathenticated,
    handleRefreshBooths,
    handleClearSearchBooths
  };
};

export default UseBoothsApiCalls;




