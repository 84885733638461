import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Sidebar from "../../../components/Sidebar/Sidebar.tsx";
import Topbar from "../../../components/Topbar/Topbar.tsx";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { images } from "../../../assets/Images.js";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridApi,
  GridCellValue,
} from "@mui/x-data-grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { SurveyContext } from "../../../contextApi/SurveyContext.ts";
import Spinner from "../../../components/Spinner/Spinner.tsx";
import UseSurveysApiCalls from "../../../hooks/SurveysApiCalls.ts";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import UseSurveyExportsApiCalls from "../../../hooks/SurveyExportsApiCalls.ts";
import { SurveyExportContext } from "../../../contextApi/SurveyExportContext.ts";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },

    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important",
  },
}));

function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

function createData(id: number, title: string, title_bm: string, dated: any) {
  return { id, title, title_bm, dated };
}

function SurveyExports() {
  const [searching, setSearching] = useState("");
  const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const { surveyExportContextData, setSurveyExportContextData } =
    useContext(SurveyExportContext);
  const [openErr, setOpenErr] = useState(false);
  const [queryOptions, setQueryOptions] = React.useState({});

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Created By",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        var formattedData = params.row.user.name;
        return <div className="rowitem">{formattedData}</div>;
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        var formattedDate = params.row.created_at;
        console.log("formatted date is ", formattedDate);
        if (formattedDate) {
          const localDate = moment
            .utc(formattedDate)
            .local()
            .format("DD-MM-yyyy HH:mm");
          return <div className="rowitem">{localDate}</div>;
        }
      },
    },
    { field: "status", headerName: "Status", minWidth: 170, flex: 1 },
    {
      field: "id",
      headerName: "Action",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return alert(JSON.stringify(thisRow, null, 4));
        };
        const url = params.row.file_path;
        const status = params.row.status;
        return (
          <span className="text-xs">
            {status === 'Completed' && <NavLink to="#">
              <Button
                variant="outlined"
                className="mx-2 h-5"
                sx={{ fontSize: "10px", marginRight: "3px" }}
                onClick={() => {
                  handleDownload(url);
                }}
              >
                Download
              </Button>
            </NavLink> }
          </span>
        );
      },
    },
  ];

  // ---------------------------------------------------------------------

  const handleToggle = () => {
    if (activeToggle) {
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    } else {
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  };
  const handleSearchChange = (e) => {
    handleOnChangeSearch(e.target.value);
    setSearching(e.target.value);
  };
  const handleDownload = (url) => {
    window.open(url);
  };

  const handleRefresh = () => {
    setSearching("");
    setPage(1);
    setSurveyExportContextData([]);
    handleRefreshCall();
  };
  const handleClearSearch = () => {
    setSearching("");
    setPage(1);
    handleClearSearchCall();
    setSearching("");
  };
  const {
    getSurveysExports,
    loading,
    handleOnChangeSearch,
    unathenticated,
    errMessage,
    handleRefreshCall,
    handleClearSearchCall,
  } = UseSurveyExportsApiCalls();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSurveysExports();
  }, []);
  useEffect(() => {
    if (errMessage) {
      setOpenErr(true);
    }
  }, [errMessage]);
  useEffect(() => {
    if (openErr) {
      setTimeout(function () {
        setOpenErr(false);
      }, 2000);
    }
  }, [openErr]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={2} sm={3} lg={size1} sx={{ backgroundColor: "#484f4f" }}>
          <Sidebar active="ExportSurvey" activeToggle={activeToggle} />
        </Grid>
        <Grid item xs={10} sm={9} lg={size2} className="">
          <Topbar handleToggle={handleToggle} />
          <Box className="w-full h-28 bg-white flex items-start justify-between">
            <Box className="ml-5">
              <Typography
                variant="body1"
                sx={{ margin: "auto", fontSize: "35px", fontWeight: "light" }}
                className="pt-2"
              >
                Export Surveys
              </Typography>
              <Typography
                variant="body1"
                sx={{ margin: "auto" }}
                className="pt-2 text-left pl-1"
              >
                Home / <span className="font-semibold">Export Surveys</span>
              </Typography>
            </Box>
          </Box>
          <Box className="mt-7 mb-7 w-full flex items-center justify-center">
            <Box className=" bg-white" sx={{ width: "95%" }}>
              <Box className="w-full bg-white mt-3 flex items-start justify-between">
                <Typography variant="h6" className="pt-2 pl-5">
                  Export Surveys
                </Typography>
                <span className="mb-2 sm:mb-0 sm:mr-5">
                  <Button
                    variant="contained"
                    className="px-5 h-10"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                </span>
              </Box>
              <Box className="mt-10 mb-20">
                {/* -------------------------Table---------------------------- */}

                <div style={{ height: 550, width: "100%" }}>
                  {!loading && surveyExportContextData?.surveyexport?.length > 0 ? (
                    <StripedDataGrid
                      sortingOrder={["asc", "desc"]}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                      rowHeight={40}
                      columns={columns.map((column) => ({
                        ...column,
                        sortable: false,
                      }))}
                      rows={surveyExportContextData?.surveyexport}
                      disableColumnMenu
                    />
                  ) : (unathenticated) ? (
                    <Box className="fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2">
                      <Typography variant="body2" className="pt-2 pl-5">
                        <span className="font-bold">No result found</span>
                      </Typography>
                    </Box>
                  ) : loading ? (
                    <Box className="fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2">
                      <Spinner size={10} />
                    </Box>
                  ) : <Typography variant="body2" className="pt-2 pl-5">
                  <span className="font-bold">No result found</span>
                </Typography>}
                </div>
              </Box>
            </Box>
          </Box>
          <Box className="w-full h-10 bg-white text-left flex items-center justify-start">
            <Typography variant="body2" className="pt-2 pl-5">
              <span className="font-bold">Copyright</span> PNB 2018
            </Typography>
          </Box>
          <Collapse
            in={openErr}
            className="fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2"
          >
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenErr(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, color: "white", backgroundColor: "red" }}
            >
              {errMessage}
            </Alert>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SurveyExports;
