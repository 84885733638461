import React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import Spinner from '../../../components/Spinner/Spinner.tsx';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import html2canvas from 'html2canvas';
import { Button, createTheme, TextField, Tooltip } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import moment from 'moment';
import { images } from '../../../assets/Images.js';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "lightgray",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "lightgray",
          },
        },
      },
    },
  },
});

function Users() {
  const [size1, setSize1] = useState(2);
  const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [userDeviceStatistics, setUserDeviceStatistics] = useState<any>();
  const [userAgeStatistics, setUserAgeStatistics] = useState<any>();
  const [activeToggle, setActiveToggle] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const [filterFromDateValue, setfilterFromDateValue] = useState('');
  const [filterToDateValue, setfilterToDateValue] = useState('');
  const [openErr, setOpenErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [usersCount, setUsersCount] = useState<any>();
  const [checkinUsers, setCheckinUsers] = useState<any>();
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  
   const getUserStatistics = async () => {
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-users-stats?from_date=${filterFromDateValue}&to_date=${filterToDateValue}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response){
        setAuthenticated(true);
        console.log('response of get statistics is ', response.data);
        setUsersCount(response?.data?.users);
        setCheckinUsers(response?.data?.check_in)
        setUserDeviceStatistics({
          series: [parseFloat(response?.data.app_users), parseFloat(response?.data.web_users)],
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ['App', 'Web'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        })
        setUserAgeStatistics({
          
          series: [parseFloat(response.data.age.unspecified), parseFloat(response.data.age.under_12), parseFloat(response.data.age.between_13_to_17), parseFloat(response.data.age.between_18_to_24), parseFloat(response.data.age.between_25_to_34), parseFloat(response.data.age.between_35_to_44), parseFloat(response.data.age.between_45_to_54)],
          options: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: ['unspecified','Under 12 years old', '13-17 years old', '18-24 years old', '25-34 years old', '35-44 years old', '45-54 years old'],
            colors:['rgb(3, 3, 3)', '#5bf181', '#f5c02e', 'rgb(233, 47, 134)', '#052796', '#2da4e9', 'rgb(15, 189, 38)'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        
        
        })
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      setOpenErr(true);
      setErrMessage(`${err.response.data.message}`);
      console.log('err in get quizes', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
   }

   const handleClearFilters = () => {
    if(filterFromDateValue !== '' || filterToDateValue !== ''){
      setfilterFromDateValue('');
      setfilterToDateValue('');
      setFiltersCleared(true);
    }
   }

  useEffect(()=>{
    getUserStatistics();
  },[])
  useEffect(()=>{
    if(filtersCleared){
      setFiltersCleared(false);
      getUserStatistics();
    }
  },[filtersCleared])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} className='2xl:h-screen' sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='UsersStats' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Users Stats</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'>Home / <span className='font-semibold'>Users Stats</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                     <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>Users Stats</Typography>
                           <Box className='flex items-center mt-1'>
                  <ThemeProvider theme={theme}>
                    <TextField
                      id="outlined-error-helper-text"
                      label="Start Date"
                      className="w-40 h-12 dateFilter"
                      autoComplete="off"
                      value={filterFromDateValue}
                      onChange={(e) => setfilterFromDateValue(moment(e.target.value).format('YYYY-MM-DD'))}
                      name="fromDate"
                      placeholder="Enter date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                    />
                  </ThemeProvider>
                  <span className='ml-2'>
                  <ThemeProvider theme={theme}>
                    <TextField
                      id="outlined-error-helper-text"
                      label="End Date"
                      className="w-40 ml-2 h-12 dateFilter"
                      autoComplete="off"
                      value={filterToDateValue}
                      onChange={(e) => setfilterToDateValue(moment(e.target.value).format('YYYY-MM-DD'))}
                      name="fromDate"
                      placeholder="Enter date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                    />
                  </ThemeProvider>
                  </span>
                  <span className='-mt-2.5 mx-2'><Button variant="contained" className='h-10' onClick={getUserStatistics}>Apply</Button></span>
                  <Box className="h-10 flex flex-col items-center justify-center mr-2 -mt-2">
          <Tooltip title="Clear Filters" placement="top" arrow>
            <img
              src={images?.crossImg}
              alt="closeIcon"
              className="w-7 h-7 cursor-pointer"
              onClick={handleClearFilters}
            />
          </Tooltip>
        </Box>
                           </Box>
                         </Box>
                         {!loading ? authenticated ?  <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                          <Grid container className='lg:flex items-center justify-around overflow-auto'>
                              <Grid item xs={12} lg={12} className='bg-blue text-left'>
                               <Typography variant='body1' className='py-2 pl-5 font-semibold border-t-2 border-b-2'>From App/Web</Typography>
                               <Box className='w-full'>
                               <div id="chart" className='flex mt-3 justify-center'>
                                 {userDeviceStatistics ? <ReactApexChart options={userDeviceStatistics.options} series={userDeviceStatistics.series} type="pie" width={380} /> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box>}
                               </div>
                               </Box>
                              </Grid>
                              {/* <Grid item xs={12} lg={5} className='text-left'>
                              <Typography variant='body1' className='py-2 pl-5 font-semibold border-t-2 border-b-2'>Ages</Typography>
                              <Box className='w-full'>
                              <div id="chart" className='flex mt-3'>
                                 {userAgeStatistics ? <ReactApexChart options={userAgeStatistics.options} series={userAgeStatistics.series} type="pie" width={457} /> : ''}
                               </div>
                              </Box>
                             </Grid> */}
                          </Grid>
                          <Box className='flex flex-col md:flex-row w-full items-center justify-between'>
                             <Box className='w-full md:w-1/2 h-52 bg-gray-100 m-10'>
                                <Typography variant='body1' className='py-2 pl-5 font-semibold border-t-2 border-b-2'>Users Count</Typography>
                                <span className='text-left'>
                                <p className='mt-5 font-semibold px-2'>Total Users: {usersCount?.total_users}</p>
                                <p className='font-semibold px-2'>Registered Today: {usersCount?.registered_today}</p>
                                </span>
                             </Box>
                             <Box className='w-full md:w-1/2 h-52 bg-gray-100 m-10'>
                                <Typography variant='body1' className='py-2 pl-5 font-semibold border-t-2 border-b-2'>Checkin Users</Typography>
                                <span className='text-left'>
                                <p className='mt-5 font-semibold px-2'>Total Checkin's: {checkinUsers?.total_checkins}</p>
                                <p className='font-semibold px-2'>Checkin's Today : {checkinUsers?.checkins_today}</p>
                                </span>
                             </Box>
                          </Box>
                         </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box>}
                    </Box> 
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading && authenticated ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={openErr} className='fixed z-40 top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                   <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenErr(false);
                      }}
                   >
                       <CloseIcon fontSize="inherit" />
                  </IconButton>
                  }
                 sx={{ mb: 2, backgroundColor: 'red', color: 'white'}}
                >
                {errMessage}
              </Alert>
          </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default Users;
