import React,{useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { UsersListContext } from '../contextApi/UsersListContext.ts';
import { useNavigate } from 'react-router-dom';

const UseUsersListApiCalls = () => {
  const {usersListContextData, setUsersListContextData, userDelete, setUserDelete} = useContext(UsersListContext);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  const navigate = useNavigate();
  const [unathenticated, setAuthenticated] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const handleOnChangeSearch = (value)=>{
    setSearchInput(value);
  }
   const handlePagination = (nextPage)=>{
    getUsersList(nextPage);
   }
   const handleSortingData = async (sortingData) => {
    setSortingField(sortingData.sortModel[0].field);
    setSortingType(sortingData.sortModel[0].sort);
   }
   const handleRefreshUsersList = () => {
    setSortingField('');
    setSortingType('');
    setSearchInput('');
    let from = 'refresh';
    let page = 1;
    getUsersList(page, from);
   }
   const handleClearSearchUsersList = () => {
    setSearchInput('');
    let from = 'clearSearch';
    handleSearch(from);
   }
   const handleSearch = async (from = 'initial') => {
    setLoading(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-app-users?query=${from === 'initial' ? searchInput : ''}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response in search app users is ', response);
        setUsersListContextData(response.data);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in search users list', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    const getUsersList = async (page=1, from='initial') => {
      setLoading(true);
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/get-app-users?sort=${from === 'initial' ? sortingField : ''}&sort_type=${from === 'initial' ? sortingType : ''}&query=${from === 'initial' ? searchInput : ''}&page=${page}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setUsersListContextData(response.data);
            setLoading(false);
          }
        }catch(err){
          setLoading(false);
          setAuthenticated(true);
          setErrMessage(`${err.response.data.message}`);
          console.log('err in get users list', err.response.data.message);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }
      const handleRadeemUser = async (radeemId: any) => {
        try{
          const accessToken = localStorage.getItem('auth');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/redeem-user/${radeemId}`,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setUserDelete(true);
          }
        }catch(err){
          alert(err.response.data.message);
          console.log('err in radeem user :', err);
          if(err?.response?.status === 401){
            localStorage.setItem('auth','');
            navigate('/login');
          }
        }
      }
      
  return {
    getUsersList,
    handleOnChangeSearch,
    handleSearch,
    loading,
    unathenticated,
    handleSortingData,
    handlePagination,
    errMessage,
    handleRefreshUsersList,
    handleClearSearchUsersList,
    handleRadeemUser
  };
};

export default UseUsersListApiCalls;