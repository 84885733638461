import React,{useState, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import {images} from '../../../../assets/Images.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import { NavLink, useNavigate } from 'react-router-dom';
import SimpleImageSlider from "react-simple-image-slider";
import axios from 'axios';
import { useParams } from 'react-router-dom'
import Spinner from '../../../../components/Spinner/Spinner.tsx';

function ViewPromotionDetails() {
    const [removeLoader, setRemoveLoader] = useState(false);
    const [profile, setProfile] = useState<any>('https://t3.ftcdn.net/jpg/02/70/22/86/360_F_270228625_yujevz1E4E45qE1mJe3DyyLPZDmLv4Uj.jpg');
    const [bannerImages, setBannerImages] = useState([]);
    const [currentImage, setCurrentImage] = React.useState(0);
    const [size1, setSize1] = useState(2);
    const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState();
  const { id } = useParams();
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }

  // -----------------------------------------------------------------

  const images = [
    { url: "https://t3.ftcdn.net/jpg/02/70/22/86/360_F_270228625_yujevz1E4E45qE1mJe3DyyLPZDmLv4Uj.jpg" },
    { url: "https://miro.medium.com/max/1052/1*VHDtVaDPNepRglIAv72BFg.jpeg" },
    { url: "https://www.tibco.com/sites/tibco/files/media_entity/2021-05/random-forest-diagram.svg" },
    { url: "https://t3.ftcdn.net/jpg/02/70/22/86/360_F_270228625_yujevz1E4E45qE1mJe3DyyLPZDmLv4Uj.jpg" }
  ];

  //-----------------------------------------------------------------------
  const getPromotionsById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-promotion/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.promotion);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in promotion view ', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
    useEffect(()=>{
      getPromotionsById();
    },[]) 
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Promotions' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Promotion Details</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/promotions'>Promotions </NavLink>/ <span className='font-semibold cursor-pointer'>Promotion Details</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                {!loading ? <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>View Promotion</Typography>
                         </Box>
                         <Box className='mt-10 mb-20 flex flex-col items-center justify-start'>
                          {/* -------------------------Table---------------------------- */}
                          <Box className='w-full lg:w-2/3 flex flex-col items-start justify-start text-left'>
                              <Box className='mt-20 xl:mt-5 flex items-center justify-center'>
                                 <Typography variant='body1' className='pt-3 pl-5'><span className='font-bold'>Promotion</span>:</Typography>
                                 <Box className='w-52 h-52 ml-5 object-contain bg-gray-100 flex items-center justify-center'>
                                       <img src={viewData?.promotion_image} className='object-contain' width='90' height='90' />
                                 </Box>
                              </Box>
                                 <Typography variant='body1' className='pt-10 pl-5'><span className='font-bold'>Link</span>: {viewData?.link} </Typography>
                                 <Typography variant='body1' className='pt-10 pl-5'><span className='font-bold'>Sorting Value</span>: {viewData?.sort} </Typography>
                          </Box>
                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full ${!loading ? '' : 'fixed'} bottom-0 h-10 bg-white text-left flex items-center justify-start`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewPromotionDetails;
