import React,{useEffect, useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {images} from '../../../../assets/Images.js';
import { styled } from '@mui/material/styles';
import { alpha} from '@mui/material/styles';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { QuizDetailsContext } from '../../../../contextApi/QuizDetailsContext.ts';
import Spinner from '../../../../components/Spinner/Spinner.tsx';
import UseQuizDetailsApiCalls from '../../../../hooks/QuizDetailsApiCalls.ts';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import { useParams } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      //backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible !important",
    width: "0 !important"
  }
}));

function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

function createData(
  id: number,
  name: string,
  ic_number: string,
  mobile_number: any,
  score: any,
  duration: any,
  date: any,
  submission: any
) {
  return {id, name, ic_number, mobile_number, score, duration, date, submission};
}

const rows = [
  createData(1,'Majlis Perasmian', 'ABC20', '00678432153', '90', '2 hours', '2018-04-19 10:00', 'web'),
  createData(2,'Doa Selamat','jhf30', '89638672901', '73', '5 weeks', '2018-04-19 17:30', 'web'),
  createData(3,'Tanyalah Ustaz','KLD45', '90372937822', '67', '1 month', '2018-04-19 21:15', 'app'),
  createData(4,'Majlis Perasmian', 'ABC20', '00678432153', '90', '2 hours', '2018-04-19 10:00 ', 'web'),
  createData(5,'Doa Selamat','jhf30', '89638672901', '73', '5 weeks', '2018-04-19 17:30', 'web'),
  createData(6,'Tanyalah Ustaz','KLD45', '90372937822', '67', '1 month', '2018-04-19 21:15', 'app'),
  createData(7,'Majlis Perasmian', 'ABC20', '00678432153', '90', '2 hours', '2018-04-19 10:00 ', 'app'),
  createData(8,'Doa Selamat','jhf30', '89638672901', '73', '5 weeks', '2018-04-19 17:30', 'web'),
  createData(9,'Tanyalah Ustaz','KLD45', '90372937822', '67', '1 month', '2018-04-19 21:15', 'web'),
  createData(10,'Majlis Perasmian', 'ABC20', '00678432153', '90', '2 hours', '2018-04-19 10:00 ', 'app'),

];

function ViewQuizDetails() {
  const [searching, setSearching] = useState('');
  const [size1, setSize1] = useState(2);
  const navigate = useNavigate();
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);
  const [page, setPage] = useState(1);
  const {quizDetailsContextData, setQuizDetailsContextData} = useContext(QuizDetailsContext);
  const [openErr, setOpenErr] = useState(false);
  const [exportErr, setExportErr] = useState(false);
  const [errExportMsg, setErrExportMsg] = useState('');
  const [queryOptions, setQueryOptions] = React.useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const { id, quizName} = useParams();
  let isDescriptive = false;
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Username', width: 160, sortable: false },
    { field: 'ic_number', headerName: 'IC Number', width: 130, sortable: false },
    { field: 'mobile_number', headerName: 'Mobile Number', width: 130, sortable: false },
    { field: 'score_percentage', headerName: 'Score', width: 90,
     renderCell: (params) => {
      var scorePercent = params.row.score_percentage;
      return <div className="rowitem">{scorePercent} %</div>
      },},
    { field: 'attempt_time_taken', headerName: 'Duration', width: 100,
       renderCell: (params) => {
      var timeInMilli = params.row.attempt_time_taken;
      console.log('milliseconds are ', timeInMilli);
      var seconds = Math.floor((timeInMilli / 1000) % 60);
      var minutes = Math.floor((timeInMilli / 60000) % 60);
      var milliseconds = (timeInMilli - ((minutes * 60 * 1000) + (seconds * 1000)));
      var finalMilli = milliseconds.toString().substring(0, milliseconds.toString().length - 1);
      var datestring = String(minutes).padStart(2, '0')  + ":" + String(seconds).padStart(2, '0') + ":" + String(finalMilli).padStart(2, '0');
      return <div className="rowitem">{datestring}</div>
         
      },},
    { field: 'created_at', headerName: 'Date', width: 130,
    renderCell: (params) => {
      var formattedDate = params.row.created_at;
      const localDate = moment.utc(formattedDate).local().format('DD-MM-yyyy HH:mm');
      return <div className="rowitem">{localDate}</div>
      },
     },
    { field: 'submission_from', headerName: 'Submission', width: 130 },
    { field: 'attempt_answer', headerName: 'Descriptive Answer', width: 170,  sortable: false,
    renderCell: (params) => {
      var formattedData;
      if(params.row.attempt_answer.length > 0){
        formattedData = params.row.attempt_answer[0].descriptive_answer;
      }else{
        formattedData = ''
      }
      
      return <div className="rowitem">{formattedData}</div>
      }, },
  ];


  const columnsWithoutAnswer: GridColDef[] = [
    { field: 'name', headerName: 'Username', width: 160, sortable: false },
    { field: 'ic_number', headerName: 'IC Number', width: 130, sortable: false },
    { field: 'mobile_number', headerName: 'Mobile Number', width: 130, sortable: false },
    { field: 'score', headerName: 'Score', width: 90,
    renderCell: (params) => {
      var scorePercent = params.row.score_percentage;
      return <div className="rowitem">{scorePercent} %</div>
      },},
    { field: 'attempt_time_taken', headerName: 'Duration', width: 150,
    renderCell: (params) => {
      var timeInMilli = params.row.attempt_time_taken;
      console.log('milliseconds are ', timeInMilli);
      var seconds = Math.floor((timeInMilli / 1000) % 60);
      var minutes = Math.floor((timeInMilli / 60000) % 60);
      var milliseconds = (timeInMilli - ((minutes * 60 * 1000) + (seconds * 1000)));
      var finalMilli = milliseconds.toString().substring(0, milliseconds.toString().length - 1);
      var datestring = String(minutes).padStart(2, '0')  + ":" + String(seconds).padStart(2, '0') + ":" + String(finalMilli).padStart(2, '0');
      return <div className="rowitem">{datestring}</div>
         
      },},
    { field: 'created_at', headerName: 'Date', width: 130,
    renderCell: (params) => {
      var formattedDate = params.row.created_at;
      const localDate = moment.utc(formattedDate).local().format('DD-MM-yyyy HH:mm');
      // var d = new Date(formattedDate);
      // var datestring = String(d.getDate()).padStart(2, '0')  + "-" + String((d.getMonth()+1)).padStart(2, '0') + "-" + d.getFullYear() + " " + String(d.getHours()).padStart(2, '0') + ":" + String(d.getMinutes()).padStart(2, '0');
      return <div className="rowitem">{localDate}</div>
      },
     },
    { field: 'submission_from', headerName: 'Submission', width: 130 },
  ];


  // ---------------------------------------------------------------------
  
  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }
  const handleSearchChange = (e) => {
    handleOnChangeSearch(e.target.value);
    setSearching(e.target.value);
  }
  const getSortedData = async (sortingData) => {
    handleSortingData(sortingData);
    const field = sortingData.sortModel[0].field;
    const type = sortingData.sortModel[0].sort;
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-quiz-attempts/${id}?sort=${field}&sort_type=${type}&query=${searching}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setQuizDetailsContextData(response.data);
      }
    }catch(err){
      console.log('err in sorting quiz details', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
      
    }
  }
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      setPage(1);
      handleSearch('initial');
    }
  }
  const handleSortModelChange = ((sortModel: GridSortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
    getSortedData({ sortModel: [...sortModel] });
  });
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value);
    setPage(value);
  };
  const handleRefresh = () => {
    setSearching('');
    setPage(1);
    setQuizDetailsContextData([]);
    handleRefreshCall();
  }
  const handleClearSearch = () => {
    setSearching('');
    setPage(1);
    handleClearSearchCall();
    setSearching('');
  }
  const handleExport = async () => {
    setBtnLoader(true);
    try{
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/export-quiz-attempts/${id}?sort=${sortingField}&sort_type=${sortingType}&query=${searching}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        console.log('response of export is ', response.data);
        if(response.data.file_url){
          window.open( 
            `${response.data.file_url}`,"_self");
        }
        setBtnLoader(false);
      }
    }catch(err){
      console.log('err in export is ', err);
      setErrExportMsg(err.response.data.message);
      setExportErr(true);
      setBtnLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const {getQuizDetails, handleOnChangeSearch, handleSearch, handleSortingData, handlePagination, unathenticated, errMessage, loading, sortingField, sortingType, handleRefreshCall, handleClearSearchCall}=UseQuizDetailsApiCalls();
  useEffect(()=>{
    window.scrollTo(0, 0);
    getQuizDetails();
  },[])
  useEffect(()=>{
    if(errMessage){
      setOpenErr(true);
    }
  },[errMessage])
  useEffect(()=>{
    if(openErr){
      setTimeout(function(){
        setOpenErr(false);
     }, 2000);
    }
  },[openErr])
  useEffect(()=>{
    if(exportErr){
      setTimeout(function(){
        setExportErr(false);
     }, 2000);
    }
  },[exportErr])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='Quiz' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-between'>
                 <Box className='ml-5'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '25px', fontWeight: 'light'}} className='pt-2'>Quiz Details</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/quiz'>Quiz </NavLink>/ <span className='font-semibold'>Quiz Details</span></Typography>
                </Box>
                <Box className='mr-20 mt-5'>

                  <Box className='w-60 lg:w-96 h-12 shadow bg-white shadow-lg hidden md:flex ml-10  lg:-mt-1 lg:ml-8 xl:ml-24 2xl:ml-28 flex items-center justify-center rounded-lg'>
                  
                  <Box>
                    <input
                      type='text'
                      value={searching}
                      onChange = {handleSearchChange}
                      placeholder='Search items'
                      className='w-40 lg:w-72 h-7 focus:outline-none mr-2 '
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                  <Box className='w-5 h-5 mr-3'>
                      <span className='w-5 h-5 cursor-pointer' onClick={()=>{handleSearch('initial'); setPage(1)}}> <img
                        src={images.searchIcon}
                        style={{ width: '100%', height: '100%' }}
                        alt=''
                      /></span>
                  </Box>
                  <Box className='w-5 h-5'>
                    <ClearIcon className='text-blue-400 -mt-2 ml-2 cursor-pointer' onClick={handleClearSearch}/>
                  </Box>
                 </Box>
                </Box>
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                    <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-between'>
                           <Typography variant='h6' className='pt-2 pl-5'>{quizName}</Typography>
                           <span className='mr-5 flex flex-col sm:flex-row'><span className='mb-2 sm:mb-0 sm:mr-5'><Button variant="contained" className='px-5 h-10' onClick={handleRefresh}>Refresh</Button></span>{btnLoader ? <span className='px-5 h-10 flex items-center justify-center'><Spinner size={5}/></span> : <Button variant="contained" className='px-5 h-10' onClick={handleExport}>Export</Button>}</span>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}

                            <div style={{ height: 550, width: '100%' }}>
                            {(!loading && quizDetailsContextData?.quiz_attempts?.length > 0) ?  <StripedDataGrid
                                   sortingOrder={['asc', 'desc']}
                                    getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                   }
                                   rowHeight={40}
                                   columns={quizDetailsContextData?.quiz_attempts[0].attempt_answer.length === 0 ? columnsWithoutAnswer : columns}
                                  rows={quizDetailsContextData?.quiz_attempts}
                                  sortingMode="server"
                                  onSortModelChange={handleSortModelChange}
                                  components={{
                                    ColumnUnsortedIcon: CustomUnsortedIcon
                                  }}
                                  disableColumnMenu
                                   /> : (!loading && (unathenticated || quizDetailsContextData?.total_pages === 0 )) ? <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>No result found</span></Typography></Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box>}
                                   </div> 
                                   {(parseInt(quizDetailsContextData?.page) > quizDetailsContextData?.total_pages) ? '' : <Box className='w-1/2 mr-5 lg:mr-8 2xl:mr-12 right-0 -mt-12 absolute overflow-none z-40 h-10 bg-white text-left flex items-center justify-start'>
                                       <Box className='sm:ml-auto sm:mr-12 flex items-center'>
                                            <Pagination count={quizDetailsContextData?.total_pages} variant="outlined" shape="rounded" size="small" page={page} onChange={handlePageChange}/>
                                       </Box>
                                   </Box> }

                         </Box>
                    </Box>
                </Box>
                <Box className='w-full h-10 bg-white text-left flex items-center justify-start'>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={openErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setOpenErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errMessage}
                 </Alert>
               </Collapse>
               <Collapse in={exportErr} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                    <Alert
                        action={
                           <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                           setExportErr(false);
                         }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                   sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                 >
                    {errExportMsg}
                 </Alert>
               </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default ViewQuizDetails;
