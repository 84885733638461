import React,{useState, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Sidebar from '../../../../components/Sidebar/Sidebar.tsx';
import Topbar from '../../../../components/Topbar/Topbar.tsx';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../../../components/Spinner/Spinner.tsx';

function EditUsersList() {
    const [formSubmit, setFormSubmit] = useState(false);
    let navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewData, setViewData] = useState();
    const [errOpen, setErrOpen] = useState(false);
    const { id } = useParams();
    const initialValues={
        name: viewData?.name ? viewData.name : '',
        email: viewData?.email ? viewData.email : '',
        // icNumber: viewData?.ic_number ? viewData.ic_number : '',
        mobileNumber: viewData?.mobile_number ? viewData.mobile_number : '',
        // gender: viewData?.gender ? viewData.gender : '',
        // dob: viewData?.dob ? viewData.dob : '',
        profileImage: viewData?.profile_image ? viewData.profile_image : '',
       }

    const validationSchema =Yup.object ({
        name: Yup.string().required('Required'),
       email: Yup.string().email('Invalid email format'),
        // icNumber: Yup.string().required('Required'),
        mobileNumber: Yup.string().required('Required'),
        // gender: Yup.string(),
        // dob: Yup.string().required('Required'),
        profileImage: Yup.string(),

    })

    const onSubmit = async (values,helpers) => {
        setFormSubmit(true);
     try{
        setBtnLoader(true);
        try {
          var formData = new FormData();
          formData.append("name", values.name);
          if(values.email != ''){
            formData.append("email", values.email);
          }
          // formData.append("ic_number", values.icNumber);
          formData.append("mobile_number", values.mobileNumber);
          // if(values.gender != ''){
          //   formData.append("gender", values.gender);
          // }
          if(values.profileImage != ''){
            formData.append("profile_image", values.profileImage);
          }
          // formData.append("dob", values?.dob);
          const accessToken = localStorage.getItem('auth');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/update-user/${id}`,
            formData,
            {
              headers: { 
                'Content-Type' : 'application/json',
                'Accept' : 'application/json',
                'Authorization' : `Bearer ${accessToken}`
              },
              withCredentials: false,
            }
          );
          if(response.status === 200){
            setOpen(true);
            setBtnLoader(false);
          }
    }catch(err){
      console.log('err in edit user list', err.response);
      if(err?.response?.data?.message === 'Validation Errors'){
        setErrOpen(true);
      }
     
      setBtnLoader(false);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
    }catch(error){
        helpers.setErrors({submit:error.message});
        setBtnLoader(false);
        if(error?.response?.status === 401){
          localStorage.setItem('auth','');
          navigate('/login');
        }
    }
  }
    const formik=useFormik({
        initialValues:initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: formSubmit,
        validateOnBlur: formSubmit,
        onSubmit: onSubmit
    });
    const [size1, setSize1] = useState(2);
  const [size2, setSize2] = useState(10);
  const [activeToggle, setActiveToggle] = useState(false);

  const handleToggle = () => {
    if(activeToggle){
      setSize1(2);
      setSize2(10);
      setActiveToggle(false);
    }else{
      setSize1(1);
      setSize2(11);
      setActiveToggle(true);
    }
  }

  const getUsersListById = async () => {
    try{
      setLoading(true);
      const accessToken = localStorage.getItem('auth');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/get-user/${id}`,
        {
          headers: { 
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization' : `Bearer ${accessToken}`
          },
          withCredentials: false,
        }
      );
      if(response.status === 200){
        setViewData(response.data.user);
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.log('err in edit user list ', err);
      if(err?.response?.status === 401){
        localStorage.setItem('auth','');
        navigate('/login');
      }
    }
  }
  const getRegExp = ['e', 'E', '+', '-', '.', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm','n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '~', '`', '!', '@', '#', '$', '%', '^', '&', '&', '*', '(', ')', '_', '=', '{','}', '[',']','|', '<', '>', ',', '?', '/',';',':','"',"'"];
  const handleContactNum = (e) => {
    if(/^[0-9]+$/.test(e.target.value)){
      formik.handleChange(e)
    }else{
      return
    }
   }
    useEffect(()=>{
        getUsersListById();
    },[])
    useEffect(()=>{
      if(open){
        setTimeout(function(){
          setOpen(false);
          navigate('/usersList');
       }, 2000);
      }
    },[open])
    useEffect(()=>{
        if(errOpen){
          setTimeout(function(){
            setErrOpen(false);
         }, 2000);
        }
      },[errOpen])
  return (
    <Box>
        <Grid container>
            <Grid item xs={2} sm={3} lg={size1} sx={{backgroundColor: '#484f4f'}}>
                <Sidebar active='UsersList' activeToggle={activeToggle}/>
            </Grid>
            <Grid item xs={10} sm={9} lg={size2} className=''>
                <Topbar handleToggle={handleToggle}/>
                <Box className='w-full h-28 bg-white flex items-start justify-start'>
                 <Box className='ml-5 flex flex-col justify-start items-start text-left'>
                    <Typography variant='body1' sx={{margin: 'auto', fontSize: '35px', fontWeight: 'light'}} className='pt-2'>Edit User</Typography>
                    <Typography variant='body1' sx={{margin: 'auto'}} className='pt-2'><NavLink to='/usersList'>Users </NavLink>/ <span className='font-semibold'>Edit User</span></Typography>
                </Box>
                
                </Box>
                <Box className='mt-7 mb-7 w-full flex items-center justify-center'>
                {!loading ?  <Box className=' bg-white' sx={{width: '95%'}}>
                         <Box className='w-full bg-white mt-3 flex items-start justify-start'>
                           <Typography variant='h6' className='pt-2 pl-5'>Edit User</Typography>
                         </Box>
                         <Box className='mt-10 mb-20'>
                          {/* -------------------------Table---------------------------- */}
                             <form className='flex flex-col items-center justify-center' onSubmit={formik.handleSubmit}>
                             <Box className='lg:w-4/5 mt-10 text-center lg:text-left ml-10 lg:ml-40'>
                              <span className='flex flex-wrap items-center justify-start'><label htmlFor='logo' className='font-bold mr-2'>Profile: </label>
                              <span className='flex flex-col items-center justify-center'> 
                                 <Box className='w-52 h-52 m-3 bg-white rounded-xl flex flex-col items-center justify-center cursor-pointer bg-gray-100'>
                                      <Button component="label"><Box className='flex items-center justify-center object-contain' sx={{width: '100%', height: '100%'}}><img src={formik.values.profileImage} alt='profile' className='object-contain rounded-xl' width='100' height='100'/></Box></Button>
                                 </Box> 
                              </span>
                              </span>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`profileImage`} defaultValue={viewData?.profile_image} error={formik.errors.profileImage} helperText={formik.errors.profileImage} onChange={formik.handleChange} label={`Profile Image url`} placeholder="e.g https://example.com" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='url' />
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`name`} defaultValue={viewData?.name} error={formik.errors.name} helperText={formik.errors.name} onBlur={formik.handleChange} label={`Name`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box>
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`email`} defaultValue={viewData?.email} error={formik.errors.email} helperText={formik.errors.email} onBlur={formik.handleChange} label={`Email`} placeholder="eg abc@gmail.com" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='email'/>
                             </Box>
                             {/* <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`icNumber`} defaultValue={viewData?.ic_number} error={formik.errors.icNumber} helperText={formik.errors.icNumber} onBlur={formik.handleChange} label={`IC Number`} placeholder="Recommended Length: less than 20 characters" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text'/>
                             </Box> */}
                             <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`mobileNumber`} defaultValue={viewData?.mobile_number} onKeyDown={(e) => getRegExp.includes(e.key) && e.preventDefault()} error={formik.errors.mobileNumber} helperText={formik.errors.mobileNumber} onBlur={(e)=>{handleContactNum(e)}} label={`User Mobile Number`} placeholder="e.g 03615786678" variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='text' InputProps={{ inputProps: { min: 0 } }} />
                             </Box>
                             {/* <Box className='w-4/5 mt-10'>
                                <FormControl sx={{ m: 1}} className={`h-10 focus:outline-none px-2 rounded w-4/5 bg-white`} variant="filled" >
                                     <InputLabel id="demo-simple-select-error-label">Gender</InputLabel>
                                     <Select
                                     labelId="demo-simple-select-error-label"
                                     name="gender"
                                     error={formik.errors.gender}
                                     id="demo-simple-select-filled"
                                     value={formik.values.gender}
                                     helperText={formik.errors.gender}
                                     label="Gender"
                                     onChange={formik.handleChange}
                                     className='text-left'
                                     style={{backgroundColor: "white"}}
                                     >
                                     <MenuItem value='male'>Male</MenuItem>
                                     <MenuItem value='female'>Female</MenuItem>
                                     <MenuItem value='other'>Other</MenuItem>
                                     </Select>
                                 </FormControl>
                             </Box> */}
                             {/* <Box className='w-4/5 mt-10'>
                              <TextField id="filled-error-helper-text" name={`dob`} error={formik.errors.dob} label={`Date of Birth`} defaultValue={viewData?.dob} helperText={formik.errors.dob} onBlur={formik.handleChange} variant="filled" className={`h-10 focus:outline-none px-2 rounded w-4/5`} sx={{input: {background: "white"}}} type='date'/>
                             </Box> */}
                             <Box className='lg:w-4/5 flex flex-wrap mt-20 text-center lg:text-left ml-10 lg:ml-40'>
                             {btnLoader ? <Box className='ml-7 w-20 flex justify-center items-center'><Spinner size={5}/></Box> : <Button variant="contained" sx={{marginLeft: '20px', backgroundColor: 'green'}} type='submit' onClick={() => {setFormSubmit(true)}} disabled={!formik.isValid}>Update User</Button> }
                             </Box>
                             </form>

                         </Box>
                    </Box> : <Box className='fixed z-20 lg:ml-32 top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2'><Spinner size={10}/></Box> }
                </Box>
                <Box className={`w-full h-10 bg-white text-left flex items-center justify-start ${!loading ? '' : 'fixed'} bottom-0`}>
                     <Typography variant='body2' className='pt-2 pl-5'><span className='font-bold'>Copyright</span> PNB 2018</Typography>
                </Box>
                <Collapse in={open} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    navigate('/promotions');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2}}
            >
              User is successfully Updated
            </Alert>
          </Collapse>
          <Collapse in={errOpen} className='fixed top-10 left-1/2 -translate-y-1/2 -translate-x-1/2'>
                   <Alert
                   action={
                      <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                         setErrOpen(false);
                      }}
                      >
                         <CloseIcon fontSize="inherit" />
                      </IconButton>
                   }
                  sx={{ mb: 2, color: 'white', backgroundColor: 'red'}}
                  >
                        Email already exists, Please use another email
                  </Alert>
                </Collapse>
            </Grid>
        </Grid>
    </Box>
  );
}

export default EditUsersList;
